<template>
	<div class="com-select-user">
		<div v-if="custom" @click="showUser">
			<slot></slot>
		</div>
		<template v-else>
			<a-input v-model:value="userIdJoin" disabled v-if="selectStyle==1">
				<template #addonAfter>
					<div @click="showUser" style="cursor: pointer;">选择用户</div>
				</template>
			</a-input>
			
			<a-select
				v-if="selectStyle == 2"
			    show-search
			    v-model:value="user_id"
			    placeholder="请输入用户昵称进行查询"
			    :default-active-first-option="false"
			    :show-arrow="false"
			    :filter-option="false"
			    :not-found-content="null"
			    @search="searchSelect"
				@change="sureSelect"
			>
			    <a-select-option v-for="(item,index) in info.list" :key="index" :value="item.user_id">
					{{ item.nickname }}
			    </a-select-option>
			</a-select>
		</template>
		
		<a-modal v-model:visible="show" title="选择用户" width="800px" @ok="sureSelect" @cancel="show =false">
			<a-input-search 
				v-model:value="search.key" 
				placeholder="请输入用户昵称进行查询" 
				style="width: 400px" 
				@search="getAccountList(1,info.limit)" 
			/>
			<div class="csu-user-list">
				<div class="csu-user-list-item" 
					:class="includesUser(item.user_id) ?'active':''" 
					v-for="(item,index) in info.list" 
					:key="index" 
					@click="selectUserId(item.user_id)">
					<a-avatar :size="48" :src="item.avatar_url"></a-avatar>
					<div class="csu-user-list-item-ri">
						<div class="sii-name" v-if="module == 'user'">{{item.nickname}}</div>
						<div class="sii-name" v-if="module == 'distribution'">{{item.name}}</div>
						<div>uid:{{item.user_id}}</div>
					</div>
					<img class="sii-check-img" v-if="includesUser(item.user_id)" src="@/assets/img/icon-share-selected.png" alt="">
				</div>
			</div>
			<div class="pager">
				<a-pagination
					show-size-changer
					:default-current="info.page"
					:total="info.count"
					:default-page-size="info.limit"
					@showSizeChange="(p,e)=>{getAccountList(info.page,e)}"
					@change="(e)=>{getAccountList(e,info.limit)}"
				/>
			</div>
		</a-modal>
	</div>
</template>

<script>
import { computed, reactive, toRefs,watch } from 'vue'
import userModel from '@/api/user'
import distributionModel from '@/api/addons/distribution'
import tool from '@/util/tool'
export default{
	name:'com-select-user',
	props:{
		value:{
			type:[String,Number,Array,Object]
		},
		selectStyle:{	//组件类型	1 input选择 2 select选择
			type:Number,
			default:1
		},
		module:{	//用户模块 user全部用户	 distribution分销商用户
			type:String,
			default:'user'
		},
		custom:{		//是否自定义选择按钮
			type:Boolean,
			default:false
		},
		mutil:{			//是否支付多选
			type:Boolean,
			default:false
		}
	},
	setup(props,context){
		const state = reactive({
			user_id:props.value || [],
			show:false,
			info:{
				list:[],
				page:1,
				limit:20,
				count:0
			},
			search:{ key:'' }
		})

		if( typeof state.state)

		watch(() => props.value,(value) => {
			if( props.mutil ){
				state.user_id  = value 
				return
			}
			checkUserIdType(value)
		})

		const includesUser = computed(()=>{
			return function(id){
				if( !state.user_id ) return
				checkUserIdType(state.user_id)
				return state.user_id.includes(id)
			}
		})

		const userIdJoin=computed(()=>{
			if( state.user_id ){
				if( typeof state.user_id == 'object'){
					return state.user_id.join(',')
				}
				return state.user_id
			}
			return ""
		})

		//检测 state.user_id 的数据类型 ，根据数据类型赋值
		function checkUserIdType(data){
			switch(typeof data){
				case 'number':
					state.user_id = [data]
					break;
				case 'string':
					let str = data.split(',')
					state.user_id = str
					break;
				default:
					state.user_id = data
					break;
			}
		}

		function showUser(){
			getAccountList(1,state.info.limit)
			state.show = true
		}

		function getAccountList(page,limit){
			if( !state.search.key && props.selectStyle ==2 ) return
			if( props.module == 'user'){
				userModel.getAccount(page,limit,state.search,res=>state.info = {limit,...res})
			}

			if( props.module == 'distribution' ){
				distributionModel.getDisUser(page,limit,{status:2,name:state.search.key},res=>state.info = {limit,...res})
			}
		}

		function sureSelect(){
			if( !state.user_id || state.user_id.length == 0){
				tool.message("请选择用户",'warning')
				return
			}
			state.show = false
			if( props.mutil ){
				context.emit("change",state.user_id)
				return
			}
			context.emit("change",state.user_id[0])
		}

		function searchSelect(e){
			state.search.key = e
			getAccountList(1,state.info.limit)
		}

		function selectUserId(id){
			checkUserIdType(state.user_id)
			if( state.user_id && state.user_id.includes(id) ){ //已存在，则取消选择
				state.user_id = state.user_id.filter(val=>val!==id)
				return
			}
			if( props.mutil ){  //多选
				state.user_id.push(id)
				return
			}
			state.user_id = [id]
		}

		return{
			...toRefs(state),
			showUser,
			getAccountList,
			sureSelect,
			searchSelect,
			includesUser,
			selectUserId,
			userIdJoin,
			checkUserIdType
		}
	}
}
</script>

<style lang="scss">
	.csu-user-list{
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		margin-top: 12px;
		
		&-item{
			width: 178px;
			border: 1px solid #f4f4f4;
			margin-right: 10px;
			margin-bottom: 10px;
			display: flex;
			align-items: center;
			padding: 8px 4px;
			cursor: pointer;
			
			&-li{
				margin-left: 6px;
			}
		}
		.sii-check-img{
			position: absolute;
			margin-left: 138px;
			margin-top: 30px;
		}
		.active{
			border:1px solid #FF0033;
		}
	}
</style>
