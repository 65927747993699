<template>
	<div class="admin-common">
		<a-layout class="ac-layout">
			<a-layout-sider v-model:collapsed="show.collapsed" :trigger="null" collapsible class="ac-slide" width="180">
				<a-menu 
				theme="dark" 
				mode="inline" 
				v-model:selectedKeys="show.selectedKeys" 
				v-model:openKeys="show.openKeys" 
				@select="selectMenu"
				@openChange="onOpenChange">
					<template v-for="(item,index) in perm">
						<template v-if="item.children.length == 0">
							<a-menu-item :key="index" @click="navChange(item)">
								<i :class="item.menu_icon" class="ri-lg ri-top"></i>
								<span  v-if="!show.collapsed" class="menu-title">{{ item.title }}</span>
							</a-menu-item>
						</template>
						<a-sub-menu :key="index" v-else>
							<template #title>
								<span>
									<i :class="item.menu_icon" class="ri-lg ri-top"></i>
									<span v-if="!show.collapsed" class="menu-title">{{ item.title }}</span>
								</span>
							</template>
							<a-menu-item :key="index+'-'+(ind+1)" v-for="(val,ind) in item.children" @click="navChange(val)">{{ val.title }}</a-menu-item>
						</a-sub-menu>
					</template>
				</a-menu>
			</a-layout-sider>
			<a-layout>
				<a-layout-header class="ac-layout-header" style="background: #fff; padding: 0 24px">
					<a-space>
						<i class="ri-lg" :class="show.collapsed ?'ri-menu-unfold-line':'ri-menu-fold-line'" @click="show.collapsed =!show.collapsed"></i>
						<i class="ri-refresh-line ri-lg" @click="reload()"></i>
					</a-space>
					<a-space>
						<a-badge :count="$store.getters.statisticsInfo && $store.getters.statisticsInfo.is_read" dot>
							<router-link to="/admin/message/message_list">
								<span style="color:#333;">消息通知</span>
							</router-link>
						</a-badge>
						<a-divider type="vertical" />
						<span @click="clearCache">更新缓存</span>
						<a-divider type="vertical" />
						<a-dropdown>
							<span>{{ $store.state.username}}<i class="ri-arrow-down-s-fill ri-top"></i></span>
							<template #overlay>
								<a-menu>
									<router-link to="/admin/user_detail">
										<a-menu-item>我的账号</a-menu-item>
									</router-link>
									<a-menu-item @click="logout">退出</a-menu-item>
								</a-menu>
							</template>
						</a-dropdown>
						
					</a-space>
					
				</a-layout-header>
				<a-layout-content :style="{ margin: '24px 16px', minHeight: '280px' }">
					<a-spin :spinning="$store.state.loading">
						<router-view></router-view>
					</a-spin>
				</a-layout-content>
			</a-layout>
		</a-layout>
	</div>
</template>
<script>
import { reactive, toRefs } from 'vue';
import store from '@/store'
import RouteHelper from '@/router/routeHelper.js'
import commonModel from '@/api/common';
import login from '@/api/login';
import router from '@/router'

const rhModel = new RouteHelper()
export default {
	inject:["reload"],
	setup(){
		const state = reactive({
			perm: [],
			temp: [], //组装成想要的菜单信息的中间变量
			is_shop: 0,
			show: {
				collapsed: false,
				selectedKeys: [],
				openKeys:[],
			},
		})

		if( localStorage.getItem('sass_nav_key')){
			let key = localStorage.getItem("sass_nav_key")
			let str = key.split("-")
			state.show.selectedKeys = key == '0' ? [parseInt(key)] :[key]
			state.show.openKeys = [parseInt(str[0])]
		}

		let userInfo = JSON.parse(localStorage.getItem('kdFarmUserInfo'));
		store.dispatch('setUserInfo',userInfo)

		//获取菜单
		rhModel.getMenu('web').then(res=>state.perm = res)


		commonModel.hanldeMessage('read',null,()=>{})

		//菜单被选中
		function selectMenu(e){
			localStorage.setItem("sass_nav_key",e.key)
			state.show.selectedKeys = [e.key]
		}
		
		const navChange = data=>router.push(data.path)
		const onOpenChange = openKeys =>state.show.openKeys = [openKeys[openKeys.length-1]]
		const clearCache = ()=>commonModel.clearCache()
		const logout = ()=>login.logout()

		return{
			...toRefs(state),
			navChange,
			selectMenu,
			onOpenChange,
			clearCache,
			logout
		}
	}
}
</script>
<style lang="scss" scoped>
.ac-layout {
	height: 100%;
	width: 100%;
	position: fixed;
	left: 0;
	top: 0;
	.menu-title{
		margin-left: 12px;
	}
	
	&-header{
		cursor: pointer;
		display: flex;
		justify-content: space-between;
	}
}
</style>
