<template>
    <div class="kd-map-earth">
        <div class="ksem-search-box">
			<input class="kdem-search-input" placeholder="搜索地址" type="text" id="input_id">
		</div>
        <!-- 画图 -->
        <div class="draw-box">
            <div class="draw-box-li" @click="drwaPolygon" v-if="drawType == 'polygon'">
                <a-tooltip title="绘制多边形">
                    <img src="../../assets/img/duobianxing.png" alt="">
                </a-tooltip>
            </div>
            <div class="draw-box-li" @click="drawCircle" v-if="drawType == 'circle'">
                <a-tooltip title="绘制圆形">
                    <i class="ri-edit-circle-line"></i>
                </a-tooltip>
            </div>
            <div class="draw-box-li" @click="clearMap">
                <a-tooltip title="重新绘制">
                    <i class="ri-delete-bin-line"></i>
                </a-tooltip>
            </div>
            <div class="draw-box-li" @click="completeDraw">
                <a-tooltip title="绘制完成">
                    <i class="ri-check-line"></i>
                </a-tooltip>
            </div>
        </div>
        <div id="allmap" :style="{width:'100%',height:mapHeight+'px'}"></div>
    </div>
</template>
<script>
import GdMap from '@/util/GdMap'
import {  reactive, toRefs,nextTick, watch } from 'vue';
export default {
    name:'kd-map-earth',
    props:{
        mapWidth:{
            type:Number,
            default:500
        },
        mapHeight:{
            type:Number,
            default:500
        },
        isSatellite:{       //是否加载卫星地图
            type:Boolean,
            default:false,
        },
        drawType:{         //绘制图像类型 polygon多边形  circle圆形
            type:String,
            default:"polygon"
        },
        mapValue:{
            type:[Object,Array]
        }
    },
    setup(props,context) {
        const state = reactive({
            map:null,
        })

        watch(()=>props.mapValue,val=>{
            if( props.drawType == 'circle'){
                if( val && val.lnglat.length > 0 ){
                    state.map.clearMap()
                    state.map.drawCircle(res=>{
                        context.emit("getMapData",res)
                    },val.lnglat,val.radius)
                }
            }
        })

        ;(function(){
            let map = new GdMap({el:'allmap',drawType:props.drawType})
            nextTick(()=>{
                let la = null
                if( props.drawType == 'polygon' ){
                    if( props.mapValue && props.mapValue.length > 0 ){
                        la = props.mapValue[0]
                        map.initMap(true,la)
                        map.drawPolyon(res=>{
                            context.emit("getMapData",res)
                        },props.mapValue)
                    }else{
                        map.initMap(true,la)
                    }
                }

                if( props.drawType == 'circle' ){
                    if( props.mapValue && props.mapValue.lnglat.length > 0 ){
                        map.initMap(true,props.mapValue.lnglat)
                        map.drawCircle(res=>{
                            context.emit("getMapData",res)
                        },props.mapValue.lnglat,props.mapValue.radius)

                    }else{
                        map.initMap(true,la)
                    }
                }

                map.searchMap("input_id")
            })
            state.map = map
        })();

        const drwaPolygon = ()=>state.map.drawPolyline(res=>{
            context.emit("getMapData",res)
        })

        const drawCircle = ()=>state.map.drawCircle(res=>{
            context.emit("getMapData",res)
        })

        const clearMap = ()=>state.map.clearMap()
        const completeDraw = ()=>state.map.completeDraw()

        return{
            ...toRefs(state),
            clearMap,
            drawCircle,
            completeDraw,
            drwaPolygon,
        }
    },
}
</script>
<style lang="scss" scoped>
.ksem-search-box{
    position: absolute;
    margin-top: 10px;
    z-index: 999;
    padding-left: 24px;
    box-sizing: border-box;
    .kdem-search-input{
        width: 300px;
        z-index: 999;
        height: 34px;
        border-radius: 3px;
        border: 0;
        outline: none;
        padding-left: 12px;
    }
}


.draw-box{
    position: absolute;
    right: 24px;
    height:36px;
    background: #fff;
    z-index: 99;
    padding:6px;
    box-sizing: border-box;
    margin-top: 12px;
    border-radius: 6px;
    display: flex;
    &-li{
        width: 36px;
        height: 100%;
        border-right: 1px solid #f4f4f4;
        cursor: pointer;
        text-align: center;

        i{
            font-size: 22px;
            position: relative;
            top: -3px;
            color: #7d7d7d;
        }
    }

    &-li:last-child{
        border-right: none;
    }
    img{
        width: 24px;
        height: 24px;
    }
}
</style>