<template>
    <!-- 农资管理 -->
    <div class="farm-data">
        <div class="el-content mb12">
            <a-space>
                <a-input-search
                    v-model:value="search.key"
                    placeholder="输入客户编号/名称/联系人/电话/备注查询"
                    enter-button
                    style="width:400px"
                    @search="onSearch"
                />  
                <a-button type="primary" @click="show.add = true"><i class="ri-add-line ri-btn"></i>新增农资</a-button>
                <a-button><i class="ri-upload-line ri-btn"></i>批量导入</a-button>
            </a-space>
        </div>
        <div class="el-content">
            <div class="el-content">
                <a-table :pagination="false" rowKey="id" :data-source="info.list" :columns="[
                    {title:'农资编号',dataIndex:'number'},
                    {title:'图片',dataIndex:'img'},
                    {title:'农资名称',dataIndex:'name'},
                    {title:'农资条码',dataIndex:'spec'},
                    {title:'规格型号',dataIndex:'spec'},
                    {title:'首选仓库',dataIndex:'warehouse'},
                    {title:'当前库存',dataIndex:'count'},
                    {title:'单位成本',dataIndex:'price'},
                    {title:'状态',dataIndex:'status'},
                    {title:'备注',dataIndex:'remark'},
                    {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
                ]" :row-selection="{ selectedRowKeys: pks, onChange:(e)=>{pks = e}  }">
                    <template #action="{record}">
                        <a-button type="link">编辑{{record.id}}</a-button>
                        <a-button type="link">删除</a-button>
                    </template>
                </a-table>
                <div class="pager">
                    <a-space>
                        <a-button>禁用</a-button>    
                        <a-button>启用</a-button>    
                        <a-button>导出</a-button>    
                        <a-button>删除</a-button>    
                    </a-space>
                    <a-pagination
                        show-size-changer
                        :default-current="info.page"
                        :total="info.count"
                        @showSizeChange="(p,e)=>{getDisRule(info.page,e)}"
                        @change="(e)=>{getDisRule(e,info.limit)}"
                    />
                </div>
            </div>
        </div>

        <a-modal v-model:visible="show.add" title="添加农资" @ok="handleOk" width="1000px">
            <a-form :label-col="{span:6}" :wrapper-col="{span:18}">
                <a-row :gutter="[16,16]">
                    <a-col :span="12">
                        <a-form-item label="农资编号" required>
                            <a-input v-model:value="form.name"></a-input>
                        </a-form-item>
                        <a-form-item label="农资名称" required>
                            <a-input v-model:value="form.name"></a-input>
                        </a-form-item>
                        <a-form-item label="规格型号" required>
                            <a-input v-model:value="form.name"></a-input>
                        </a-form-item>
                        <a-form-item label="首选仓库" required>
                            <a-input v-model:value="form.name"></a-input>
                        </a-form-item>
                        <a-form-item label="农资图片" required>
                            <a-input v-model:value="form.name"></a-input>
                        </a-form-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-item label="农资条码" required>
                            <a-input v-model:value="form.name"></a-input>
                        </a-form-item>
                        <a-form-item label="计量单位">
                            <a-input v-model:value="form.rank"></a-input>
                        </a-form-item>
                        <a-form-item label="备注">
                            <a-input v-model:value="form.rank"></a-input>
                        </a-form-item>
                        <a-form-item label="质保预警" required>
                            <a-input v-model:value="form.name"></a-input>
                        </a-form-item>
                        <a-form-item label="库存预警" required>
                            <a-space>
                                <a-input v-model:value="form.name"></a-input>
                                ———
                                <a-input v-model:value="form.name"></a-input>
                            </a-space>
                        </a-form-item>
                    </a-col>
                </a-row>
                
			</a-form>
        </a-modal>
    </div>
</template>
<script>
import { reactive, toRefs } from 'vue'
export default {
    setup() {
        const state = reactive({
            info:{
                list:[
                    {id:1,number:"111111",name:'白条猪',spec:'1321321321',warehouse:'城南1号仓库',count:1000,price:10.00,status:1},
                    {id:1,number:"111111",name:'白条猪',spec:'1321321321',warehouse:'城南1号仓库',count:1000,price:10.00,status:1},
                    {id:1,number:"111111",name:'白条猪',spec:'1321321321',warehouse:'城南1号仓库',count:1000,price:10.00,status:1},
                    {id:1,number:"111111",name:'白条猪',spec:'1321321321',warehouse:'城南1号仓库',count:1000,price:10.00,status:1},
                    {id:1,number:"111111",name:'白条猪',spec:'1321321321',warehouse:'城南1号仓库',count:1000,price:10.00,status:1},
                    {id:1,number:"111111",name:'白条猪',spec:'1321321321',warehouse:'城南1号仓库',count:1000,price:10.00,status:1},
                    {id:1,number:"111111",name:'白条猪',spec:'1321321321',warehouse:'城南1号仓库',count:1000,price:10.00,status:1},
                    {id:1,number:"111111",name:'白条猪',spec:'1321321321',warehouse:'城南1号仓库',count:1000,price:10.00,status:1},
                ],
                page:1,
                count:10,
                limit:1,
            },
            search:{
                key:""
            },
            show:{
                add:true
            },
            form:{
                name:"",
                rank:99
            }
        })

        return{
            ...toRefs(state)
        }
    },
}
</script>
<style scoped lang="scss">
.farm-data{
    .pager{
        display: flex;
        justify-content: space-between;
    }
    .dc-form{
        display: flex;
        flex-wrap: nowrap;
    }
}
</style>