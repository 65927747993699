import {reactive} from "vue";
import userModel from "@/api/user";

//会员列表数据
export function useMemberList(){
    let mlState = reactive({
        search:{
            key:'',
            searchTime:[],
            sort:'1',
            user_id:"",
        },
        list:[],
        page:1,
        count:0,
        limit:10,
    })

    function getMemberList(page,limit){
        userModel.getAccount(page,limit,mlState.search,res=> {
            mlState.list = res.list
            mlState.page = res.page
            mlState.count = res.count
            mlState.limit = limit
        })
    }

    return { mlState ,getMemberList }
}

/**
 * 检测用户来源
 * @param type  来源类型
 * @returns {string}
 */
export function checkMemberOrigin(type){
    if( type === 1 ) return '微信小程序'
    if( type === 2 ) return '普通注册用户'
    if( type === 3 ) return '支付宝注册'
    if( type === 4 ) return 'QQ用户'
    if( type === 5 ) return '微信用户'
    if( type === 0 ) return '未知'
}

// export function useMemberDetail(id){
//
// }
