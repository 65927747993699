<template>
	<!-- 摄像头设备详情 -->
	<div class="live-monitor-detail el-content" v-if="detail">
		<a-button class="go-back" @click="$router.go(-1)">返回</a-button>
		<a-tabs v-model:activeKey="active">
		    <a-tab-pane key="first" tab="基本信息">
				<span v-if="gateway.length > 0">
					<div class="lmd-title">
						<span>接入网关信息</span>
					</div>
					<div class="lmd-item">
						<div class="lmd-item-title">SIP 服务器 ID</div>
						<div class="lmd-item-val">{{ gateway[0].sipServerId }}</div>
                        <com-copy :value="gateway[0].sipServerId"></com-copy>
					</div>
					<div class="lmd-item">
						<div class="lmd-item-title">SIP 服务器 IP</div>
						<div class="lmd-item-val">{{ gateway[0].sipServerIP }}</div>
                        <com-copy :value="gateway[0].sipServerIP"></com-copy>
					</div>
					<div class="lmd-item">
						<div class="lmd-item-title">SIP 服务器端口号（UDP）</div>
						<div class="lmd-item-val">{{ gateway[0].sipServerPort[0] }}</div>
                        <com-copy :value="gateway[0].sipServerPort[0]"></com-copy>
					</div>
				</span>
				<div class="lmd-title" style="margin-top: 12px;">
					<span style="margin-right:8px;">设备基本信息</span>
					<a href="https://www.yuque.com/kundian/ipc/emqkfx" target="_blank">如何配置？</a>
				</div>
				<div class="lmd-item">
					<div class="lmd-item-title">类型</div>
					<div class="lmd-item-val">{{detail.cloud_device.type == 1 ?'摄像头':'平台'}}</div>
				</div>
				<div class="lmd-item">
					<div class="lmd-item-title">设备名称</div>
					<div class="lmd-item-val">
                        {{ detail.cloud_device.desc }}
                        <a-button type="link" @click="showUpdateDevice(detail,1)">修改</a-button>
                    </div>
				</div>
				<div class="lmd-item">
					<div class="lmd-item-title">状态</div>
					<div class="lmd-item-val">
						<a-tag color="#999" v-if="detail.cloud_device.state == 'offline'">离线</a-tag>
						<a-tag color="#00CC66" v-if="detail.cloud_device.state == 'online'">在线</a-tag>
						<a-tag color="#FF9900" v-if="detail.cloud_device.state == 'notReg'">未注册</a-tag>
						<a-tag color="#FF0066" v-if="detail.cloud_device.state == 'locked'">锁定</a-tag>
					</div>
				</div>
				<div class="lmd-item" v-if="detail.cloud_device">
					<div class="lmd-item-title">设备国标 ID</div>
					<div class="lmd-item-val">
						{{ detail.cloud_device.gbId }}
						<com-copy :value="detail.cloud_device.gbId"></com-copy>
					</div>
				</div>
				<div class="lmd-item">
					<div class="lmd-item-title">用户名</div>
					<div class="lmd-item-val">
						{{ detail.cloud_device.username }}
						<com-copy :value="detail.cloud_device.username"></com-copy>
					</div>
				</div>
				<div class="lmd-item">
					<div class="lmd-item-title">密码</div>
					<div class="lmd-item-val">
						<span v-if="password">{{ detail.cloud_device.password }}</span>
						<span v-else>******</span>
						<i class="ri-lg" @click="password = !password" :class="password ? 'ri-eye-line':'ri-eye-close-line'"></i>
                        <a-button style="margin-left: 24px" type="link" @click="showUpdateDevice(detail,2)">修改</a-button>
                    </div>
				</div>
				<div class="lmd-item">
					<div class="lmd-item-title">厂商</div>
					<div class="lmd-item-val">{{ detail.device.vendor || '-' }}</div>
				</div>
				<div class="lmd-item">
					<div class="lmd-item-title">注册成功后启动拉流</div>
					<div class="lmd-item-val">
						<a-tag color="#00CC66" v-if="detail.cloud_device.pullIfRegister">开启</a-tag>
						<a-tag color="#999" v-else>关闭</a-tag>
					</div>
				</div>
				<div class="lmd-item">
					<div class="lmd-item-title">通道数</div>
					<div class="lmd-item-val">{{ detail.cloud_device.channels }}</div>
				</div>
				<div class="lmd-item">
					<div class="lmd-item-title">创建时间</div>
					<div class="lmd-item-val">{{ detail.device.end_time || '-'}}</div>
				</div>
			</a-tab-pane>
		    <a-tab-pane key="third" tab="通道列表" v-if="detail.cloud_device.type == 2">
				<a-table :pagination="false" row-key="gbId" :data-source="channels.list" :columns="[
					{title:'通道国标 ID',dataIndex:'gbId'},
					{title:'通道名称',dataIndex:'name'},
					{title:'uid',dataIndex:'uuid'},
					{title:'状态',dataIndex:'status',slots:{customRender:'status'}},
					{title:'厂商',dataIndex:'vendor'},
					{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
				]">
				   <template #status="{record}">
					   <a-tag color="#00CC66" v-if="record.state=='online'">在线</a-tag>
					   <a-tag color="#999" v-else>离线</a-tag>
				   </template>
				   <template #action="{index}">
					   <a-button type="link" @click="changeChannelsGbId(index)">实时预览</a-button>
				   </template>
				</a-table>
			</a-tab-pane>
			<a-tab-pane key="second" tab="实时预览" style="background:#f0f2f5;padding:20px;">
				<a-row :gutter="[16,16]">
					<a-col :span="10">
						<div class="gutter-box">
							<div class="lmdpt-left">
								<com-play-gb
                                    :device-id="detail.device.id"
                                    :channle-id="channels.gbId"
                                    :webrtc="detail.user_device.webrtc"
                                    @changeChannels="getCurrenGbId">
                                </com-play-gb>
							</div>
						</div>
					</a-col>
					<a-col :span="14">
						<div class="gutter-box" >
							<div style="display:flex;padding-top:12px;">
								<div class="lmdcc-direction">
									<div class="lmd-control-title">
										方向调节
										<a-tooltip title="用于球机远程云台控制">
											<i class="ri-question-line"></i>
										</a-tooltip>
									</div>
									<div class="lmdcc-items">
										<div @mousedown="adjustDirection('leftup')" @mouseup="stopDevice('stop')" class="lmdcc-items-li"><i class="ri-lg ri-arrow-left-up-fill"></i></div>
										<div @mousedown="adjustDirection('up')" @mouseup="stopDevice('stop')" class="lmdcc-items-li"><i class="ri-lg ri-arrow-up-fill"></i></div>
										<div @mousedown="adjustDirection('rightup')" @mouseup="stopDevice('stop')" class="lmdcc-items-li"><i class="ri-lg ri-arrow-right-up-fill"></i></div>
										<div @mousedown="adjustDirection('left')" @mouseup="stopDevice('stop')" class="lmdcc-items-li"><i class="ri-lg ri-arrow-left-fill"></i></div>
										<div class="lmdcc-items-li lmdcc-items-center"></div>
										<div @mousedown="adjustDirection('right')" @mouseup="stopDevice('stop')" class="lmdcc-items-li"><i class="ri-lg ri-arrow-right-fill"></i></div>
										<div @mousedown="adjustDirection('leftdown')" @mouseup="stopDevice('stop')" class="lmdcc-items-li"><i class="ri-lg ri-arrow-left-down-fill"></i></div>
										<div @mousedown="adjustDirection('down')" @mouseup="stopDevice('stop')" class="lmdcc-items-li"><i class="ri-lg ri-arrow-down-fill"></i></div>
										<div @mousedown="adjustDirection('rightdown')" @mouseup="stopDevice('stop')" class="lmdcc-items-li"><i class="ri-lg ri-arrow-right-down-fill"></i></div>
									</div>
								</div>

								<div class="lmdcc-shot">
									<div class="lmd-control-title">镜头控制</div>
									<div class="lmdct-items">
										<div class="lmdct-items-op" @mousedown="changeZoom('focusnear')" @mouseup="stopDevice('stop')"><i class="ri-lg ri-add-line"></i></div>
										<div class="lmdct-items-txt">变焦</div>
										<div class="lmdct-items-op" @mousedown="changeZoom('focusfar')"  @mouseup="stopDevice('stop')"><i class="ri-lg ri-subtract-line"></i></div>
									</div>
									<div class="lmdct-items">
										<div class="lmdct-items-op" @mousedown="adjustDirection('zoomin')" @mouseup="stopDevice('stop')"><i class="ri-lg ri-add-line"></i></div>
										<div class="lmdct-items-txt">变倍</div>
										<div class="lmdct-items-op"  @mousedown="adjustDirection('zoomout')" @mouseup="stopDevice('stop')"><i class="ri-lg ri-subtract-line"></i></div>
									</div>
									<div class="lmdct-items">
										<div class="lmdct-items-op" @mousedown="changeAperture('irisout')" @mouseup="stopDevice('stop')"><i class="ri-lg ri-add-line"></i></div>
										<div class="lmdct-items-txt">光圈</div>
										<div class="lmdct-items-op"  @mousedown="changeAperture('irisin')" @mouseup="stopDevice('stop')"><i class="ri-lg ri-subtract-line"></i></div>
									</div>
								</div>

								<div class="lmdcc-step">
									<div class="lmd-control-title" style="margin-bottom: 12px;">步长</div>
									<a-slider v-model:value="speed" vertical style="height:160px;" :min="1" :max="10"></a-slider>
								</div>
							</div>

							<div class="lmdpt-right-top" style="height: 160px;">
								<div class="lmdpt-right-title">流信息</div>
								<div class="lmdpt-right-top-item">
									<div class="rti-title">设备名称</div>
									<div class="rti-val">{{ detail.device.desc }}</div>
								</div>
								<div class="lmdpt-right-top-item">
									<div class="rti-title">空间 ID</div>
									<div class="rti-val">{{ detail.device.nsId }}</div>
								</div>
								<div class="lmdpt-right-top-item">
									<div class="rti-title">设备国标 ID</div>
									<div class="rti-val">{{ detail.cloud_device.gbId }}</div>
								</div>
								<div class="lmdpt-right-top-item">
									<div class="rti-title">UID：</div>
									<div class="rti-val" v-if="detail.cloud_device.type == 2 && channels.list.length > 0">
										{{ channels.list[channels.index].uuid }}
										<a-button v-if="detail.cloud_device.state != 'notReg'" @click="synchronizationDeviceToLive" type="primary">同步设备到监控</a-button>
									</div>
									<div class="rti-val" v-else>
										{{ detail.device.uuid }}
										<a-button v-if="detail.cloud_device.state != 'notReg'" @click="synchronizationDeviceToLive" type="primary">同步设备到监控</a-button>
									</div>
								</div>
							</div>
						</div>
					</a-col>
					<a-col :span="10">
						<div class="gutter-box lmdcc-position">
							<div class="lmd-control-title">
								预置位
								<a-tooltip title="记忆当前画面位置">
									<i class="ri-question-line"></i>
								</a-tooltip>
								<a-space>
									<a-button size="small" type="primary" @click="position.show = true">新增预置位</a-button>
									<a-button size="small" type="primary" @click="positionGet">获取预置位</a-button>
								</a-space>
							</div>
							<div class="lmdp-table">
								<a-table :pagination="false" :scroll="{ y: 300 }" size="small" row-key="id" :data-source="position.list" :columns="[
									{title:'ID',dataIndex:'id'},
									{title:'名称',dataIndex:'name'},
									{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
								]" >
								<template #action="{record}">
									<a-button size="small" type="link" @click="positionSet(record.id)">调用</a-button>
									<a-button size="small" type="link" @click="positionDel(record.id)">删除</a-button>
								</template>
								</a-table>
							</div>
						</div>
					</a-col>
					<a-col :span="14">
						<div class="gutter-box lmdcc-position">
							<div class="lmd-control-title">
								巡航组
								<a-button size="small" type="primary" @click="addCruise(-1)">添加巡航组</a-button>
							</div>
							<div class="lmdp-table">
								<a-table :pagination="false" size="small" :scroll="{ y: 300 }" :data-source="cruise.list" row-key="id" :columns="[
									{title:'ID',dataIndex:'id'},
									{title:'名称',dataIndex:'name'},
									{title:'速度',dataIndex:'speed'},
									{title:'停留时间',dataIndex:'stay'},
									{title:'操作',dataIndex:'action',slots:{customRender:'action'},width:'120'},
								]">
								<template #action="{record}">
									<a-space>
										<a-button type="link" size="small" @click="callCruise(record.id)">调用</a-button>
										<a-button type="link" size="small" @click="deleteCruise(record.id)">删除</a-button>
									</a-space>
								</template>
								</a-table>
							</div>
						</div>
					</a-col>
				</a-row>

			</a-tab-pane>
		</a-tabs>
		<a-modal title="新增预置位"
			v-model:visible="position.show"
			@ok="positionSave"
			@cancel="position.show = false"
			width="600px">
			<a-form :label-col="{span:4}" :wrapper-col="{span:16}">
				<a-form-item label="预置位名称">
					<a-input v-model:value="position.name"></a-input>
				</a-form-item>
			</a-form>
		</a-modal>
		<a-modal title="新增编辑巡航组"
			v-model:visible="cruise.show"
			@ok="curiseSave"
			@cancel="cruise.show = false"
			width="600px">
			<a-form :label-col="{span:4}" :wrapper-col="{span:16}">
				<a-form-item label="名称">
					<a-input v-model:value="cruise.form.name"></a-input>
				</a-form-item>
				<a-form-item label="通道ID" v-if="detail.cloud_device.type == 2">
					<a-select v-model:value="cruise.form.streams" placeholder="请选择通道" @change="changeCruisePosition">
						<a-select-option v-for="(item,index) in channels.list" :key="index" :value="item.gbId">{{item.name}}</a-select-option>
					</a-select>
				</a-form-item>
				<a-form-item label="速度">
					<a-input-number v-model:value="cruise.form.speed" :min="1" :max="10"></a-input-number>
				</a-form-item>
				<a-form-item label="停留时间">
					<a-input v-model:value="cruise.form.stay" type="number" addon-after="秒" :min="15" :max="3600"> </a-input>
				</a-form-item>
				<a-form-item label="预置位">
					<a-select v-model:value="cruise.form.presetIds" mode="multiple" placeholder="请选择预置位">
						<a-select-option v-for="(item,index) in cruise.position" :key="index" :value="item.id">{{item.name}}</a-select-option>
					</a-select>
				</a-form-item>
			</a-form>
		</a-modal>

        <a-modal title="修改设备信息"
                 v-model:visible="emState.show"
                 @ok="saveDeviceInfo"
                 @cancel="emState.show = false"
                 width="600px">
            <a-form :label-col="{span:4}" :wrapper-col="{span:16}">
                <a-form-item label="设备名称" v-if="emState.update_type == 1">
                    <a-input v-model:value="emState.form.desc"></a-input>
                </a-form-item>
                <div v-if="emState.update_type == 2">
                    <a-form-item label="设备密码" >
                        <a-input v-model:value="emState.form.password"></a-input>
                    </a-form-item>
                    <a-form-item label="确认密码" >
                        <a-input v-model:value="emState.form.re_password"></a-input>
                    </a-form-item>
                </div>
            </a-form>
        </a-modal>
	</div>
</template>
<script>
import live from '@/api/live'
import { reactive, toRefs } from 'vue';
import comCopy from '@/components/public/com-copy.vue'
import comPlayGb from '@/components/layout/live/com-play-gb.vue'
import { onBeforeRouteLeave } from 'vue-router';
import store from '@/store'
import { useEditMonitor } from '@/models/live'

export default{
	components:{
		comCopy,
		comPlayGb
	},
	setup(){
		const options = live.getRouterQuery()

		//设备相关信息
		const dvData = reactive({
			active:"first",
			password:false,
			detail:null,	//设备详情
			gateway:[],		//网关信息
			channels:{
				list:[],	//通道列表
				index:0,	//选择的通道索引
				gbId:"",	//选择的通道id
			},
			speed:5,

			position:[],	//预设位置
		})
		if( options.active ) dvData.active = options.active

        let {emState,showUpdateDevice,saveDeviceInfo } = useEditMonitor(getMonitorDetail)

		//设备网关信息
		live.getNameSpacesInfo(options.id).then(res=>{
			dvData.gateway = res
		})

        getMonitorDetail()

        //获取设备信息
        function getMonitorDetail(){
            live.getGbDeviceDetail({id:options.id}).then(res=>{
                dvData.detail = res
                if( res.cloud_device.type == 1){
                    //获取巡航信息
                    getCruisegroups(0)
                }

                //平台类型
                if( res.cloud_device.type == 2){
                    live.getChannel(options.id).then(res1=>{
                        dvData.channels.list = res1.items
                        if( res1.items.length > 0 ){
                            dvData.channels.gbId = res1.items[0].gbId
                            //获取巡航信息
                            getCruisegroups(dvData.channels.gbId)
                        }
                    })
                }
            })
        }




		//切换视频源
		function changeChannelsGbId(e){
			dvData.active = "second"
			dvData.channels.index = e
			dvData.channels.gbId = dvData.channels.list[e].gbId

		}

		//方向控制
		function adjustDirection( cmd ){
			live.directionControl(options.id,cmd,dvData.speed,dvData.channels.gbId).then(()=>{
			})
		}

		//停止控制
		function stopDevice(cmd){
			live.directionControl(options.id,cmd,dvData.speed,dvData.channels.gbId)
		}

		//变焦操作
		function changeZoom( cmd ){
			let channel = dvData.detail.cloud_device.type == 2 ? (dvData.channels ? dvData.channels.gbId :''):""
			live.changeFocus(options.id,cmd,dvData.speed,channel)
		}

		//光圈操作
		function changeAperture( cmd ){
			live.changeAperture(options.id,cmd,dvData.speed,dvData.channels.gbId)
		}

		//预置位信息
		const position = reactive({
			list:[],
			name:"",		//新增预置位名称
		})

		//获取预设位置
		function positionGet(){
			live.getPrePosition(options.id,dvData.channels.gbId).then(res=>{
				position.list = res.items
			})
		}

		//预设位置保存
		function positionSave(){
			live.savePositon(options.id,"set",position.name,dvData.channels.gbId).then(res=>{
				position.show = false
				position.name=""
				positionGet()
			})
		}

		//删除预设位置
		function positionDel( id ){
			live.savePositon(options.id,'remove',id,dvData.channels.gbId).then(()=>{
				positionGet()
			})
		}

		//调用预设位置
		function positionSet(id){
			live.savePositon(options.id,'goto',id,dvData.channels.gbId).then(()=>{
				// positionGet()
			})
		}

		//同步设备到监控
		function synchronizationDeviceToLive(){
			live.deviceToLive(options.id,dvData.channels.gbId)
		}

		//巡航组信息
		const cruise = reactive({
			list:[],
			streams:"",	//通道id
			position:[],//选择预置位
			show:false,
			form:{
				id:0,
				name:"",
				streams:"",
				presetIds:[],
				speed:7,
				stay:15
			}
		})

		//获取巡航组信息
		function getCruisegroups(streams){
			live.getCruisegroups(dvData.detail.device.cloud_id,streams).then(res=>{
				cruise.list = res.items
				cruise.streams = streams
			})
		}

		function addCruise(){
			cruise.form.id = dvData.detail.device.cloud_id
			if(dvData.detail.cloud_device.type != 2){
				changeCruisePosition(0)
			}
			cruise.show = true
		}

		function changeCruisePosition(e){
			live.getPrePosition(options.id,e).then(res=>{
				cruise.position = res.items
			})
		}

		//保存巡航组信息
		function curiseSave(){
			live.addCruisegroups(cruise.form).then(()=>{
				cruise.show = false
				getCruisegroups(dvData.channels.gbId)
			})
		}

		//调用巡航组
		function callCruise(id){
			live.callCruise(dvData.detail.user_device.id,id,dvData.channels.gbId)
		}

		function deleteCruise(id){
			live.deleteCruise(dvData.detail.user_device.id,id,dvData.channels.gbId).then(()=>{
				getCruisegroups(dvData.channels.gbId)
			})
		}

		function getCurrenGbId(e){
			dvData.channels.gbId = e
			dvData.channels.list.forEach((item,index)=>{
				if( item.gbId == e ){
					dvData.channels.index = index
				}
			})

		}
		onBeforeRouteLeave((to,from,next)=>{
			if( to.path == '/live/monitor_list'){
				store.dispatch("setKeepPage",{name:to.name,type:1})
			}else{
				store.dispatch("setKeepPage",{name:to.name,type:2})
			}
			next()
		})

		return{
			...toRefs(dvData),
			position,
			cruise,

			adjustDirection,
			changeZoom,
			changeAperture,
			positionGet,
			positionSave,
			positionDel,
			positionSet,
			synchronizationDeviceToLive,
			changeChannelsGbId,
			getCruisegroups,
			addCruise,
			changeCruisePosition,
			curiseSave,
			callCruise,
			deleteCruise,
			stopDevice,
			getCurrenGbId,

            emState,
            showUpdateDevice,
            saveDeviceInfo
		}
	},
}
</script>

<style lang="scss">
	.gutter-box {
		background: #fff;
		padding: 12px;
	}
	.live-monitor-detail{
		.go-back{
			position: absolute;
			right: 3%;
			z-index: 999;
		}
		.lmd-title{
			span{
				font-size: 16px;
				font-weight: bold;
			}
			a{
				font-size: 12px;
				text-decoration: none;
				color: #0080FF;
			}
		}
		.lmd-item{
			display: flex;
			align-items: center;
			height: 44px;
			font-size: 14px;

			&-title{
				color: #999;
				width: 200px;
			}

			&-val{
				.ri-lg{
					left: 12px;
					position: relative;
					top: 2px;
					cursor: pointer;
				}
			}
		}
		.lmd-info{
			display: flex;
			align-items: center;
			padding-left: 12px;
			span{
				margin-right: 8px;
				color: #666;
				font-size: 14px;
			}
		}
		.lmd-preview-top{
			display: flex;
			padding:24px 12px;


		}

		//控制
		.lmd-control{
			&-title{
				color: #999;
				font-size: 14px;
			}
			&-con{
				display: flex;
			}
		}



		//预位置
		.lmdcc-position{
			height: 400px;
			.lmdp-table{
				width:100%;
				margin-top: 12px;
			}
		}
	}

	.lmdpt-left{
		width: 100%;
		// height: 400px;
		border-radius: 6px;
		&-video{
			width: 100%;
			background: #000;
			text-align: center;
			color: #fff;
		}

		&-text{
			width: 100%;
			background: #000;
			text-align: center;
			color: #fff;
			line-height: 350px;
		}

		&-btm{
			height: 50px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0 12px;
			font-size: 14px;
		}
	}
	//方向控制
	.lmdcc-direction{
		width: 210px;
		padding-left: 12px;
		.lmdcc-items{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			&-li{
				width: 36px;
				height: 36px;
				border: 1px solid #e8e8e8;
				margin: 12px 15px 15px 0;
				cursor: pointer;
				text-align: center;
				line-height: 40px;
			}

			&-center{
				width: 40px;
				border: none;
				box-shadow: none;
			}
		}
	}

	//镜头控制
	.lmdcc-shot{
		width: 180px;
		margin-left: 24px;
		.lmdct-items{
			width: 100%;
			display: flex;
			align-items: center;
			margin: 12px 15px 28px 0;
			&-op{
				width: 36px;
				height: 36px;
				border: 1px solid #e8e8e8;
				text-align: center;
				line-height: 40px;
				margin: 0 12px;
				cursor: pointer;
			}

			&-txt{
				font-size: 14px;
				color: #666;
			}
		}
	}
	.lmdpt-right{
		margin-left: 24px;
		flex-grow: 1;
		display: flex;
		box-shadow: -4px 4px 9px #e4e4e4;

		&-title{
			font-size: 14px;
		}

		&-top{
			padding:12px 24px;
			border-radius: 6px;
			font-size: 14px;
			box-sizing: border-box;
			overflow: hidden;
			overflow-x: auto;

			&-item{
				height: 30px;
				display: flex;
				align-items: center;
			}

			&-li{
				// height: 40px;
				margin-top: 12px;
			}
			.rti-title{
				color: #999;
				width: 120px;
			}
			.rti-val{
				color: #666;

				.ri-file-copy-2-line{
					cursor: pointer;
				}
			}
		}


	}
</style>
