<template>
	<!-- 权限菜单 -->
	<div class="permMenu">
		<div class="el-content">
			<a-radio-group v-model:value="addons_name" @change="changeAddons">
				<a-radio value="web">sass管理端</a-radio>
				<a-radio value="farm_admin">农场管理端</a-radio>
			</a-radio-group>
		</div>

		<div class="el-content">
			<div class="perm-menu">
				<div class="perm-menu-left">
					<a-tree show-icon default-expand-all draggable @drop="dragleave">
						<a-tree-node v-for="item in webMenu" :key="item.menu_id">
							<template #title>
								<div class="perm-menu-item">
									<div><span>{{item.menu_name}}</span></div>
									<a-space class="perm-menu-item-action">
										<span @click="editMenu(item)">编辑</span>
										<span @click="addMenuType(1, item)">添加菜单</span>
										<span @click="addMenuType(2, item)">添加方法</span>
										<span @click="addMenuType(3, item)">添加接口</span>
										<span @click="deleteMenu(item)">删除</span>
									</a-space>
								</div>
							</template>
							
							<a-tree-node v-for="val in item.children" :key="val.menu_id">
								<template #title>
									<div class="perm-menu-item" style="width: 683px;">
										<div>
											<span v-if="val.menu_type == 2"><i class="ri-settings-4-line"></i>【方法】</span>
											<span v-if="val.menu_type == 3"><i class="ri-link"></i>【接口】</span>
											<span>{{val.menu_name}}</span>
										</div>
										<a-space class="perm-menu-item-action">
											<span @click="editMenu(val)">编辑</span>
											<span @click="addMenuType(1, val)" v-if="val.menu_type == 1">添加菜单</span>
											<span @click="addMenuType(2, val)">添加方法</span>
											<span @click="addMenuType(3, val)" v-if="val.menu_type != 3">添加接口</span>
											<span @click="deleteMenu(val)">删除</span>
										</a-space>
									</div>
								</template>
								<a-tree-node v-for="v in val.children" :key="v.menu_id">
									<template #title>
										<div class="perm-menu-item" style="width: 663px;">
											<div>
												<span v-if="v.menu_type == 2"><i class="ri-settings-4-line"></i>【方法】</span>
												<span v-if="v.menu_type == 3"><i class="ri-link"></i>【接口】</span>
												<span>{{v.menu_name}}</span>
											</div>
											<a-space class="perm-menu-item-action">
												<span @click="editMenu(v)">编辑</span>
												<span @click="addMenuType(1, v)" v-if="v.menu_type == 1">添加菜单</span>
												<span @click="addMenuType(2, v)">添加方法</span>
												<span @click="addMenuType(3, v)" v-if="v.menu_type != 3">添加接口</span>
												<span @click="deleteMenu(v)">删除</span>
											</a-space>
										</div>
									</template>
									
									<a-tree-node v-for="m in v.children" :key="m.menu_id">
										<template #title>
											<div class="perm-menu-item" style="width: 648px;">
												<div>
													<span v-if="m.menu_type == 2"><i class="ri-settings-4-line"></i>【方法】</span>
													<span v-if="m.menu_type == 3"><i class="ri-link"></i>【接口】</span>
													<span>{{m.menu_name}}</span>
												</div>
												<a-space class="perm-menu-item-action">
													<span @click="editMenu(m)">编辑</span>
													<span @click="addMenuType(1, m)" v-if="m.menu_type == 1">添加菜单</span>
													<span @click="addMenuType(2, m)">添加方法</span>
													<span @click="addMenuType(3, m)" v-if="m.menu_type != 3">添加接口</span>
													<span @click="deleteMenu(m)">删除</span>
												</a-space>
											</div>
										</template>
										<a-tree-node v-for="n in m.children" :key="n.menu_id">
											<template #title>
												<div class="perm-menu-item" style="width: 648px;">
													<div>
														<span v-if="n.menu_type == 2"><i class="ri-settings-4-line"></i>【方法】</span>
														<span v-if="n.menu_type == 3"><i class="ri-link"></i>【接口】</span>
														<span>{{n.menu_name}}</span>
													</div>
													<a-space class="perm-menu-item-action">
														<span @click="editMenu(n)">编辑</span>
														<span @click="addMenuType(1, n)" v-if="n.menu_type == 1">添加菜单</span>
														<span @click="addMenuType(2, n)">添加方法</span>
														<span @click="addMenuType(3, n)" v-if="n.menu_type != 3">添加接口</span>
														<span @click="deleteMenu(n)">删除</span>
													</a-space>
												</div>
											</template>
										</a-tree-node>
									</a-tree-node>
								</a-tree-node>
							</a-tree-node>
						</a-tree-node>
					</a-tree>
				</div>
				<div class="perm-menu-right">
					<a-form :label-col="{span:4}" :wrapper-col="{span:12}">
						<a-form-item label="上级菜单">
							<div style="display: flex;">
								<a-select style="width: 230px;margin-right: 20px;" v-if="parent_index >= 0" v-model:value="parent_index" placeholder="上级菜单" disabled>
									<a-select-option :value="0">请选择上级菜单</a-select-option>
									<a-select-option :value="i" v-for="(v, i) in webMenu" :key="i">{{ v.label }}</a-select-option>
								</a-select>
								<a-select v-if="parent_index >= 0" style="width: 230px;" v-model:value="form.menu_parent" placeholder="上级菜单" disabled>
									<a-select-option :value="0">请选择上级菜单</a-select-option>
									<a-select-option :value="v.menu_id" v-for="(v, i) in webMenu[parent_index].children" :key="i">{{v.label}}</a-select-option>
								</a-select>
								<a-select v-else style="width: 230px;" v-model:value="form.menu_parent" placeholder="上级菜单" disabled>
									<a-select-option :value="0">请选择上级菜单</a-select-option>
									<a-select-option :value="v.menu_id" v-for="(v, i) in webMenu" :key="i">{{v.label}}</a-select-option>
								</a-select>
							</div>
						</a-form-item>
						<a-form-item label="菜单类型">
							<a-radio-group v-model:value="form.menu_type">
								<a-radio :value="1">菜单</a-radio>
								<a-radio :value="2">方法</a-radio>
								<a-radio :value="3">接口</a-radio>
							</a-radio-group>
						</a-form-item>
						<a-form-item label="菜单名称"><a-input v-model:value="form.menu_name"></a-input></a-form-item>
						<a-form-item label="图标"><a-input v-model:value="form.menu_icon"></a-input></a-form-item>
						<a-form-item label="插件">
							<a-select v-model:value="form.plugins" mode="multiple">
								<a-select-option v-for="(item, index) in plugins" :key="index" :value="item.key">{{item.value}}</a-select-option>
							</a-select>
						</a-form-item>
						<a-form-item label="菜单路由">
							<a-input v-model:value="form.menu_route" v-if="form.menu_type == 2 || form.menu_type == 1"></a-input>
							<a-select v-model:value="form.menu_route" filterable placeholder="请选择" v-if="form.menu_type == 3" show-search>
								<a-select-option v-for="(item, index) in routes" :key="index" :value="item.rule">{{item.rule}}</a-select-option>
							</a-select>
						</a-form-item>
						<a-form-item label="排序"><a-input v-model:value="form.menu_sort"></a-input></a-form-item>
						<a-form-item :wrapper-col="{offset:4}"><a-button type="primary" @click="saveMenu">保存</a-button></a-form-item>
					</a-form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import authModel from '@/api/saas/auth';
export default {
	setup(){
		const state = reactive({
			addons_name: 'web',
			info: {
				list: [],
				page: 1,
				count: 0,
				limit: 10
			},
			form: {
				menu_name: '',
				menu_icon: '#',
				menu_route: '',
				menu_parent: 0,
				menu_type: '1',
				addons_name: 'web',
				menu_sort: '',
				plugins: []
			},
			roles: [], //角色
			parent_index: -1, //二级分类时使用
			routes: [], //原来的routes信息系
			transferRoute: [], //transfer框中的数据结构
			checkRoute: [], //transfer选中的信息
			children: [], //transfer选中的信息整理

			webMenu: [], //组装好的菜单列表
			miniappRoute: [],
			expandedKeys: [],
			plugins: [] //插件列表
		})

		changeAddons()

		function changeAddons() {
			getAuthRoute();
			getAuthMenuList();
		}

		function getAuthRoute(){
			authModel.getAuthMenuByAddon(state.addons_name,res=>{
				state.routes = res.routes;
				let data = [];
				res.routes.forEach(item => {
					data.push({ key: item.rule, label: item.rule, disabled: false });
				});
				state.transferRoute = data;
			})
		}

		//获取已添加的菜单列表
		function getAuthMenuList(){
			authModel.getAuthMenuList(res=>{
				let { addons } = res.data;
				state.roles = res.roles;
				//开始组装成想要的菜单列表
				if (state.addons_name == 'web') {
					state.webMenu = neatenMenu(addons.web.menu);
				} else {
					state.webMenu = neatenMenu(addons.farm_admin.menu);
				}
				let temp = Object.keys(res.plugins);
				state.plugins = [];
				temp.forEach(item => {
					state.plugins.push({ key: item, value: res.plugins[item] });
				});
			})
		}

		//给添加好的菜单新增一个 “label”属性， el-tree 这个组件要展示这个属性
		function neatenMenu(data) {
			data.forEach(item => {
				item.label = item.menu_name
				if (item.children) neatenMenu(item.children)
			})
			return data;
		}

		function saveMenu(){
			let form = JSON.parse(JSON.stringify(state.form))
			form.addons_name = state.addons_name
			authModel.addOrEditMenu(form,()=>{
				getAuthMenuList();
				state.form.menu_id = 0;
				state.form.menu_name = '';
				state.form.menu_icon = '#';
				state.form.menu_route = '';
				state.form.menu_parent = 0;
				state.form.menu_type = 1;
				state.form.addons_name = 'web';
				state.form.menu_sort = 0;
				state.form.plugins = [];
			})
		}

		function addMenuType(type, data) {
			state.form.menu_type = parseInt(type);
			state.form.menu_parent = parseInt(data.menu_id);
			if (data.menu_parent > 0) {
				//有上级菜单时
				state.webMenu.forEach((item, index) => {
					if (item.menu_id == data.menu_parent) {
						state.parent_index = index;
					}
				});
			} else {
				state.parent_index = -1;
			}

			state.form.menu_id = 0;
			state.form.menu_name = '';
			state.form.menu_icon = '#';
			state.form.menu_route = '';
			state.form.addons_name = 'web';
			state.form.menu_sort = 0;
			state.form.plugins = [];
		}

		function editMenu(data) {
			state.form.menu_id = data.menu_id;
			state.form.menu_name = data.menu_name;
			state.form.menu_icon = data.menu_icon;
			state.form.menu_route = data.menu_route;
			state.form.menu_parent = parseInt(data.menu_parent);
			state.form.menu_type = parseInt(data.menu_type);
			state.form.addons_name = data.addons_name;
			state.form.menu_sort = data.menu_sort;
			state.form.plugins = data.plugins;

			if (data.menu_parent > 0) {
				//有上级菜单时
				state.webMenu.forEach((item, index) => {
					if (item.children) {
						item.children.forEach(val => {
							if (val.menu_id == data.menu_parent) {
								state.parent_index = index;
							}
						});
					}
				});
			} else {
				state.parent_index = -1;
			}
		}

		function dragleave(info){
			const dropKey = info.node.eventKey;
			const dragKey = info.dragNode.eventKey;
			const dropPos = info.node.pos.split('-');
			const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
			const loop = (data, key ,callback) => {
				data.forEach((item, index, arr) => {
				if (item.menu_id === key) {
					return callback(item, index, arr);
				}
				if (item.children) {
					return loop(item.children, key, callback);
				}
				});
			};
			const data = [...state.webMenu];

			// 找到拖动的节点信息
			let dragObj= {};
			loop(data, dragKey, (item, index, arr) => {
				arr.splice(index, 1);
				dragObj = item;
			});
			
			if (!info.dropToGap) {	//不同级拖动节点
				loop(data, dropKey, (item) => {
					item.children = item.children || [];
					item.children.push(dragObj);
					changeMenuParent(dragObj.menu_id, item.menu_id);		//改变拖拽节点的父节点为目标节点的id
				});
			} else if (
				(info.node.children || []).length > 0 && // Has children
				info.node.expanded && // Is expanded
				dropPosition === 1 // On the bottom gap
			) {
				loop(data, dropKey, (item) => {
					item.children = item.children || [];
					// where to insert 示例添加到尾部，可以是随意位置
					item.children.unshift(dragObj);
				});
			} else {
				let ar= [];
				let i = 0;
				loop(data, dropKey, (item, index, arr) => {
					ar = arr;
					i = index;
				});
				if (dropPosition === -1) {
					ar.splice(i, 0, dragObj);
					if( i+1 <=ar.length ){
						if( dragObj.menu_parent == ar[i+1].menu_parent ){
							changeMenuSort(dragObj.menu_id, ar[i+1].menu_id, 'up');
						}else{
							changeMenuParent(dragObj.menu_id, ar[i+1].menu_parent);
						}
					}else{
						if( dragObj.menu_parent == ar[i-1].menu_parent ){
							changeMenuSort(dragObj.menu_id, ar[i-1].menu_id, 'down');
						}else{
							changeMenuParent(dragObj.menu_id, ar[i-1].menu_parent);
						}
					}
				} else {
					ar.splice(i + 1, 0, dragObj);
					changeMenuSort(dragObj.menu_id, ar[i].menu_id, 'down');
				}
			}
			state.webMenu = data;
		}

		const deleteMenu = row=>authModel.deleteMenu(row.id,()=>getAuthMenuList())

		function changeMenuParent(menu_id, parent_id){
			authModel.handleMenu('node',{menu_id,parent_id})
		}
		function changeMenuSort(menu_id, neighbor_id, direction){
			authModel.handleMenu('sort',{menu_id, neighbor_id, direction})
		}

		return{
			...toRefs(state),
			getAuthRoute,
			getAuthMenuList,
			neatenMenu,
			saveMenu,
			deleteMenu,
			addMenuType,
			editMenu,
			dragleave,
			changeMenuSort,
			changeMenuParent,
			changeAddons
		}
	}
}
</script>

<style lang="scss">
.perm-menu {
	display: flex;
	justify-content: space-between;

	&-left,
	&-right {
		width: 50%;
		min-height: 600px;
	}

	.perm-menu-tree-node {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 14px;
		padding-right: 8px;
	}
	
	&-item{
		width: 700px;
		display: flex;
		justify-content: space-between;
		
		&-action{
			span{
				color: #409EFF;
				font-size: 12px;
			}
		}
	}
}
</style>
