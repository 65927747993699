<template>
    <!-- 产品 -->
    <div class="digital-product">
        <div class="el-content mb12">
            <a-space>
                <a-input-search
                    v-model:value="search.key"
                    placeholder="输入客户编号/名称/联系人/电话/备注查询"
                    enter-button
                    style="width:400px"
                    @search="onSearch"
                />  
                <a-button type="primary" @click="show.add = true"><i class="ri-add-line ri-btn"></i>新增产品</a-button>
                <a-button><i class="ri-upload-line ri-btn"></i>批量导入</a-button>
            </a-space>
        </div>

        <div class="el-content">
            <a-table :pagination="false" rowKey="id" :data-source="info.list" :columns="[
                {title:'产品编号',dataIndex:'number'},
                {title:'图片',dataIndex:'img'},
                {title:'产品名称',dataIndex:'name'},
                {title:'产品条码',dataIndex:'spec'},
                {title:'首选仓库',dataIndex:'warehouse'},
                {title:'当前库存',dataIndex:'count'},
                {title:'单位成本',dataIndex:'price'},
                {title:'零售价',dataIndex:'sale_price'},
                {title:'批发价',dataIndex:'sale_price'},
                {title:'VIP会员价',dataIndex:'sale_price'},
                {title:'状态',dataIndex:'status'},
                {title:'备注',dataIndex:'remark'},
                {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
            ]" :row-selection="{ selectedRowKeys: pks, onChange:(e)=>{pks = e}  }">
                <template #action="{record}">
                    <a-button type="link">编辑{{record.id}}</a-button>
                    <a-button type="link">删除</a-button>
                </template>
            </a-table>
            <div class="pager">
                <a-space>
                    <a-button>禁用</a-button>    
                    <a-button>启用</a-button>    
                    <a-button>导出</a-button>    
                    <a-button>删除</a-button>    
                </a-space>
                <a-pagination
                    show-size-changer
                    :default-current="info.page"
                    :total="info.count"
                    @showSizeChange="(p,e)=>{getDisRule(info.page,e)}"
                    @change="(e)=>{getDisRule(e,info.limit)}"
                />
            </div>
        </div>
    </div>
    
</template>
<script>
import { reactive, toRefs } from 'vue'
export default {
    setup() {
        const state = reactive({
            search:{
                key:'',
            },
            pks:[],
            info:{
                list:[
                    {id:1,number:"#12454",name:"巴南脐橙加工",spec:'1234567898',warehouse:'城南1号仓库',count:"100",sale_price:"1.12",price:'10000',remark:'重庆市',status:'1'},
                    {id:2,number:"#12454",name:"巴南脐橙加工",spec:'1234567898',warehouse:'城南1号仓库',count:"100",sale_price:"1.12",price:'10000',remark:'重庆市',status:'1'},
                    {id:3,number:"#12454",name:"巴南脐橙加工",spec:'1234567898',warehouse:'城南1号仓库',count:"100",sale_price:"1.12",price:'10000',remark:'重庆市',status:'1'},
                    {id:4,number:"#12454",name:"巴南脐橙加工",spec:'1234567898',warehouse:'城南1号仓库',count:"100",sale_price:"1.12",price:'10000',remark:'重庆市',status:'1'},
                    {id:5,number:"#12454",name:"巴南脐橙加工",spec:'1234567898',warehouse:'城南1号仓库',count:"100",sale_price:"1.12",price:'10000',remark:'重庆市',status:'1'},
                    {id:6,number:"#12454",name:"巴南脐橙加工",spec:'1234567898',warehouse:'城南1号仓库',count:"100",sale_price:"1.12",price:'10000',remark:'重庆市',status:'1'},
                    {id:7,number:"#12454",name:"巴南脐橙加工",spec:'1234567898',warehouse:'城南1号仓库',count:"100",sale_price:"1.12",price:'10000',remark:'重庆市',status:'1'},
                    {id:8,number:"#12454",name:"巴南脐橙加工",spec:'1234567898',warehouse:'城南1号仓库',count:"100",sale_price:"1.12",price:'10000',remark:'重庆市',status:'1'},
                    {id:9,number:"#12454",name:"巴南脐橙加工",spec:'1234567898',warehouse:'城南1号仓库',count:"100",sale_price:"1.12",price:'10000',remark:'重庆市',status:'1'},
                    {id:10,number:"#12454",name:"巴南脐橙加工",spec:'1234567898',warehouse:'城南1号仓库',count:"100",sale_price:"1.12",price:'10000',remark:'重庆市',status:'1'},
                ],
                page:1,
                count:0,
                limit:10
            },
            show:{
                add:true
            },
            form:{
                name:"",
                rank:99
            }
        })

        return{
            ...toRefs(state)
        }
    },
}
</script>
<style scoped lang="scss">
.digital-product{
    .pager{
        display: flex;
        justify-content: space-between;
    }
    .dc-form{
        display: flex;
        flex-wrap: nowrap;
    }
}
</style>