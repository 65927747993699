<template>
    <div class="el-content">
        <a-table :pagination="false" rowKey="id" :data-source="info.list" :columns="[
            {title:'ID',dataIndex:'id'},
            {title:'预警类型',dataIndex:'type'},
            {title:'预警时间',dataIndex:'time'},
            {title:'单据编号',dataIndex:'number'},
            {title:'库存物品',dataIndex:'goods_name'},
            {title:'规格型号',dataIndex:'spce'},
            {title:'数量',dataIndex:'count'},
            {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
        ]" >
            <template #action="{record}">
                <a-button type="link">立即出库{{record.id}}</a-button>
                <a-button type="link">添加入库</a-button>
            </template>
        </a-table>
        <div class="pager">
            <a-pagination
                show-size-changer
                :default-current="info.page"
                :total="info.count"
                @showSizeChange="(p,e)=>{getDisRule(info.page,e)}"
                @change="(e)=>{getDisRule(e,info.limit)}"
            />
        </div>
    </div>
</template>
<script>
import { reactive, toRefs } from 'vue'
export default {
    setup() {
        const state = reactive({
            info:{
                list:[
                    {id:1,type:'库存上限',number:'1111222',goods_name:'脐橙',spce:'188kg',count:100,time:'2020-04-12 12:11:11'},
                    {id:2,type:'库存上限',number:'1111222',goods_name:'脐橙',spce:'188kg',count:100,time:'2020-04-12 12:11:11'},
                    {id:3,type:'库存上限',number:'1111222',goods_name:'脐橙',spce:'188kg',count:100,time:'2020-04-12 12:11:11'},
                    {id:4,type:'库存上限',number:'1111222',goods_name:'脐橙',spce:'188kg',count:100,time:'2020-04-12 12:11:11'},
                    {id:5,type:'库存上限',number:'1111222',goods_name:'脐橙',spce:'188kg',count:100,time:'2020-04-12 12:11:11'},
                    {id:6,type:'库存上限',number:'1111222',goods_name:'脐橙',spce:'188kg',count:100,time:'2020-04-12 12:11:11'},
                    {id:7,type:'库存上限',number:'1111222',goods_name:'脐橙',spce:'188kg',count:100,time:'2020-04-12 12:11:11'},
                    {id:8,type:'库存上限',number:'1111222',goods_name:'脐橙',spce:'188kg',count:100,time:'2020-04-12 12:11:11'},
                    {id:9,type:'库存上限',number:'1111222',goods_name:'脐橙',spce:'188kg',count:100,time:'2020-04-12 12:11:11'},
                    {id:10,type:'库存上限',number:'1111222',goods_name:'脐橙',spce:'188kg',count:100,time:'2020-04-12 12:11:11'},
                ],
                page:1,
                count:0,
                limit:10
            }
        })

        return{
            ...toRefs(state)
        }
    },
}
</script>
<style scoped lang="scss">

</style>