<template>
	<div id="accountDetail">
		<div class="el-content">
			<div class="account-d-item">
				<div class="account-d-item-title">基本信息</div>
				<div class="account-d-item-ul">
					<img class="ad-avatar_url" :src="user.avatar_url" alt="">
					<div class="account-d-item-ul-li">
						<a-form ref="form" :model="user">
							<p><strong>{{user.nickname}}</strong></p>
							<a-form-item label="真实姓名：">
								<template v-if="update_type!=1">
									<span>{{user.true_name || '无'}}</span><span class="opration" @click="update_type=1">[修改]</span>
								</template>
								<div v-if="update_type == 1">
									<a-input v-model:value="user.true_name">
										<template #addonAfter>
											<div @click="saveAccountInfo"><i class="ri-check-line"></i></div>
										</template>
									</a-input>
								</div>
							</a-form-item>
							<a-form-item label="联系电话：">
								<template v-if="update_type!=2">
									<span>{{user.mobile|| '无'}}</span><span class="opration" @click="update_type=2">[修改]</span>
								</template>
								<template v-if="update_type == 2">
									<a-input v-model:value="user.mobile">
										<template #addonAfter>
											<div @click="saveAccountInfo"><i class="ri-check-line"></i></div>
										</template>
									</a-input>
								</template>
							</a-form-item>
							<a-form-item label="会员等级：">
								<span>{{user.level_id_name}}</span>
							</a-form-item>
							<a-form-item label="发言权限：" v-if="$store.state.modulesPerm.community==1">
								<kd-switch :value="user.speak_auth" :active-value="1" :inactive-value="2" @change="changeUserSpeckAuth"></kd-switch>
							</a-form-item>
						</a-form>
					</div>
					<div class="account-d-item-ul-li">
						<a-form ref="form" :model="user" label-width="120px">
							<a-form-item label="会员来源：">
								<span><span>{{checkUserOrigin(user.origin)}}</span></span>
							</a-form-item>
							<a-form-item label="分享码：">
								<span>{{user.share_code|| '无'}}</span>
							</a-form-item>
							<a-form-item label="一级上级分销：" v-if="user.one_dis">
								<span>{{user.one_dis.name}} (电话：{{user.one_dis.mobile}})(uid:{{user.one_dis.user_id}})</span>
							</a-form-item>

							<a-form-item label="二级上级分销：" v-if="user.two_dis">
								<span>{{user.two_dis.name}} (电话：{{user.two_dis.mobile}})(uid:{{user.two_dis.user_id}})</span>
							</a-form-item>
							<a-form-item label="注册时间：">
								<span>{{user.create_time}}</span>
							</a-form-item>
							<a-form-item label="锁粉时间：" v-if="user.lock_time">
								<span>{{user.lock_time}}</span>
							</a-form-item>
						</a-form>
					</div>
				</div>
			</div>

			<div class="account-d-item">
				<div class="account-d-item-title">资产信息</div>
				<div class="account-d-money">
					<div class="account-d-money-item">
						<p>积分</p>
						<p>{{user.score}}</p>
						<div class="admi-btn">
							<com-account-recharge :type="2" :user-id="account_id" :frozen="user.gold.frozen" @success="getUserInfo">
								充值
							</com-account-recharge>

						</div>
					</div>
					<div class="account-d-money-item">
						<p>余额</p>
						<p v-if="money">{{money.bank}}</p>
						<div class="admi-btn">
							<com-account-recharge :type="1" :user-id="account_id" :frozen="user.gold.frozen" @success="getUserInfo">
								充值
							</com-account-recharge>
							<router-link v-if="money" :to="{path:'/account/moneyRecord',query:{id:money.id,type:1}}">
								<span>交易明细</span>
							</router-link>
							<router-link v-if="money" :to="{path:'/account/moneyRecord',query:{id:money.id,type:2}}">
								<span>资金明细</span>
							</router-link>
						</div>
					</div>
					<div class="account-d-money-item">
						<p>分销</p>
						<p v-if="user.userdis">{{user.userdis.all_price || '0.00'}}</p>
						<div class="admi-btn">
							<router-link :to="{path:'/adhibition/distribution/record',query:{user_id:account_id}}">
								<span>资金明细</span>
							</router-link>
						</div>
					</div>
					<div class="account-d-money-item">
						<p>优惠券（张）</p>
						<p>{{user.coupon_num || 0}}</p>
						<div class="admi-btn">
						</div>
					</div>
				</div>
			</div>

			<div class="account-d-item">
				<div class="account-d-item-title">交易信息</div>
				<div class="account-d-deal">
					<div class="account-d-deal-item">
						<div class="addi-icon addi-icon1"><i class="ri-file-list-3-fill"></i></div>
						<div class="addi-right">
							<p>累计成交订单数(笔)</p>
							<p style="color: #409eff;">{{user.pay_order_number || 0}}</p>
						</div>
					</div>
					<div class="account-d-deal-item">
						<div class="addi-icon addi-icon2"><i class="ri-money-cny-circle-line"></i></div>
						<div class="addi-right">
							<p>累计成交金额（元）</p>
							<p style="color: #ff8934;">￥{{user.use_money || 0}}</p>
						</div>
					</div>
					<div class="account-d-deal-item" style="border: none;"></div>
					<div class="account-d-deal-item" style="border: none;"></div>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
import comAccountRecharge from '@/components/miniapp/com-account-recharge.vue'
import { reactive, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import userModel from '@/api/user.js'
import store from '@/store'
import { onBeforeRouteLeave } from 'vue-router'

export default{
	components:{
		comAccountRecharge
	},
	setup(){
		const _d =reactive({
			account_id:0,
			user:{
				gold:{},
			},
			update_type:0,		//要修改的类型 0 不修改 1修改手机号 2 修改真实姓名
			money:null,
		})
		const opt = useRoute().query
		if( opt.id ) getUserInfo()
		function getUserInfo(){
			_d.account_id = opt.id
			userModel.getAccountDetail(opt.id,res=>{
				_d.user = res
				if( res.level_id ){
					userModel.getAccountLevelByUser(res.level_id,result=>{
						_d.user.level_id_name = result.level_name
					})
					return
				}
				_d.user.level_id_name = "暂无"

			})
		}
		userModel.getAccountMoney(opt.id,res=>_d.money = res)

		function checkUserOrigin(e){
			if( e == 1 ) return '微信小程序'
			if( e == 2 ) return '普通注册用户'
			if( e == 3 ) return '支付宝注册'
			if( e == 4 ) return 'QQ用户'
			if( e == 5 ) return '微信用户'
			if( e == 0 ) return '未知'
		}

		const saveAccountInfo = ()=>{
			let data = {
				user_id:_d.account_id,
				mobile:_d.user.mobile,
				true_name:_d.user.true_name,
				remark:_d.user.remark
			}
			userModel.updateAccount(data,res=>_d.update_type = 0)
		}

		const changeUserSpeckAuth = ()=>userModel.updateAccountSpeckAuth(_d.account_id)


		onBeforeRouteLeave((to,from,next)=>{
			if( to.path == '/account/accountList'){
				store.dispatch("setKeepPage",{name:to.name,type:1})
			}else{
				store.dispatch("setKeepPage",{name:to.name,type:2})
			}
			next()
		})

		return{
			...toRefs(_d),
			getUserInfo,
			saveAccountInfo,
			changeUserSpeckAuth,
			checkUserOrigin
		}
	},
}
</script>

<style lang="scss">
.account-d-item{
	width: 100%;
	margin-bottom: 20px;

	a{
		color: #0074D9;
		text-decoration: none;
	}

	&-title{
		width: 98%;
		padding: 0 1%;
		height: 50px;
		line-height: 50px;
		font-weight: 700;
		font-size: 15px;
		background: #f4f4f4;
		margin-bottom: 20px;
	}
	.account-d-item-ul{
		width: 90%;
		margin: auto;
		display: flex;

		&-li{
			width: 440px;
			margin-left: 20px;
			font-size: 15px;
			line-height: 20px;
		}

		.opration{
			color: #0074D9;
			margin-left: 10px;
			cursor: pointer;
		}
	}
	.ad-avatar_url{
		width: 80px;
		height: 80px;
		border-radius: 50%;
	}

	// 资产信息
	.account-d-money{
		width: 100%;
		display: flex;
		justify-content: space-between;

		&-item{
			width: 24%;
			height: 150px;
			border: 1px solid #f4f4f4;
			text-align: center;
			p{
				margin: 16px 0;
			}
			p:first-child{
				font-size: 16px;
			}

			p:nth-child(2){
				font-size: 20px;
				font-weight: 700;
			}

			.admi-btn{
				width: 200px;
				margin: auto;
				display: flex;
				justify-content: center;
				cursor: pointer;
				color: #409eff;
				font-size: 13px;

				span{
					margin: 0 5px;
				}
			}
		}
	}


	//交易信息
	.account-d-deal{
		display: flex;
		justify-content: space-between;

		&-item{
			width: 20%;
			height: 150px;
			border: 1px solid #f4f4f4;
			display: flex;
			align-items: center;
			padding: 0 2%;

			.addi-icon{
				width: 80px;
				height: 80px;
				border-radius: 50%;
				text-align: center;
				line-height: 80px;
				.ri-file-list-3-fill{
					font-size: 30px;
					color: #409eff;
				}
			}
			.addi-icon1{
				background: rgba($color: #409eff, $alpha: .1);
			}
			.addi-icon2{
				background: rgba($color: #ff8934, $alpha: .1);

			}
			.ri-money-cny-circle-line{
				font-size: 30px;
				color: #ff8934;
			}

			.addi-right{
				margin-left: 20px;
				p:first-child{
					font-size: 15px;
				}
				p:last-child{
					font-size: 18px;
					font-weight: 700;
				}

			}
		}
	}
}

.account_detail .opration{
	color:#49a0f7;
	cursor: pointer;
	margin-left: 10px;
}
</style>
