<template>
    <div class="kd-map-earth">
        <div class="ksem-search-box">
			<input class="kdem-search-input" placeholder="搜索地址" type="text" id="input_id">
		</div>
        <!-- 画图 -->
        <div class="draw-box">
            <div class="draw-box-li" @click="drwaPolygon">
                <a-tooltip title="绘制多边形">
                    <img src="../../../assets/img/duobianxing.png" alt="">
                </a-tooltip>
            </div>
            <div class="draw-box-li" @click="clearMap">
                <a-tooltip title="重新绘制">
                    <i class="ri-delete-bin-line"></i>
                </a-tooltip>
            </div>
            <div class="draw-box-li" @click="completeDraw">
                <a-tooltip title="绘制完成">
                    <i class="ri-check-line"></i>
                </a-tooltip>
            </div>
        </div>
        <div id="allmap" :style="{width:'100%',height:600+'px'}"></div>
    </div>
</template>
<script>
import GdMap from '@/util/GdMap'
import {  reactive, toRefs,nextTick, watch } from 'vue';
export default {
    name:'kd-map-earth',
    props:{
        isSatellite:{       //是否加载卫星地图
            type:Boolean,
            default:false,
        },
        drawType:{         //绘制图像类型 polygon多边形  circle圆形
            type:String,
            default:"polygon"
        },
        mapValue:{
            type:[Object,Array]
        },
        latitude:{
            type:[String,Number]
        },
        longitude:{
            type:[String,Number]
        }
    },
    setup(props,context) {
        const state = reactive({
            map:null,
        })

        ;(function(){
            let map = new GdMap({el:'allmap',drawType:props.drawType})
            nextTick(()=>{
                let la = [116.397428, 39.90923]
                if( props.latitude && props.longitude ){
                    la = [props.longitude,props.latitude]
                }

                let pointArr = props.mapValue
                if( pointArr && pointArr.length > 0 ){
                    map.initMap(true,la)
                    pointArr.forEach((item,index)=>{
                        if( item.point && item.point.length > 0 ){
                            map.drawPolyon(res=>{
                                let result = props.mapValue
                                for (let i = 0; i <result.length ; i++) {
                                    if( result[i].edit ) {
                                        result[i].point = res.point
                                    }
                                }
                                context.emit("getMapData",result)
                            },item.point,item.edit,item.color,item.color)
                        }
                    })
                }else{
                    console.log('1111')
                    map.initMap(true,la)
                }
                map.searchMap("input_id")
            })
            state.map = map
        })();

        const drwaPolygon = ()=>state.map.drawPolyline(res=>{
            console.log('++++++++++',res.point);
            let pointArr = props.mapValue
            for (let i = 0; i <pointArr.length ; i++) {
                if( pointArr[i].edit ) {
                    pointArr[i].point = res.point
                }
            }
            context.emit("getMapData",pointArr)
        })

        const drawCircle = ()=>state.map.drawCircle(res=>{
            context.emit("getMapData",res)
        })

        const clearMap = ()=>state.map.clearMap()
        const completeDraw = ()=>state.map.completeDraw()

        return{
            ...toRefs(state),
            clearMap,
            drawCircle,
            completeDraw,
            drwaPolygon,
        }
    },
}
</script>
<style lang="scss" scoped>
.ksem-search-box{
    position: absolute;
    margin-top: 10px;
    z-index: 999;
    padding-left: 24px;
    box-sizing: border-box;
    .kdem-search-input{
        width: 300px;
        z-index: 999;
        height: 34px;
        border-radius: 3px;
        border: 0;
        outline: none;
        padding-left: 12px;
    }
}


.draw-box{
    position: absolute;
    right: 24px;
    height:36px;
    background: #fff;
    z-index: 99;
    padding:6px;
    box-sizing: border-box;
    margin-top: 12px;
    border-radius: 6px;
    display: flex;
    &-li{
        width: 36px;
        height: 100%;
        border-right: 1px solid #f4f4f4;
        cursor: pointer;
        text-align: center;

        i{
            font-size: 22px;
            position: relative;
            top: -3px;
            color: #7d7d7d;
        }
    }

    &-li:last-child{
        border-right: none;
    }
    img{
        width: 24px;
        height: 24px;
    }
}
</style>
