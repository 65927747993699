<template>
    <div class="com-server-select">
        <a-cascader
            style="width:200px;"
            v-model:value="value"
            :options="scheme"
            :load-data="loadData"
            placeholder="选择分支"
            change-on-select
            :field-names="{label: 'father_name', value: 'id', children: 'children'}"
            @change="getValue"
        />
    </div>
</template>
<script>
import { reactive, toRefs, watch } from 'vue'
import serviceModel from '@/api/saas/service.js'
export default {
    name:'com-server-select',
    props:{
        server:{
            type:Object,
            default:0
        }
    },
    setup(props,context) {
        const _d = reactive({
            value:[],
            scheme:[],
        }) 
        
        watch(() => props.server,(val) => {
            _d.scheme.forEach(item=>{
                if( item.id == val.father_id){
                    if( !item.children || item.children.length == 0 ){
                        serviceModel.getBranch(item.id,1,res=>{
                            let branch = []
                            res.forEach(item=>{
                                branch.push({father_name:item.server_name,id:item.server_id})
                            })
                            item.children = branch
                        })
                    }
                    _d.value = [item.id,val.server_id]
                }
            })
        },true)

        const loadData = (selectedOptions) => {
            let target = selectedOptions[selectedOptions.length - 1]
            target.loading = true
            serviceModel.getBranch(target.id,1,res=>{
                let branch = []
                res.forEach(item=>{
                    branch.push({father_name:item.server_name,id:item.server_id})
                })
                target.children = branch
                target.loading = false
            })
        }

        serviceModel.getServerScheme(1,999,res=>{
			_d.scheme = res.list
            _d.scheme.forEach(item=>item.isLeaf = false)

            //回显操作
            if(props.server ){
                _d.scheme.forEach(item=>{
                    if( item.id == props.server.father_id){
                        if( !item.children || item.children.length == 0 ){
                            serviceModel.getBranch(item.id,1,res=>{
                                let branch = []
                                res.forEach(item=>{
                                    branch.push({father_name:item.server_name,id:item.server_id})
                                })
                                item.children = branch
                            })
                        }
                        _d.value = [item.id,props.server.server_id]
                        console.log('_d.value',_d.value);
                        
                    }
                })
            }
		})
        const getValue = (e)=>{
            let data = e[e.length-1]
            context.emit("success",data)
        }
        return{
            ...toRefs(_d),
            loadData,
            getValue
        }
    },
}
</script>
<style lang="scss" scoped>
.com-server-select{
    display: inline-block;
}
</style>