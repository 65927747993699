<template>
    <div class="com-select-link">
        <a-input v-model:value="path">
            <template #addonAfter>
                <div class="select-icon" @click="show = true">选择链接</div>
            </template>
        </a-input>
        <a-modal title="选择链接" v-model:visible="show"  @ok="sureSelect" @cancel="show = false" width="1000px">
            <a-tabs v-model:activeKey="active" @change="changeTab">
                <a-tab-pane key="1" tab="链接地址">
                    <span v-for="(item,index) in shop_btn" :key="index">
                        <span v-if="item.path == 'goto_map'" > 
                            <kd-map :custom="true" @change="selectAddress">
                                <a-button style="margin: 10px 10px 0 0">导航</a-button>
                            </kd-map>
                        </span>
                        <a-button v-else @click="selectPath(index)" style="margin: 10px 10px 0 0">{{item.name}}</a-button>
                    </span>
                    
                </a-tab-pane>
                <a-tab-pane key="2" tab="商品">
                    <a-input-search v-model:value="goods.key" 
                        placeholder="商品名称" 
                        enter-button 
                        style="width:400px;"
                        class="mb12"
                        @search="getDataList(1,goods.limit)" 
                    />
                    <a-table size="small" :scroll="{y: 400}" :pagination="false" :data-source="goods.list" row-key="id" :columns="[
                        {title:'ID',dataIndex:'id'},
                        {title:'封面',dataIndex:'cover',slots:{customRender:'cover'}},
                        {title:'商品名称',dataIndex:'goods_name',width:'500px'},
                        {title:'价格',dataIndex:'price'},
                        {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
                    ]" >
                        <template #cover="{record}">
                            <a-image :src="record.cover" style="width:40px;height:40px"></a-image>
                        </template>
                        <template #action="{record}">
                            <a-button @click="selectPath(record.id)">选择</a-button>
                        </template>
                    </a-table>
                </a-tab-pane>
                <a-tab-pane key="3" tab="租地">
                    <a-input-search v-model:value="goods.key" 
                        placeholder="土地名称" 
                        enter-button 
                        style="width:400px;"
                        class="mb12"
                        @search="getDataList(1,goods.limit)" 
                    />
                    <a-table size="small" :scroll="{y: 400}" :pagination="false" :data-source="goods.list" row-key="id" :columns="[
                        {title:'ID',dataIndex:'id'},
                        {title:'封面',dataIndex:'cover',slots:{customRender:'cover'}},
                        {title:'土地名称',dataIndex:'land_name',width:'500px'},
                        {title:'最低价格',dataIndex:'price'},
                        {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
                    ]" >
                        <template #cover="{record}">
                            <a-image :src="record.cover" style="width:40px;height:40px"></a-image>
                        </template>
                        <template #action="{record}">
                            <a-button @click="selectPath(record.id)">选择</a-button>
                        </template>
                    </a-table>
                </a-tab-pane>
                <a-tab-pane key="4" tab="认养">
                    <a-input-search v-model:value="goods.key" 
                        placeholder="认养名称" 
                        enter-button 
                        style="width:400px;"
                        class="mb12"
                        @search="getDataList(1,goods.limit)" 
                    />
                    <a-table size="small" :scroll="{y: 400}" :pagination="false" :data-source="goods.list" row-key="id" :columns="[
                        {title:'ID',dataIndex:'id'},
                        {title:'封面',dataIndex:'cover',slots:{customRender:'cover'}},
                        {title:'认养名称',dataIndex:'name',width:'500px',slots:{customRender:'name'}},
                        {title:'最低价格',dataIndex:'price'},
                        {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
                    ]" >
                        <template #cover="{record}">
                            <a-image :src="record.cover" style="width:40px;height:40px"></a-image>
                        </template>
                        <template #name="{record}">
                            {{record.name}}
                            第{{record.numbers}}期
                        </template>
                        <template #action="{record}">
                            <a-button @click="selectPath(record.id)">选择</a-button>
                        </template>
                    </a-table>
                </a-tab-pane>
                <a-tab-pane key="5" tab="资讯">
                    <a-table size="small" :scroll="{y: 400}" :pagination="false" :data-source="goods.list" row-key="id" :columns="[
                        {title:'ID',dataIndex:'id'},
                        {title:'封面',dataIndex:'cover',slots:{customRender:'cover'}},
                        {title:'标题',dataIndex:'title',width:'500px'},
                        {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
                    ]" >
                        <template #cover="{record}">
                            <a-image :src="record.cover" style="width:40px;height:40px"></a-image>
                        </template>
                        <template #action="{record}">
                            <a-button @click="selectPath(record.id)">选择</a-button>
                        </template>
                    </a-table>
                </a-tab-pane>
                <a-tab-pane key="6" tab="商户">
                    <a-table size="small" :scroll="{y: 400}" :pagination="false" :data-source="goods.list" row-key="shop_id" :columns="[
                        {title:'ID',dataIndex:'shop_id'},
                        {title:'LOGO',dataIndex:'logo',slots:{customRender:'logo'}},
                        {title:'店铺名称',dataIndex:'name',width:'500px'},
                        {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
                    ]" >
                        <template #logo="{record}">
                            <a-image :src="record.logo" style="width:40px;height:40px"></a-image>
                        </template>
                        <template #action="{record}">
                            <a-button @click="selectPath(record.shop_id)">选择</a-button>
                        </template>
                    </a-table>
                </a-tab-pane>
                <a-tab-pane key="7" tab="自定义页面">
                    <a-table size="small" :scroll="{y: 400}" :pagination="false" :data-source="goods.list" row-key="id" :columns="[
                        {title:'ID',dataIndex:'id'},
                        {title:'名称',dataIndex:'page_name',width:'500px'},
                        {title:'是否默认',dataIndex:'is_default',slots:{customRender:'is_default'}},
                        {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
                    ]" >
                        <template #is_default="{record}">
                            <a-tag :color="record.is_default == 1 ?'#00CC66':'#999'">{{ record.is_default == 1 ?'默认':'否'}}</a-tag>   
                        </template>
                        <template #action="{record}">
                            <a-button @click="selectPath(record.id)">选择</a-button>
                        </template>
                    </a-table>
                </a-tab-pane>
                <a-tab-pane key="8" tab="商品类目">
                   <a-tree
                        :tree-data="goods.list"
                        :replaceFields="{ children: 'child', title: 'name', key: 'id' }"
                        @select="e=>selectPath(e[0])"
                    >
                    </a-tree>
                </a-tab-pane>
            </a-tabs>
            <div class="pager" v-if="active !=1">
                <a-pagination
                    show-size-changer
                    :default-current="goods.page"
                    :total="goods.count"
                    @showSizeChange="(p,e)=>{getDataList(goods.page,e)}"
                    @change="(e)=>{getDataList(e,goods.limit)}"
                />
            </div>
        </a-modal>
    </div>
</template>
<script>
import { getCurrentInstance, reactive, toRefs,watch } from 'vue'
import kdMap from '@/components/public/kd-map.vue'
import commonModel from '@/api/common'
import tool from '@/util/tool'
import goodsModel from '@/api/goods'
export default {
    name:'com-select-link',
    components:{
        kdMap
    },
    props:{
        modelValue:{
            type:String,
            default:""
        },
        notLink:{   //是否返回不是链接的跳转数据 例如跳转小程序 拨打电话 等
            type:Boolean,
            default:true
        }
    },
    setup(props,context) {
        //获取到实例
        const { proxy } = getCurrentInstance()

        const _data = reactive({
            active:"1",
            path:props.modelValue,
            val:props.modelValue,   //选择未确定的值     
            show:false,
            shop_btn: tool.getAppPath(props.notLink),
            jump:1,     // 1普通跳转 2小程序 3电话号码 4经纬度
        })

        watch(() => props.modelValue,(value) => {
                _data.val  = value
                _data.path = value
            }
        )

        const selectPath = (e)=>{
            let path = ""
            if(_data.active == 1 ) path =_data.shop_btn[e].path
            if(_data.active == 2 ) path = 'pages/shop/goods_detail?goods_id='+e
            if(_data.active == 3 ) path = 'pages/land/land_detail?land_id='+e
            if(_data.active == 4 ) path = 'sub_package/adoptV2/pages/detail?adopt_id='+e
            if(_data.active == 5 ) path = 'pages/article/detail?article_id='+e
            if(_data.active == 6 ) path = 'sub_package/marketing/pages/store/detail?shop_id='+e
            if(_data.active == 7 ) path = 'pages/index/custom_page?id='+e
            if(_data.active == 8 ) path = 'pages/shop/list?category_id='+e
            _data.val = path
            _data.jump=1
            if(path == 'goto_miniapp') _data.jump = 2
            if(path == 'goto_call') _data.jump = 3
            if(path == 'goto_map') _data.jump =4
            if(path == 'goto_miniapp') _data.jump = 2
            _data.val = path
        }
        
        //确认选择
        const sureSelect = ()=>{
            _data.path = _data.val
            context.emit('update:modelValue',_data.val)
            context.emit('changeJump',_data.jump)
            _data.show = false
        }

        // 商品选择
        const goods = reactive({
            key:"",
            list:[],
            page:1,
            limit:10,
            count:0
        })

        //获取可链接的信息
        const getDataList = (page,limit)=>{
            let data = {
                page,limit
            }
            if( goods.key ) data.key = goods.key

            let url = ''
            if( _data.active == 2 ) url='getGoodsList'
            if( _data.active == 3 ) url='land2_getLandList'
            if( _data.active == 4 ){
                url='getAdoptionList'
                data.is_chil = 1	//查询周期信息
            }
            if( _data.active == 5 ) url='getArticleList'
            if( _data.active == 6 ) url='getShopList'
            if( _data.active == 7 ) url='getPageList'
            if( _data.active == 8 ){ //获取商品类目信息
                goodsModel.getCategroyList(1,999,null,res=>goods.list = res)
                return
            }
            commonModel.getDataList(url,data).then(res=>{
                goods.list  = res.list
                goods.page  = res.page
                goods.count  = res.count
            })
        }

        //切换tab 获取新的数据信息
        const changeTab = ()=>{
            if(_data.active !=1 ){
                getDataList(1,goods.limit)
            }
        }

        //获取地址信息
        function selectAddress(e){
            _data.jump = 4
            _data.val = e.longitude+','+e.latitude
            sureSelect()
        }

        return{
            ...toRefs(_data),
            goods,
            selectPath,
            sureSelect,
            changeTab,
            getDataList,
            selectAddress
        }
    },
}
</script>
<style lang="scss" scoped>
    .select-icon{
        cursor: pointer;
    }
</style>