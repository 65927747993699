<template>
    <!-- 溯源列表 -->
    <div class="trace">
        <div class="el-content mb12">
            <div class="kd-pacel">检索参数</div>
            <a-space>
                <a-input-search
                    v-model:value="value"
                    placeholder="搜索溯源编号、名称"
                    enter-button
                    style="width:300px;"
                    @search="onSearch"
                />
                <a-button type="primary"><i class="ri-add-line ri-btn"></i>创建溯源</a-button>
                <a-button><i class="ri-delete-bin-line ri-btn"></i>批量删除</a-button>
            </a-space>
        </div>

        <div class="el-content">
            <div class="kd-pacel">列表</div>
            <a-table :pagination="false" :data-source="info.list" :columns="[
                {title:'ID',dataIndex:'id'},
                {title:'编号',dataIndex:'number'},
                {title:'标题',dataIndex:'title'},
                {title:'溯源产品',dataIndex:'goods'},
                {title:'溯源周期',dataIndex:'cycle'},
                {title:'浏览量',dataIndex:'view_count'},
                {title:'操作人',dataIndex:'user'},
                {title:'操作时间',dataIndex:'create_time'},
                {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
            ]" :row-selection="{ selectedRowKeys: id, onChange: onSelectChange }">
               <template #action="{record}">
                    <a-button type="link" size="small">编辑{{record.id}}</a-button>
                    <a-button type="link" size="small">溯源码</a-button>
                    <a-button type="link" size="small">删除</a-button>
               </template>
            </a-table>
            <div class="pager">
                <a-pagination
                    show-size-changer
                    :default-current="info.page"
                    :total="info.count"
                    @showSizeChange="(p,e)=>{getDisRule(info.page,e)}"
                    @change="(e)=>{getDisRule(e,info.limit)}"
                />
            </div>
        </div>

        <a-modal title="添加溯源" v-model:visible="show.add"  @ok="handleOk" width="700px">
            <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
				<a-form-item label="溯源产品" required>
					<a-input v-model:value="form.goods"></a-input>
				</a-form-item>
                <a-form-item label="选择地块" required>
                    <a-select v-model:value="form.land_name"></a-select>
				</a-form-item>
                <a-form-item label="选择作物" required>
                    <a-select v-model:value="form.land_name"></a-select>
				</a-form-item>
                <a-form-item label="起止时间" required>
                    <a-select v-model:value="form.time"></a-select>
				</a-form-item>
                <a-form-item label="收货数量" required>
                    <a-input v-model:value="form.count"></a-input>
				</a-form-item>
                <a-form-item label="计量单位" required>
                    <a-input v-model:value="form.unit"></a-input>
				</a-form-item>
                <a-form-item label="负责人" required>
                    <a-input v-model:value="form.user"></a-input>
				</a-form-item>
                <a-form-item label="检测证书" required>
                    <a-input v-model:value="form.img"></a-input>
				</a-form-item>
                <a-form-item label="溯源码数量" required>
                    <a-input v-model:value="form.count"></a-input>
				</a-form-item>
			</a-form>
        </a-modal>
    </div>
</template>
<script>
import { reactive, toRefs } from 'vue'
export default {
    setup() {
        const state = reactive({
            info:{
                list:[
                    {id:1,number:'W1235254',title:'奉节脐橙',goods:'纽荷兰',cycle:'2020-01-01~2020-10-10',view_count:15,user:'张三三',create_time:"2020-12-25"},
                    {id:1,number:'W1235254',title:'奉节脐橙',goods:'纽荷兰',cycle:'2020-01-01~2020-10-10',view_count:15,user:'张三三',create_time:"2020-12-25"},
                    {id:1,number:'W1235254',title:'奉节脐橙',goods:'纽荷兰',cycle:'2020-01-01~2020-10-10',view_count:15,user:'张三三',create_time:"2020-12-25"},
                    {id:1,number:'W1235254',title:'奉节脐橙',goods:'纽荷兰',cycle:'2020-01-01~2020-10-10',view_count:15,user:'张三三',create_time:"2020-12-25"},
                    {id:1,number:'W1235254',title:'奉节脐橙',goods:'纽荷兰',cycle:'2020-01-01~2020-10-10',view_count:15,user:'张三三',create_time:"2020-12-25"},
                    {id:1,number:'W1235254',title:'奉节脐橙',goods:'纽荷兰',cycle:'2020-01-01~2020-10-10',view_count:15,user:'张三三',create_time:"2020-12-25"},
                    {id:1,number:'W1235254',title:'奉节脐橙',goods:'纽荷兰',cycle:'2020-01-01~2020-10-10',view_count:15,user:'张三三',create_time:"2020-12-25"},
                ],
                page:1,
                limit:10,
                count:0
            },
            show:{
                add:true
            },
            form:{
                goods:"",
                land_name:"",
                time:"",
                count:"",
                unit:"",
                user:"",
                img:"",
            }
        })

        return{
            ...toRefs(state)
        }
    },
}
</script>