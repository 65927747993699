<template>
	<!-- 编辑认养项目信息 -->
	<div class="v2-adopt-edit el-content">
		<a-form :label-col="{span:3}" :wrapper-col="{span:12}">
			<a-tabs v-model:activeKey="active">
			    <a-tab-pane key="1" tab="基本信息">
					<a-card title="基本信息" class="mb12">
						<a-form-item label="项目分类">
							<a-select v-model:value="form.category_id">
								<a-select-option 
									v-for="(item,index) in typeData" 
									:value="item.id" 
									:key="index">{{item.name}}
								</a-select-option>
							</a-select>
						</a-form-item>
						<a-form-item label="项目名称">
							<a-input v-model:value="form.name" placeholder="请输入项目名称"></a-input>
						</a-form-item>
						<a-form-item label="认养品种">
							<a-input v-model:value="form.varieties" placeholder="请输入认养品种"></a-input>
						</a-form-item>
						<a-form-item label="认养周期">
							<a-input v-model:value="form.purchase_month" placeholder="请输入认养周期" addon-after="天"></a-input>
						</a-form-item>
						<a-form-item label="认养产量">
							<a-input v-model:value="form.yield" placeholder="请输入认养产量" addon-after="kg"></a-input>
						</a-form-item>
						<a-form-item label="单位">
							<a-input v-model:value="form.unit" placeholder="单位:例如:只"></a-input>
						</a-form-item>
						<a-form-item label="起止时间">
							<a-range-picker v-model:value="formTime" />
						</a-form-item>
						<a-form-item label="图片展示">
							<kd-img-select :mutil="true" :src="form.slide" @success="(url)=>{ form.slide = url}"></kd-img-select>
						</a-form-item>
						<a-form-item label="视频展示">
							<com-select-video v-model="form.video_src"></com-select-video>
							<div class="kd-tips">请上传50M以内的项目展示视频</div>
						</a-form-item>
						<a-form-item label="项目简介">
							<a-textarea v-model:value="form.simple_description" placeholder="请输入项目简介" :rows="4" />
						</a-form-item>
					</a-card>
					<a-card title="价格设置" class="mb12">
						<a-form-item label="认养单价" name="price">
							<a-input v-model:value="form.price" addon-after="元"></a-input>
						</a-form-item>
						<a-form-item label="价格说明">
							<a-textarea v-model:value="form.price_description" placeholder="价格说明" :rows="4" />
						</a-form-item>
						<a-form-item label="项目数量">
							<a-input v-model:value="form.count" :addon-after="form.unit"></a-input>
						</a-form-item>
					</a-card>
					<a-card title="收获设置">
						<a-form-item label="认养收获(48*48px)" v-for="(val,ind) in form.adopt_gain" :key="ind">
							<div class="v2ae-cycle">
								<kd-img-select :src="val.img" @success="(url)=>{ val.img = url}"></kd-img-select>
								<a-input v-model:value="val.summary" placeholder="收获描述" style="margin-left:12px;"></a-input>
								<i class="ri-delete-back-2-fill ri-2x ri-top" style="top:8px;left:6px;" @click="addOrDelGain(ind)"></i>
							</div>
						</a-form-item>
						<a-form-item :wrapper-col="{offset:3}">
							<div class="v2ae-cycle-add" @click="addOrDelGain(-1)">
								<i class="ri-add-line ri-lg ri-top"></i>
							</div>
						</a-form-item>
					</a-card>
				</a-tab-pane>
			    <a-tab-pane key="2" tab="配送方式">
					<a-form-item label="可自提门店" v-if="isShop == 0 && shop_id == 0">
						<a-select v-model:value="form.outlet_id" style="width: 300px;">
							<a-select-option value="">请选择自提门店</a-select-option>
							<a-select-option v-for="(item,index) in outletData" :key="index" :value="item.id">
								{{item.name}}
							</a-select-option>
						</a-select>
						<div class="f12">如果不选择可自提门店，则表示该认养项目不支持自提，仅支持快递配送！</div>
					</a-form-item>
					<a-form-item label="配送方式">
						<a-radio-group v-model:value="form.delivery_type">
						    <a-radio :value="1">一次性配送</a-radio>
						    <a-radio :value="2">周期配送</a-radio>
						</a-radio-group>
					</a-form-item>
					<a-form-item label="周期信息" v-if="form.delivery_type==2">
						<div class="v2ae-cycle" v-for="(item,index) in adoptDelivery" :key="index">
							<a-space>
								<a-input v-model:value="item.day" style="width: 200px;" addon-after="天/次"></a-input>
								<a-input v-model:value="item.min_times" placeholder="最少配送次数" style="width: 200px;"  addon-after="次"></a-input>
								<a-input v-model:value="item.alias" placeholder="说明,例如:一周一次、一个月一次" style="width: 300px;"></a-input>
								<a-input v-model:value="item.rank" placeholder="排序" style="width: 200px;"></a-input>
								<i class="ri-delete-back-2-fill ri-2x" @click="addOrDelCycle(index)"></i>
							</a-space>
						</div>
						<div class="v2ae-cycle-add" @click="addOrDelCycle(-1)"><i class="ri-add-line ri-lg ri-top"></i></div>
						<div class="f12">提示：配送周期数、配送次数、说明必填，且配送周期数和配送次数不能小于等于0 </div>
					</a-form-item>
				</a-tab-pane>
			    <a-tab-pane key="3" tab="营销推广">
					<a-card title="基本营销">
						<com-account-level-form
							:formData="{
								score:form.score,
								levels:form.levels,
								is_user_discount:form.is_discount,
								specify_discount_price:form.specify_discount_price,
							}" 
							@success="e=>{
								form.score = e.score;
								form.levels = e.levels;
								form.is_discount = e.is_user_discount;
								form.specify_discount_price = e.specify_discount_price;
							}">
						</com-account-level-form>
						<a-form-item label="认养限购" >
							<a-input v-model:value="form.limit_buy" :addon-after="form.unit"></a-input>
							<div class="f12">不填写或者填写为0表示不限购</div>
						</a-form-item>
						<a-form-item label="虚拟销量">
							<a-input v-model:value="form.virtual_sales" :addon-after="form.unit"></a-input>
						</a-form-item>
						<a-form-item label="推荐设置" v-if="isShop == 0">
							<a-radio-group v-model:value="form.is_recommend">
								<a-radio :value="1">推荐</a-radio>
							    <a-radio :value="0">不推荐</a-radio>
							</a-radio-group>
						</a-form-item>
					</a-card>
					<a-card title="认养分销" v-if="isShop == 0 && shop_id == 0">
						<com-distribution-set @changeDis="getDisData"
							:dis-info="{
								one_dis_price:form.one_dis_price,
								two_dis_price:form.two_dis_price,
								one_team_price:form.one_team_price,
								two_team_price:form.two_team_price,
								dis_price_type:form.dis_price_type,
								is_distribution:form.is_distribution,
							}" >
						</com-distribution-set>
					</a-card>
				</a-tab-pane>
				<a-tab-pane key="6" tab="设备绑定">
					<a-form-item label="监控设备">
						<a-select v-model:value="form.live_ids" mode="multiple">
							<a-select-option 
								v-for="(item,index) in liveData" 
								:value="item.id" 
								:key="index">{{item.title}}
							</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item label="物联网设备" v-if="isShop == 0 && shop_id == 0 && show.is_open_web_of_thing == 1">
						<com-device-select 
							:value="form.did" 
							@change="(e)=>{form.did = e }">
						</com-device-select>
					</a-form-item>
				</a-tab-pane>
				<a-tab-pane key="7" tab="绑定管理员">
					<a-form-item label="管理员">
						<a-select v-model:value="form.inspector_id">
							<a-select-option :value="0">请选择管理员</a-select-option>
							<a-select-option v-for="(item,index) in pollingList" :key="index" :value="item.id">
								{{item.name}}
							</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item label="管理项">
						<a-checkbox-group v-model:value="form.work_type_ids">
							<a-checkbox v-for="(v,i) in workService" :key="i" :value="v.id">{{ v.title }}</a-checkbox>
						</a-checkbox-group>
					</a-form-item>
					<a-form-item label="工单周期">
						<a-input v-model:value="form.inspector_cycle" addon-after="天" type="number"></a-input>
					</a-form-item>
				</a-tab-pane>
				<a-tab-pane key="8" tab="认养合同">
					<a-form-item label="是否启用合同">
						<kd-switch :value="form.contract_status" 
							:activeValue="1" 
							:inactiveValue="2" 
							@change="e=>form.contract_status = e">
						</kd-switch>
					</a-form-item>
					<a-form-item label="选择合同" v-if="form.contract_status == 1">
						<a-select v-model:value="form.contract_id">
							<a-select-option 
								v-for="(item,index) in contractData" 
								:value="item.id" 
								:key="index">{{item.name}}
							</a-select-option>
						</a-select>
					</a-form-item>
				</a-tab-pane>
				<a-tab-pane key="4" tab="认养协议">
					<a-form-item label="认养协议">
						<tinymce-editor v-model="form.agreement" uniid="agreement"></tinymce-editor>
					</a-form-item>
				</a-tab-pane>
				<a-tab-pane key="5" tab="认养详情">
					<a-form-item label="认养详情">
						<tinymce-editor v-model="form.description" uniid="adopt_description"></tinymce-editor>
					</a-form-item>
				</a-tab-pane>
			</a-tabs>
			<a-form-item  :wrapper-col="{offset:3}">
				<a-space>
					<a-button type="primary" @click="saveAdoptInfo" >保存</a-button>
					<a-button @click="$router.go(-1)">返回</a-button>
				</a-space>
			</a-form-item>
		</a-form>
	</div>
</template>

<script>
import comDistributionSet from '@/components/miniapp/com-distribution-set.vue'
import comDeviceSelect from '@/components/miniapp/com-device-select.vue'
import comSelectVideo from '@/components/public/com-select-video.vue'
import ComAccountLevelForm from '@/components/form/com-account-level-form'
import moment from 'moment'
import { reactive, toRefs } from 'vue'
import tool from '@/util/tool.js'
import adoptModel from '@/api/adopt.js'
import outletModel from '@/api/addons/outlet.js'
import liveModel from '@/api/live.js'
import landModel from '@/api/land.js'
import router from '@/router'
import { useRoute } from 'vue-router'
import setModel from '@/api/set'
export default{
	name:"v2-adopt-edit",
	components:{
		comDistributionSet,
		comDeviceSelect,
		comSelectVideo,
		ComAccountLevelForm
	},
	props:{
		isShop:{
			type:[String,Number],
			default:0
		}
	},
	setup(){
		const _d = reactive({
			active:'1',
			id:0,
			shop_id:0,
			show:{
				loading:false,	
				video:false,
				is_open_web_of_thing:false,
			},	
			typeData:[],				//分类信息
			liveData:[],				//监控信息
			pollingList:[],				//管理员
			workService:[],				//管理项
			form:{
				id:0,
				did:"",
				name:"",
				unit:"",			//单位
				price:"",
				count:"",
				score:"",
				yield:"",			//产量
				cover:"",
				slide:[],
				live_ids:[],
				outlet_id:"",
				buy_count:"",
				limit_buy:"",		//认养限购
				virtual_sales:"",	//虚拟销量
				varieties:"",		//品种
				video_src:"",
				all_numbers:0,
				numbers:0,
				is_discount:0,
				levels:[],
				specify_discount_price:"",
				is_recommend:0,
				category_id:"",
				inspector_id:"",
				work_type_ids:[],
				inspector_cycle:"",	//工单周期
				purchase_month:"",	//认养周期、生长周期
				simple_description:"",
				delivery_type:1,	//配送方式 1一次性 2周期配送
				agreement:"",		//认养协议
				description:"",		//认养详情
				price_description:"",//价格说明
				//分销信息
				is_distribution:"",
				total_dis_price:"",
				one_bonus:"",
				two_bonus:"",
				one_reward_unit:"",
				one_unit_num:"",
				two_reward_unit:"",
				
				one_dis_price:"",
				two_dis_price:"",
				one_team_price:"",
				two_team_price:"",
				dis_price_type:1,
				is_put_away:0,
				
				adopt_gain:[],	//认养收获

				contract_id:"",
				contract_status:0,
			},
			formTime:[],
			adoptDelivery:[],	//配送周期
			outletData:[],		//门店信息
			contractData:[],	//合同数据
		})
		
		const opt = useRoute().query
		if(opt.shop_id ) _d.shop_id = opt.shop_id
		adoptModel.getAdoptCategroy(1,999,null,res=>_d.typeData = res.list)
		outletModel.getOutlet(res=>_d.outletData = res)
		liveModel.getLiveList(1,999,null,res=>_d.liveData = res.list)
		landModel.getInspector(1,999,{type:2,shop_id:_d.shop_id},res=>_d.pollingList = res.list)
		adoptModel.getAdoptWorkServer(1,99,{shop_id:_d.shop_id},res=>_d.workService = res.list)
		adoptModel.getAdoptContract(1,999,null,res=>_d.contractData = res.list)
		setModel.getFarmSet(['is_open_web_of_thing']).then(res=>{
			if(res && res.is_open_web_of_thing == 1){
				_d.show.is_open_web_of_thing = true
			}
		})

		if( opt.id ){
			adoptModel.getAdoptDetail(opt.id,{shop_id:_d.shop_id},res=>{
				_d.form = tool.filterForm(Object.keys(_d.form),res)
				if( !_d.form.live_ids ) _d.form.live_ids = []
				if( res.cycle.length >0 ) _d.adoptDelivery = res.cycle
				if( !_d.form.slide ) _d.form.slide = []
				if( !_d.form.work_type_ids >0 ) _d.form.work_type_ids = []
				if( !_d.form.adopt_gain >0 ) _d.form.adopt_gain = []

				if( res.start_time && res.end_time){
					_d.formTime = [ moment(res.start_time,"YYYY-MM-DD"), moment(res.end_time,"YYYY-MM-DD")]
				}
			})
		}

		function addOrDelCycle(index){
			if( index  >=0 ){
				_d.adoptDelivery.splice(index,1)
				return
			}
			_d.adoptDelivery.push({ day:"", rank:99, alias:"", min_times:"", })
		}

		function addOrDelGain(index){
			if( index  >=0 ){
				_d.form.adopt_gain.splice(index,1)
				return
			}
			_d.form.adopt_gain.push({ img:"", summary:'', })
		}
		function getDisData(e){
			_d.form.is_distribution = e.is_distribution
			_d.form.one_dis_price = e.one_dis_price
			_d.form.two_dis_price = e.two_dis_price
			_d.form.one_team_price = e.one_team_price 
			_d.form.two_team_price = e.two_team_price 
			_d.form.dis_price_type = e.dis_price_type
		}
		const checkDataIsNull = (data)=> {return ( data == "" || data<=0 ) ? true :false}
		const saveAdoptInfo = ()=>{
			let form = JSON.parse(JSON.stringify(_d.form))
			form.start_time = moment(_d.formTime[0]).format("YYYY-MM-DD")
			form.end_time = moment(_d.formTime[1]).format("YYYY-MM-DD")
			adoptModel.addOrEditAdopt(form,_d.adoptDelivery,res=>{
				router.go(-1)
			})
		}
		return{
			...toRefs(_d),
			addOrDelCycle,
			addOrDelGain,
			getDisData,
			checkDataIsNull,
			saveAdoptInfo
		}
	}
}
</script>

<style lang="scss">
.v2-adopt-edit{
	.kd-tips{
		font-size: 12px;
		color: #999;
	}
	.v2ae-cycle{
		display: flex;
		align-items: baseline;
		margin-bottom: 12px;
		.ri-delete-back-2-fill{
			cursor: pointer;
		}
	}
	.v2ae-cycle-add{
		width: 860px;
		height: 32px;
		line-height: 32px;
		text-align: center;
		border: 1px dashed #999;
		color: #999;
		cursor: pointer;
	}
}
</style>
