<template>
    <div class="worker-add">
        <a-button type="primary" @click="showAdd" v-if="!custom">
            <i class="ri-add-line"></i>添加{{workType == 1 ?'巡检员':'工人'}}
        </a-button>
        <div v-else @click="showAdd">
            <slot/>
        </div>
        <a-modal title="添加工人" :visible="showEdit" @ok="saveWorker" @cancel="showEdit = false" width="600px">
		    <a-form :label-col="{span:4}" :wrapper-col="{span:16}">
		    	<a-form-item label="工人姓名" name="name">
		    		<a-input placeholder="请输入工人姓名" v-model:value="workerForm.name"></a-input>
		    	</a-form-item>
		    	<a-form-item label="工人电话" name="mobile">
		    		<a-input placeholder="请输入工人电话" v-model:value="workerForm.mobile"></a-input>
		    	</a-form-item>
		    	<!-- 平台可看到全部用户 -->
		    	<a-form-item label="客户端用户" v-if="isShop == 0 ">
					<com-select-user :value="workerForm.account_id" @change="e=>workerForm.account_id = e "></com-select-user>
		    	</a-form-item>
		    	
		    	<!-- 商户只能搜索用户 -->
		    	<a-form-item label="客户端用户" v-if="isShop == 1 ">
		    		<com-select-user :select-style="2" :value="workerForm.account_id" @change="e=>workerForm.account_id = e "></com-select-user>
		    	</a-form-item>
		    	<a-form-item label="微信公众号openid">
		    		<a-input placeholder="微信公众号openid" v-model:value="workerForm.openid"></a-input>
		    	</a-form-item>
		    	<a-form-item label="状态">
		    		<a-radio-group v-model:value="workerForm.status">
		    		    <a-radio :value="1">正常</a-radio>
		    		    <a-radio :value="0">禁用</a-radio>
		    		</a-radio-group>
		    	</a-form-item>
		    </a-form>  
		</a-modal>
    </div>
</template>
<script>
import { reactive, toRefs } from 'vue'
import landModel from '@/api/land.js'
import comSelectUser from '@/components/miniapp/com-select-user.vue'
export default {
    name:'com-worker-add',
    components:{
        comSelectUser
    },
    props:{
        workType:{    //1巡检员 2工人
            type:Number,
            default:1  
        },
        workData:{
            type:Object,
            default:null
        },
        isShop:{
            type:Number,
            default:0
        },
        custom:{
            type:Boolean,
            default:false,
        }
    },
    setup(props,context) {
        const _d = reactive({
            showEdit:false,
            workerForm:null,
        })

        function showAdd(){
            let row = props.workData
            _d.workerForm = {
				id:row ? row.id:0,
				name:row ? row.name:"",
				type:row ? row.type:props.workType,
				mobile:row ? row.mobile:"",
				openid:row ? row.openid:"",
				account_id:row ? row.account_id:"",
				status:row ? row.status:0,
			}
			_d.showEdit = true
        }

         const saveWorker = ()=> landModel.addOrEditInspector(_d.workerForm,()=>{
            _d.showEdit = false
            context.emit("success")
        })


        return{
            ...toRefs(_d),
            showAdd,
            saveWorker
        }
    },
}
</script>