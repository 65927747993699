<template>
	<div class="cmp-land-edit el-content">
		<a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
		    <a-tabs  v-model:activeKey="active" @change="changeActive">
		        <a-tab-pane key="basic" tab="土地基本信息">
					<a-card title="土地基本信息">
					    <a-row :gutter="20">
					    	<a-col :span="12">
					    		<a-form-item label="默认排序">
					    		    <a-input  v-model:value="form.rank" placeholder="排序"/>
					    		</a-form-item>
					    		<a-form-item label="土地分类">
					    		    <a-select placeholder="请选择土地分类" v-model:value="form.category_id">
					    				<a-select-option value="">请选择土地分类</a-select-option>
					    				<a-select-option v-for="(item,index) in typeData" :key="index" :value="item.id">
					    					{{ item.name}}
					    				</a-select-option>
					    		    </a-select>
					    		</a-form-item>
					    		<a-form-item label="土地名称">
					    		    <a-input  v-model:value="form.land_name" placeholder="请输入土地名称"/>
					    		</a-form-item>
					    		<a-form-item label="土地简介">
					    		    <a-input  v-model:value="form.land_intro" placeholder="请输入土地简介"/>
					    		</a-form-item>
					    		<a-form-item label="土地封面">
					    			<kd-img-select :src="form.cover" @success="e=>{ form.cover = e }"></kd-img-select>
					    		</a-form-item>
					    		<a-form-item label="巡检员">
					    		    <a-select v-model:value="form.inspector_id">
					    				<a-select-option value="">请选择巡检员</a-select-option>
					    				<a-select-option v-for="(item,index) in pollingList" :key="index" :value="item.id">
					    					{{ item.name}}
					    				</a-select-option>
					    		    </a-select>
					    		</a-form-item>
					    		<a-form-item label="巡检任务">
					    			<a-checkbox-group v-model:value="form.work_order_server_ids">
					    				<a-checkbox v-for="(v,i) in workService"
                                                    :key="i"
                                                    :label="v.id"
                                                    :value="v.id">
                                            {{ v.title }}
                                        </a-checkbox>
					    			</a-checkbox-group>
					    		</a-form-item>
					    	</a-col>
					    	<a-col :span="12">
					    		<a-form-item>
					    			<template #label>
					    				<span>展示价格</span>
					    				<a-tooltip content="该价格仅做展示作用,实际价格请在设置面积价格的地方设置">
					    					<i class="el-icon-info"></i>
					    				</a-tooltip>
					    			</template>
					    			<a-input v-model:value="form.price" placeholder="请输入展示价格" addon-after="元"></a-input>
					    		</a-form-item>
					    		<a-form-item>
					    			<template #label>
					    				<span>配送运费</span>
					    				<a-tooltip content="该运费是在用户下配送订单时使用">
                                            <i class="el-icon-info"></i>
                                        </a-tooltip>
					    			</template>
					    			<a-input v-model:value="form.send_price"
                                             placeholder="请输入运费"
                                             addon-after="元/kg">
                                    </a-input>
					    		</a-form-item>
					    		<a-form-item label="地块经纬度">
									<kd-map :value="form.longitude+','+form.latitude" @change="getAddress"></kd-map>
					    		</a-form-item>
                                <a-form-item label="框选地块区域">
<!--                                    <span v-if="form.point">已绘制区域{{ aeState.form.area.area }}平方米</span>-->
                                    <div v-for="( item,index ) in form.point" :key="index">
                                        经度:{{ item[0] }},纬度：{{item[1]}}
                                    </div>
                                    <a-button type="primary" @click="showSelectArea(0)">选择区域</a-button>
                                </a-form-item>
					    		<a-form-item label="服务设置">
					    			<a-checkbox-group v-model:value="form.ser_ids">
					    				<a-checkbox v-for="(item,index) in serviceData" :key="index" :value="''+item.id">
                                            {{item.name}}
                                        </a-checkbox>
					    			</a-checkbox-group>
					    		</a-form-item>
					    		<a-form-item label="可种种子">
					    			<a-checkbox-group v-model:value="form.seed">
					    				<a-checkbox v-for="item in seedData" :key="item.id" :value="item.id">
                                            {{item.send_name}}
                                        </a-checkbox>
					    			</a-checkbox-group>
					    		</a-form-item>
					    		<a-form-item label="上架状态">
					    			<a-radio-group v-model:value="form.is_put_away">
					    				<a-radio :value="1">上架</a-radio>
					    				<a-radio :value="0">下架</a-radio>
					    			</a-radio-group>
					    		</a-form-item>
					    	</a-col>
					    </a-row>
					</a-card>
					<a-card title="营销设置">
						<com-account-level-form
							:formData="{
								score:form.score,
								levels:form.levels,
								is_user_discount:form.is_user_discount,
								specify_discount_price:form.specify_discount_price,
							}"
							@success="e=>{
								form.score = e.score;
								form.levels = e.levels;
								form.is_user_discount = e.is_user_discount;
								form.specify_discount_price = e.specify_discount_price;
							}">
						</com-account-level-form>


						<com-distribution-set @changeDis="getDisData"
							:dis-info="{
								one_dis_price:form.one_dis_price,
								two_dis_price:form.two_dis_price,
								one_team_price:form.one_team_price,
								two_team_price:form.two_team_price,
								dis_price_type:form.dis_price_type,
								is_distribution:form.is_distribution,
							}" >
						</com-distribution-set>
					</a-card>
		        </a-tab-pane>
				<a-tab-pane key="cycle" tab="租赁周期设置">
					<div class="cmp-le-cycle" v-for="(item,index) in form.lease_cycle" :key="index">
						<span class="cmp-le-cycle-title">租赁周期：</span>
						<a-input placeholder="名称" style="width: 240px;margin-right: 12px;" v-model:value="item.name"/>
						<a-input placeholder="周期天数" style="width: 200px;" v-model:value="item.day" type="number" min="0" addon-after="天"/>
						<i class="ri-delete-bin-5-line ri-lg" @click="doLeaseCycle(index)"></i>
					</div>
					<div class="cmp-le-cycle-add" @click="doLeaseCycle(-1)"><i class="ri-add-line ri-lg ri-top"></i>添加周期</div>
				</a-tab-pane>
				<a-tab-pane key="area" tab="租赁面积价格">
					<div class="cmp-land-spec">
						<div class="cmp-ls-item" style="border-bottom: 1px solid #f4f4f4;margin-bottom: 12px;">
							<span class="cmp-ls-item-title">批量设置：</span>
							<a-space style="flex-wrap: wrap;">
								<a-input style="width: 140px;"  placeholder="名称/编号" v-model:value="batch.land_num">
									<template #addonAfter>
										<text class="ri-arrow-down-s-line" @click="batchSet('land_num')"></text>
									</template>
								</a-input>
								<a-input style="width: 140px;"  placeholder="别名" v-model:value="batch.alias">
									<template #addonAfter>
										<text class="ri-arrow-down-s-line" @click="batchSet('alias')"></text>
									</template>
								</a-input>
								<a-input style="width: 140px;"  placeholder="面积" v-model:value="batch.area">
									<template #addonAfter>
										<text class="ri-arrow-down-s-line" @click="batchSet('area')"></text>
									</template>
								</a-input>
								<a-input style="width: 140px;"  placeholder="单价" v-model:value="batch.cost">
									<template #addonAfter>
										<text class="ri-arrow-down-s-line" @click="batchSet('cost')"></text>
									</template>
								</a-input>
								<a-select placeholder="请选择监控" v-model:value="batch.live_id" @change="batchSet('live_id')" style="width: 140px;">
									<a-select-option value="">请选择监控</a-select-option>
									<a-select-option v-for="(item,index) in liveData" :key="index" :value="item.id">
										{{ item.title}}
									</a-select-option>
								</a-select>
								<template v-if="deviceData.length>0">
									<div style="width: 140px;">
										<com-device-select
											v-if="isShop==0 &&shop_id == 0"
											:value="batch.did"
											:device-list="deviceData"
											@change="(e)=>{batch.did = e; batchSet('did') }">
										</com-device-select>
									</div>
								</template>
								<a-select
								placeholder="请选择浇水开关控制"
								v-if="isShop==0 && shop_id == 0 && show.is_open_web_of_thing == 1"
								v-model:value="batch.water_switch" @change="batchSet('water_switch')" style="width: 140px;">
									<a-select-option :value="0">浇水开关控制</a-select-option>
									<a-select-option value="1">开关1</a-select-option>
									<a-select-option value="2">开关2</a-select-option>
									<a-select-option value="3">开关3</a-select-option>
									<a-select-option value="4">开关4</a-select-option>
								</a-select>
							</a-space>
						</div>

                        <div class="cmp-ls-li" :class="item.operation_status === 1 ?'cmp-ls-li-rent' :'' "
                             v-for="(item,index) in landSpec"
                             :key="index"
                        >
                            <div class="cmp-ls-li-item">
                                <a-input class="cmp-ls-li-input" v-model:value="item.land_num" addon-before="名称/编号" ></a-input>
                                <a-input class="cmp-ls-li-input" v-model:value="item.alias" addon-before="别名" ></a-input>
                                <a-input class="cmp-ls-li-input" v-model:value="item.area" addon-before="面积" addon-after="㎡"></a-input>
                                <a-input class="cmp-ls-li-input" v-model:value="item.cost" addon-before="单价" addon-after="元"></a-input>
                            </div>
                            <div class="cmp-ls-li-item">
                                <a-select class="cmp-ls-li-input" placeholder="请选择监控" v-model:value="item.live_id">
                                    <a-select-option value="">请选择监控</a-select-option>
                                    <a-select-option  v-for="(val,ind) in liveData" :key="ind" :value="val.id">
                                        {{val.title}}
                                    </a-select-option>
                                </a-select>
                                <div class="cmp-ls-li-input" v-if="deviceData.length>0">
                                    <com-device-select
                                        v-if="isShop==0 &&shop_id == 0"
                                        :value="item.did"
                                        :device-list="deviceData"
                                        @change="(e)=>{item.did = e;}">
                                    </com-device-select>
                                </div>
                                <a-select class="cmp-ls-li-input" v-model:value="item.water_switch"
                                    placeholder="浇水开关控制"
                                    v-if="isShop==0 && shop_id == 0 && show.is_open_web_of_thing == 1">
                                    <a-select-option :value="0">浇水开关控制</a-select-option>
                                    <a-select-option value="1">开关1</a-select-option>
                                    <a-select-option value="2">开关2</a-select-option>
                                    <a-select-option value="3">开关3</a-select-option>
                                    <a-select-option value="4">开关4</a-select-option>
                                </a-select>
                            </div>
                            <div class="cmp-ls-li-operation">
                                <a-space>
                                    <a-tooltip title="点击跳转到租地订单">
                                        <a-tag color="#00CC66" v-if="item.operation_status === 1 && item.land_order">
                                            <router-link :to="{path:'/land/orderDetail',query:{order_id:item.land_order.id}}">
                                                已出租（{{ item.land_order.user_name }}）
                                            </router-link>
                                        </a-tag>
                                    </a-tooltip>
                                    <a-tag color="#FF0066" v-if="item.operation_status === 2">已锁定</a-tag>
                                    <a-tag color="#FF6600" v-if="item.operation_status === 3">处理中</a-tag>

                                    <a-button size="small"
                                              :type="item.point && item.point.length >0 ?'primary':'default'"
                                              @click="showSelectArea(index+1)">
                                        {{ item.point && item.point.length >0 ?'已标记':'标记'}}
                                    </a-button>
                                    <a-tooltip title="删除地块">
                                        <i class="ri-close-circle-fill ri-xl ri-top"  @click="doLandSpec(index)"></i>
                                    </a-tooltip>
                                    <a-tooltip title="添加地块">
                                        <i class="ri-add-circle-fill ri-xl ri-top" @click="doLandSpec(-1)" v-if="index==landSpec.length-1"></i>
                                    </a-tooltip>
                                </a-space>
                            </div>
                        </div>
						<div class="cmp-ls-add" @click="show.batch = true"><i class="el-icon-plus"></i>批量添加面积价格</div>
					</div>
				</a-tab-pane>

				<a-tab-pane key="device" tab="土地关联设备">
					<a-form-item label="监控选择" :label-col="{ span: 2 }" :wrapper-col="{ span: 8 }">
						<a-select placeholder="请选择监控" v-model:value="form.live_id" >
							<a-select-option value="">请选择监控</a-select-option>
							<a-select-option v-for="(item,index) in liveData" :key="index" :value="item.id">
								{{ item.title}}
							</a-select-option>
						</a-select>
						<div style="font-size: 12px;color: #999;">
							还没有监控？
							<router-link :to="{path:shop_id == 0 ?'/live/liveList':'/many_store/live/liveList'}">
								<a-button type="link" @click="toHelpPath(1)">去添加</a-button>
							</router-link>
						</div>
					</a-form-item>
					<a-form-item label="物联网设备" v-if="isShop==0 &&shop_id == 0 && deviceData.length > 0" :label-col="{ span: 2 }" :wrapper-col="{ span: 8 }">
						<com-device-select
							:value="form.did"
							:device-list="deviceData"
							@change="(e)=>{form.did = e }">
						</com-device-select>
					</a-form-item>
				</a-tab-pane>
				<a-tab-pane key="detail" tab="土地详情">
					<a-form-item label="土地详情" :label-col="{ span: 2 }" :wrapper-col="{ span: 8 }">
						<tinymce-editor v-model="form.land_desc"></tinymce-editor>
					</a-form-item>
				</a-tab-pane>
		    </a-tabs>

			<a-form-item :wrapper-col="{ span: 6, offset: 3 }" style="margin-top: 24px;">
				<a-space>
					 <com-submit-btn @success="submitSave"></com-submit-btn>
					<a-button @click="$router.go(-1)">返回</a-button>
				</a-space>
			</a-form-item>
		</a-form>
		<a-modal title="面积价格批量添加" width="700px" :visible="show.batch" @ok="batchAddArea" @cancel="show.batch = false">
		    <a-form ref="bacth" :model="batch" :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
		    	<a-form-item label="名称/编号">
		    		<a-input size="mini" placeholder="名称/编号" v-model:value="batch.land_num"></a-input>
		    	</a-form-item>
		    	<a-form-item label="别名">
		    		<a-input size="mini" placeholder="别名" v-model:value="batch.alias"></a-input>
		    	</a-form-item>
		    	<a-form-item label="面积">
		    		<a-input size="mini" placeholder="面积" v-model:value="batch.area" addon-after="㎡">
		    		</a-input>
		    	</a-form-item>
		    	<a-form-item label="单价">
		    		<a-input size="mini" placeholder="单价" v-model:value="batch.cost" addon-after="元/天">
		    		</a-input>
		    	</a-form-item>
		    	<a-form-item label="监控">
		    		<a-select size="mini" v-model:value="batch.live_id" placeholder="请选择监控">
		    			<a-select-option v-for="(val,ind) in liveData" :key="ind" :value="val.id">{{val.title}}</a-select-option>
		    		</a-select>
		    	</a-form-item>
		    	<a-form-item label="设备" v-if="isShop==0 &&shop_id == 0 && show.is_open_web_of_thing == 1">
		    		<com-device-select
		    			:value="batch.did"
		    			:device-list="deviceData"
		    			@change="(e)=>{batch.did = e }">
		    		</com-device-select>
		    	</a-form-item>
		    	<a-form-item label="浇水开关控制" v-if="isShop==0 &&shop_id == 0 && show.is_open_web_of_thing == 1">
		    		<a-select
		    			size="mini"
		    			v-model:value="batch.water_switch"
		    			placeholder="浇水开关控制"
		    			v-if="isShop==0 &&shop_id == 0 && show.is_open_web_of_thing == 1">
		    			<a-select-option :value="0">浇水开关控制</a-select-option>
		    			<a-select-option value="1">开关1</a-select-option>
		    			<a-select-option value="2">开关2</a-select-option>
		    			<a-select-option value="3">开关3</a-select-option>
		    			<a-select-option value="4">开关4</a-select-option>
		    		</a-select>
		    	</a-form-item>
		    	<a-form-item label="地块数量">
		    		<a-input size="mini" placeholder="地块数量" v-model:value="batch.count"></a-input>
		    	</a-form-item>
		    </a-form>
		</a-modal>

        <a-modal v-model:visible="show.map" title="选择区域" :footer="null" width="80%">
            <div v-if="show.map">
                <com-land-earth
                    :latitude="form.latitude"
                    :longitude="form.longitude"
                    :is-satellite="true"
                    :mapValue="mapData"
                    @getMapData="getMapArea">
                </com-land-earth>
            </div>
        </a-modal>

    </div>
</template>

<script>
import comDeviceSelect from '@/components/miniapp/com-device-select.vue'
import comDistributionSet from '@/components/miniapp/com-distribution-set.vue'
import ComAccountLevelForm from '@/components/form/com-account-level-form'
import comLandEarth from './com-land-earth.vue'
import landModel from '@/api/land.js'
import liveModel from '@/api/live.js'
import common from '@/api/common.js'
import setModel from '@/api/set.js'
import tool from '@/util/tool.js'
import { reactive, toRefs } from 'vue'
import { useRoute } from 'vue-router'
export default{
	name:"page-land-edit",
	components:{
		comDeviceSelect,
		comDistributionSet,
		ComAccountLevelForm,
        comLandEarth
	},
	props:{
		isShop:{	//0=自营 1商户
			type:Number,
			default:0
		}
	},
	setup(){
		const _d = reactive({
			shop_id:0,
			active:"basic",
			form:{
				id:0,
				land_name:"",
				category_id:"",
				ser_ids:[],
				land_intro:"",
				price:"",
				cover:"",
				send_price:"",
				is_put_away:1,
				seed:[],
				rank:99,
				land_desc:"",
				latitude:'',
				longitude:'',
				live_id:"",
				did:"",
                point:[],

				//营销
				score:"",
				is_user_discount:0,
				levels:[],
				specify_discount_price:"",
				inspector_id:0,	//巡检员id
				work_order_server_ids:[],	//巡检任务

				//分销信息
				is_distribution:0,
				total_dis_price:0,
				one_bonus:'',
				two_bonus:'',
				one_reward_unit:0,
				one_unit_num:'',
				two_reward_unit:0,

				one_dis_price:"",
				two_dis_price:"",
				one_team_price:"",
				two_team_price:"",
				dis_price_type:1,

				//租赁周期
				lease_cycle:[
					{name:"",day:""}
				],
			},
			landSpec: [{
				id: 0,
				did:0,			//设备号
				area:"",		//面积
				cost:"",		//价格
				alias:"",		//别名
				live_id:"",		//监控
				land_id: '',	//土地id
				land_num:"",	//地块编号
				water_switch:0,//浇水开关配置
                point:[],
			}],
			//批量设置信息
			batch:{
				did:0,			//设备号
				area:"",		//面积
				cost:"",		//价格
				alias:"",		//别名
				live_id:"",		//监控
				land_id: '',	//土地id
				land_num:"",	//地块编号
				count:1,		//批量生成条数
				water_switch:0,	//浇水开关选择
			},
			typeData:[],	//土地分类信息
			serviceData:[],	//土地服务信息
			liveData:[],	//监控信息
			seedData:[],	//种子信息
			deviceData:[],	//物联网设备信息
			pollingList:[],	//巡检员
			workService:[],	//巡检任务

			show:{
				batch:false,
				is_open_web_of_thing:false,
                map:false,
			},

            mapData:[ ]
		})

		const opt = useRoute().query
		if( opt.shop_id ) _d.shop_id = opt.shop_id
		if( opt.id ){
			landModel.getLandDetail({id:opt.id,shop_id:_d.shop_id},res=>{
				_d.form = tool.filterForm(Object.keys(_d.form),res.land)
				_d.landSpec = res.spec
				if( !_d.form.ser_ids ) _d.form.ser_ids = []
				if( !_d.form.lease_cycle ) _d.form.lease_cycle = [{name:'',day:''}]
				if( !_d.form.seed ) _d.form.seed = []
				if( !_d.form.work_order_server_ids ) _d.form.work_order_server_ids = []
			})
		}
		landModel.getLandCategroy(1,999,{shop_id:_d.shop_id},res=>_d.typeData = res.list)
		landModel.getLandServer(1,999,{shop_id:_d.shop_id},res=>_d.serviceData = res.list)
		landModel.getSeed(1,999,{shop_id:_d.shop_id},res=>_d.seedData = res.list)
		landModel.getInspector(1,999,{shop_id:_d.shop_id},res=>_d.pollingList = res.list)
		landModel.getLandWorkServer(1,999,{shop_id:_d.shop_id},res=>_d.workService = res.list)

		function getDisData(e){
			_d.form.is_distribution = e.is_distribution
			_d.form.one_dis_price = e.one_dis_price
			_d.form.two_dis_price = e.two_dis_price
			_d.form.one_team_price = e.one_team_price
			_d.form.two_team_price = e.two_team_price
			_d.form.dis_price_type = e.dis_price_type
		}

		/**
		 * 租赁周期新增/删除操作
		 * @param {Number}  index = -1 添加周期  index >= 0 删除周期
		 */
		function doLeaseCycle(index){
			if( index === -1 ) _d.form.lease_cycle.push({ name:"",day:"" })
			if( index >=0 ) _d.form.lease_cycle.splice(index,1)
		}

		/**
		 * 面积价格新增、删除操作
		 * @param {Number} index = -1 添加周期  index >= 0 删除周期
		 */
		function doLandSpec(index){
			if( index == -1 ){
				_d.landSpec.push({
					id: 0,
					did:"",			//设备号
					area:"",		//面积
					cost:"",		//价格
					alias:"",		//别名
					live_id:"",		//监控
					land_id: '',	//土地id
					land_num:"",	//地块编号
					water_switch:"",
				})
				return
			}
			if( _d.landSpec[index].id ){
				common.deleteDataList(_d.landSpec[index].id,34,'确认要删除该面积信息吗').then(()=>{
					_d.landSpec.splice(index,1)
				})
				return
			}
			_d.landSpec.splice(index,1)
		}

		/**
		 * 面积价格批量设置
		 * @param {Object} type = land_num alias area cost did live_id
		 */
		function batchSet(type){
			this.landSpec.map((item,ind) => {
				switch (type) {
					case "land_num":
						item.land_num = _d.batch.land_num+'-'+(ind+1)
						break;
					case "area":
						item.area = _d.batch.area
						break;
					case "cost":
						item.cost = _d.batch.cost
						break;
					case "did":
						item.did = _d.batch.did
						break;
					case "live_id":
						item.live_id = parseInt(_d.batch.live_id)
						break;
					case "alias":
						item.alias = _d.batch.alias
						break;
					case "water_switch":
						item.water_switch = _d.batch.water_switch
						break;
					default:
						break;
				}
			})
		}

		//批量添加面积价格信息
		function batchAddArea(){
			let batch = _d.batch
			if( batch.count <=0 ){
				tool.message("批量添加条数必须大于0")
				return
			}
			for (var i = 0; i < batch.count; i++) {
				_d.landSpec.push({
					id: 0,
					did:batch.did,			//设备号
					area:batch.area,		//面积
					cost:batch.cost,		//价格
					alias:batch.alias,		//别名
					live_id:batch.live_id,	//监控
					land_id: batch.land_id,	//土地id
					water_switch: batch.water_switch,
					land_num:batch.land_num +'-'+(_d.landSpec.length+1),//地块编号
				})
			}
			_d.show.batch = false
		}

		function getAddress(e){
			_d.form.latitude = e.latitude
			_d.form.longitude = e.longitude
		}

		function changeActive(){
			let {shop_id } = _d
			if( _d.active == "area" || _d.active =="device"){
				if( _d.liveData.length == 0 ){
					liveModel.getLiveList(1,999,null,res=>_d.liveData = res.list || [])
				}
				if( _d.deviceData.length == 0 ){
					setModel.getFarmSet(['is_open_web_of_thing']).then(res=>{
						if(res && res.is_open_web_of_thing == 1){
							_d.show.is_open_web_of_thing = true
							liveModel.open_getThingHostList(shop_id,true,res=>_d.deviceData = res)
						}
					})
				}
			}
		}
		const submitSave = () => landModel.addOrEditLandV2(_d.form,_d.landSpec)

        function getMapArea(e){
		    // _d.form.point = e.point
            if( e && e.length > 0 ){
                e.forEach((item,index)=>{
                    if( index === 0 ){
                        _d.form.point = item.point
                    }else{
                        _d.landSpec[index-1].point = item.point
                    }
                })
            }

            _d.show.map = false
            console.log('绘制区域',e);
        }

        function showSelectArea(index){
            let spec = _d.landSpec ,pointArr = []
            //土地标记
            pointArr.push({ id:index,point: _d.form.point,edit:false })
            if( index === 0 ) pointArr[0].edit = true

            //地块标记
            if( spec.length > 0 ){
                spec.forEach((item,ind)=>pointArr.push({id:ind+1,point:item.point,edit:index === (ind+1),color:'#00CC99'}))
            }
            _d.mapData = pointArr
            _d.show.map = true
        }

		return{
			...toRefs(_d),
			getDisData,
			doLeaseCycle,
			doLandSpec,
			batchSet,
			batchAddArea,
			getAddress,
			changeActive,
			submitSave,
            getMapArea,
            showSelectArea
		}
	},
}
</script>
<style lang="scss">
	.cmp-le-cycle{
		display: flex;
		width: 100%;
		align-items: center;
		margin-bottom: 12px;
		&-title{
			margin-right: 12px;
			color: #666;
			font-size: 14px;
		}

		.ri-delete-bin-5-line{
			cursor: pointer;
			margin-left: 12px;
		}
	}
	.cmp-le-cycle-add{
		width: 444px;
		border: 1px dashed #d5d5d5;
		height: 28px;
		line-height: 28px;
		text-align: center;
		margin-left: 82px;
		margin-top: 20px;
		color: #999;
		font-size: 14px;
		cursor: pointer;
	}

	.cmp-land-spec{
		width: 100%;

		.cmp-ls-item{
			display: flex;
			align-items: center;
			padding-bottom: 12px;

			.el-input{
				width: 140px;
				margin-right: 12px;
			}

			.el-input__inner{
				width: 100% !important;
			}

			&-title{
				margin-right: 12px;
				color: #666;
				font-size: 12px;
			}

			.ri-close-circle-fill,.ri-add-circle-fill{
				cursor: pointer;
				margin-left: 12px;
			}
		}
        .cmp-ls-li{
            width: 100%;
            padding: 12px 8px 0 8px;
            border: 1px solid #f4f4f4;
            margin-bottom: 12px;
            cursor: pointer;
            position: relative;
            &:hover{
                border: 1px solid #00aaff;
            }
            &-item{
                width: 100%;
                display: flex;
                margin-bottom: 12px;
            }

            &-input{
                width: 240px;
                margin-right: 12px;
            }

            &-operation{
                height: 40px;
                position: absolute;
                right: 0;
                bottom: 0;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                .ant-tag{
                    cursor: pointer;
                }
            }

            &-rent{
                background: #f4f4f4;
            }
        }

		.cmp-ls-add{
			width: 890px;
			height: 28px;
			line-height: 28px;
			text-align: center;
			font-size: 14px;
			color: #999;
			border: 1px dashed #d8d8d8;
			cursor: pointer;
			margin-left: 72px;
		}
	}
</style>
