<template>
    <!-- 仓储详情 -->
    <div class="warehouse-detail">
        <div class="el-content mb12">
            <div class="wd-name">氮肥</div>
            <div class="wd-con">
                <div class="wd-con-item"><span class="f12">剩余库存：</span>1200kg</div>
                <div class="wd-con-item"><span class="f12">农资编号：</span>N1234566</div>
                <div class="wd-con-item"><span class="f12">备注：</span>备注说明备注说明备注说明</div>
                <div class="wd-con-item"><span class="f12">累计入库：</span>1200kg</div>
                <div class="wd-con-item"><span class="f12">创建人：</span>张三三</div>
                <div class="wd-con-item"><span class="f12">累计金额：</span>1345.99元</div>
                <div class="wd-con-item"><span class="f12">创建时间：</span>2020-05-20</div>
            </div>
        </div>

        <div class="el-content">
            <a-tabs v-model:activeKey="active">
                <a-tab-pane key="1" tab="入库记录">
                    <a-table :pagination="false" :data-source="input.list" :columns="[
                        {title:'ID',dataIndex:'id'},
                        {title:'入库类型',dataIndex:'type'},
                        {title:'入库数量',dataIndex:'count'},
                        {title:'金额',dataIndex:'price'},
                        {title:'负责人',dataIndex:'user'},
                        {title:'来源',dataIndex:'origin'},
                        {title:'备注',dataIndex:'remark'},
                        {title:'操作人',dataIndex:'user'},
                        {title:'操作时间',dataIndex:'create_time'},
                        {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
                    ]">
                        <template #action="{record}">
                            <a-button type="link" size="small">查看凭证{{record.id}}</a-button>
                        </template>
                    </a-table>
                    <div class="pager">
                        <a-pagination
                            show-size-changer
                            :default-current="input.page"
                            :total="input.count"
                            @showSizeChange="(p,e)=>{getDisRule(input.page,e)}"
                            @change="(e)=>{getDisRule(e,input.limit)}"
                        />
                    </div>
                </a-tab-pane>
                <a-tab-pane key="2" tab="出库记录">
                    <a-table :pagination="false" :data-source="output.list" :columns="[
                        {title:'ID',dataIndex:'id'},
                        {title:'出库类型',dataIndex:'type'},
                        {title:'出库数量',dataIndex:'count'},
                        {title:'出库对象',dataIndex:'land_name'},
                        {title:'负责人',dataIndex:'user'},
                        {title:'备注',dataIndex:'remark'},
                        {title:'操作人',dataIndex:'user'},
                        {title:'操作时间',dataIndex:'create_time'},
                    ]">
                    </a-table>
                    <div class="pager">
                        <a-pagination
                            show-size-changer
                            :default-current="input.page"
                            :total="input.count"
                            @showSizeChange="(p,e)=>{getDisRule(input.page,e)}"
                            @change="(e)=>{getDisRule(e,input.limit)}"
                        />
                    </div>
                </a-tab-pane>
            </a-tabs>
        </div>
    </div>
</template>
<script>
import { reactive, toRefs } from 'vue'
export default {
    setup() {
        const state = reactive({
            active:'1',
            input:{
                list:[
                    {id:1,type:'采购入库',count:'200kg',price:'1000元',user:'齐丽丽',origin:'xx化工厂',remark:'备注说明',create_time:'2020-05-21'},
                    {id:1,type:'采购入库',count:'200kg',price:'1000元',user:'齐丽丽',origin:'xx化工厂',remark:'备注说明',create_time:'2020-05-21'},
                    {id:1,type:'采购入库',count:'200kg',price:'1000元',user:'齐丽丽',origin:'xx化工厂',remark:'备注说明',create_time:'2020-05-21'},
                    {id:1,type:'采购入库',count:'200kg',price:'1000元',user:'齐丽丽',origin:'xx化工厂',remark:'备注说明',create_time:'2020-05-21'},
                    {id:1,type:'采购入库',count:'200kg',price:'1000元',user:'齐丽丽',origin:'xx化工厂',remark:'备注说明',create_time:'2020-05-21'},
                    {id:1,type:'采购入库',count:'200kg',price:'1000元',user:'齐丽丽',origin:'xx化工厂',remark:'备注说明',create_time:'2020-05-21'},
                    {id:1,type:'采购入库',count:'200kg',price:'1000元',user:'齐丽丽',origin:'xx化工厂',remark:'备注说明',create_time:'2020-05-21'},
                    {id:1,type:'采购入库',count:'200kg',price:'1000元',user:'齐丽丽',origin:'xx化工厂',remark:'备注说明',create_time:'2020-05-21'},
                ],
                page:1,
                limit:10,
                count:0
            },
            output:{
                list:[
                    {id:1,type:'采购入库',count:'200kg',land_name:'A地块',user:'齐丽丽',remark:'备注说明',create_time:'2020-05-21'},
                    {id:1,type:'采购入库',count:'200kg',land_name:'A地块',user:'齐丽丽',remark:'备注说明',create_time:'2020-05-21'},
                    {id:1,type:'采购入库',count:'200kg',land_name:'A地块',user:'齐丽丽',remark:'备注说明',create_time:'2020-05-21'},
                    {id:1,type:'采购入库',count:'200kg',land_name:'A地块',user:'齐丽丽',remark:'备注说明',create_time:'2020-05-21'},
                    {id:1,type:'采购入库',count:'200kg',land_name:'A地块',user:'齐丽丽',remark:'备注说明',create_time:'2020-05-21'},
                    {id:1,type:'采购入库',count:'200kg',land_name:'A地块',user:'齐丽丽',remark:'备注说明',create_time:'2020-05-21'},
                    {id:1,type:'采购入库',count:'200kg',land_name:'A地块',user:'齐丽丽',remark:'备注说明',create_time:'2020-05-21'},
                    {id:1,type:'采购入库',count:'200kg',land_name:'A地块',user:'齐丽丽',remark:'备注说明',create_time:'2020-05-21'},
                ],
                page:1,
                limit:10,
                count:0
            }
        })

        return{
            ...toRefs(state)
        }
    },
}
</script>
<style scoped lang="scss">
.warehouse-detail{
    .wd-name{
        font-weight: bold;
        font-size: 18px;
    }
    .wd-con{
        width: 800px;
        display: flex;
        flex-wrap: wrap;

        &-item{
            width: 400px;
            line-height: 32px;
        }
    }
}
</style>