<template>
    <div class="v2-adopt-list">
        <div class="el-content v2al-header mb12">
            <div class="v2al-header-left" v-if="asState">
                <div class="v2al-header-left-title">
                    <h4>数据统计</h4>
                    <div>
                        <span class="time">更新时间：{{ new Date().toLocaleString() }}</span>
                        <i class="ri-refresh-line ri-lg ri-top" @click="getAdoptStatistics()"></i>
                    </div>
                </div>
                <div class="v2al-header-left-info">
                      <div class="item">
                          <p>{{ asState.adopt_nums }}</p>
                          <span>认养项目</span>
                      </div>
                      <div class="item">
                          <p>{{ asState.cycle }}</p>
                          <span>认养周期</span>
                      </div>
                      <div class="item">
                          <p>{{ asState.adopt_order }}</p>
                          <span>认养订单</span>
                      </div>
                      <div class="item">
                          <p>{{ asState.adopt_DeliveryTime_order }}</p>
                          <span>待配送订单</span>
                      </div>
                </div>
            </div>
            <div class="v2al-header-right">
                <h4 class="v2al-header-right-title">认养通知</h4>
                 <template v-if="messageList.length >0">
                     <div class="v2al-header-right-item" v-for="item in messageList" :key="item.id">
                         <div class="content">{{ item.title }}</div>
                         <router-link :to="{path:'/adopt/orderDetail',query:{order_id:item.to_id}}">
                            <i class="ri-more-fill ri-lg"></i>
                         </router-link>
                     </div>
                </template>
            </div>
        </div>
        <div class="el-content v2al-list">
            <a-tabs v-model:activeKey="search.category_id" @change="getAdoptList(1,adoptState.limit)">
                <a-tab-pane key="" tab="全部"></a-tab-pane>
            </a-tabs>
            <a-space class="mb12">
                <com-shop-search-select v-if="isShop == 0" @change="e=>search.shop_id = e"></com-shop-search-select>
                <a-input-search
                    v-model:value="search.key"
                    placeholder="请输入项目名称进行搜索"
                    enter-button style="width: 300px;"
                    @search="getAdoptList(1, adoptState.limit)"
                />
                <router-link :to="{path:isShop == 0 ?'/adopt/edit':'/many_store/adopt/edit',query:{shop_id:search.shop_id}}">
                  <a-button type="primary" v-has="{action:'/adopt/edit',plat:isShop}">
                    <i class="ri-add-line ri-top"></i>新增项目
                  </a-button>
                </router-link>
            </a-space>
            <div v-for="(item,index) in adoptState.list" :key="index">
                <a-tooltip title="点击查看周期信息">
                    <a-row class="v2al-list-item" @click="getAdoptCycleList(item.id)">
                        <a-col :span="6">
                            <div class="v2al-list-info">
                                <img class="cover" :src="item.cover" alt="">
                                <div>
                                    <div class="adopt-name">{{ item.name }}</div>
                                    <span class="adopt-number">编号：{{ item.id }}</span>
                                    <div style="display: flex;">
                                        <a-rate v-model:value="item.favorable_num" disabled/>
                                    </div>
                                </div>
                          </div>
                        </a-col>
                        <a-col :span="3">
                            <div class="v2al-list-num">
                                <b>{{ item.all_numbers }}</b>
                                <div>周期数</div>
                            </div>
                        </a-col>
                        <a-col :span="3">
                            <div class="v2al-list-num">
                                <b> {{ item.view }} </b>
                                <div>项目热度</div>
                            </div>
                        </a-col>
                        <a-col :span="3">
                            <div class="v2al-list-num">
                                <b>{{ item.all_order_nums }}</b>
                                <div>订单数量</div>
                            </div>
                        </a-col>
                        <a-col :span="3">
                            <div class="v2al-list-num">
                                <b>{{ item.sales_volume }}</b>
                                <div>销售总额</div>
                            </div>
                        </a-col>
                        <a-col :span="6">
                            <div class="v2al-list-btn">
                                <a-space>
                                    <kd-button title="查看周期信息" type="success" icon="ri-calendar-check-line"
                                               @click.stop="getAdoptCycleList(item.id)"
                                               v-has="{action:'wxapp_adopt_cycle_list',plat:isShop}"></kd-button>
                                    <kd-button title="添加周期信息" type="primary" icon="ri-add-line"
                                               @click.stop="copyAdoptCycle(item.id)"
                                               v-has="{action:'/adopt/edit',plat:isShop}"></kd-button>
                                    <router-link :to="{
                                                path:isShop == 0 ?'/adopt/edit':'/many_store/adopt/edit',
                                                query:{shop_id:search.shop_id,id:item.id}
                                        }">
                                        <kd-button title="编辑项目"
                                                   type="purple"
                                                   icon="ri-edit-box-line"
                                                   v-has="{action:'/adopt/edit',plat:isShop}">
                                        </kd-button>
                                    </router-link>

                                    <kd-button title="删除" type="danger" icon="ri-delete-bin-line"
                                               @click.stop="delAdopt(item.id)"
                                               v-has="{action:'/adopt/edit',plat:isShop}"></kd-button>
                                    <a-dropdown>
                                    <kd-button title="更多信息" type="purple" icon="ri-more-fill"
                                               v-has="{action:'/adopt/edit',plat:isShop}"></kd-button>
                                        <template #overlay>
                                            <a-menu>
                                                <a-menu-item @click="showFeature(item.id)">项目特点</a-menu-item>
                                                <a-menu-item @click="showFlow(item.id)">认养流程</a-menu-item>
                                                <a-menu-item @click="showEnvironment(item.id)">认养环境</a-menu-item>
                                                <a-menu-item @click="showRank(item.id)">认养排名</a-menu-item>
                                                <a-menu-item @click="showIssue(item.id)">认养问题</a-menu-item>
                                            </a-menu>
                                        </template>
                                    </a-dropdown>
                                </a-space>
                          </div>
                        </a-col>
                    </a-row>
                </a-tooltip>
                <div class="v2al-list-cycle" v-if="acState.show && acState.adopt_id == item.id">
                    <a-table :pagination="false" row-key="id" :data-source="acState.list" :columns="[
                                {title:'名称',dataIndex:'name',slots:{customRender:'name'}},
                                {title:'周期',dataIndex:'numbers',slots:{customRender:'numbers'}},
                                {title:'状态',dataIndex:'adopt_status',slots:{customRender:'adopt_status'}},
                                {title:'认养数量',dataIndex:'count'},
                                {title:'单价',dataIndex:'price'},
                                {title:'认养天数',dataIndex:'purchase_month'},
                                {title:'上下架',dataIndex:'is_put_away',slots:{customRender:'is_put_away'}},
                                {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
                            ]">
                        <template #name="{record}">
                            <div class="v2al-list-info">
                                <a-image
                                    style="width: 50px; height: 50px;margin-right: 12px;"
                                    :src="record.cover"/>
                                <div>
                                  <div class="adopt-name">{{ record.name }}</div>
                                  <div class="adopt-number">编号：{{ record.id }}</div>
                                  <div class="adopt-number">品种：{{ record.varieties }}</div>
                                </div>
                            </div>
                        </template>
                        <template #numbers="{record}">
                            <span>第{{ record.numbers }}期</span>
                        </template>
                        <template #adopt_status="{record}">
                            <a-dropdown>
                                <a-tag :color="cycleStatus(record.adopt_status,false)">
                                    {{cycleStatus(record.adopt_status, true)}}
                                </a-tag>
                                <template #overlay>
                                    <a-menu>
                                        <a-menu-item @click="changeAdoptCycleStatus(record.id,1,record.copy_id)">报名中</a-menu-item>
                                        <a-menu-item @click="changeAdoptCycleStatus(record.id,2,record.copy_id)">认养中</a-menu-item>
                                        <a-menu-item @click="changeAdoptCycleStatus(record.id,3,record.copy_id)">配送中</a-menu-item>
                                        <a-menu-item @click="changeAdoptCycleStatus(record.id,4,record.copy_id)">已完成</a-menu-item>
                                    </a-menu>
                                </template>
                            </a-dropdown>
                        </template>
                        <template #is_put_away="{record}">
                            <a-tag
                                style="cursor: pointer;"
                                :color="record.is_put_away ==1 ?'#00CC66':'#999'"
                                @click="changeAdoptCyclePutAway(record.is_put_away,record.id,record.copy_id)">
                                {{ record.is_put_away == 1 ? '上架中' : '已下架' }}
                            </a-tag>
                        </template>
                        <template #action="{record}">
                            <a-space>
                                <router-link :to="{
                                        path: isShop == 0 ? '/adopt/edit':'/many_store/adopt/edit',
                                        query:{id:record.id,shop_id:search.shop_id}
                                    }"
                                >
                                    <kd-button
                                        title="编辑项目"
                                        type="purple"
                                        icon="ri-edit-box-line"
                                        v-has="{action:'/adopt/edit',plat:isShop}">
                                    </kd-button>
                                </router-link>
                                <kd-button
                                    title="认养码"
                                    type="primary"
                                    icon="ri-qr-code-line"
                                    @click="showAdoptCycleQrcode(record.id,record.qrcode)">
                                </kd-button>
                                <kd-button
                                    title="删除"
                                    type="danger"
                                    icon="ri-delete-bin-line"
                                    @click="deleteAdoptCycle(record.id,record.copy_id)"
                                    v-has="{action:'/adopt/edit',plat:isShop}">
                                </kd-button>
                                <a-dropdown>
                                    <kd-button
                                        title="更多信息"
                                        type="purple"
                                        icon="ri-more-fill"
                                        v-has="{action:'/adopt/edit',plat:isShop}">
                                    </kd-button>
                                    <template #overlay>
                                        <a-menu>
                                            <a-menu-item @click="showFeature(record.id)">项目特点</a-menu-item>
                                            <a-menu-item @click="showFlow(record.id)">认养流程</a-menu-item>
                                            <a-menu-item @click="showEnvironment(record.id)">认养环境</a-menu-item>
                                            <a-menu-item @click="showRank(record.id)">认养排名</a-menu-item>
                                            <a-menu-item @click="showIssue(record.id)">认养问题</a-menu-item>
                                        </a-menu>
                                    </template>
                                </a-dropdown>
                            </a-space>
                        </template>
                  </a-table>
                </div>
            </div>
            <div class="pager">
                <a-pagination
                    show-size-changer
                    :default-current="adoptState.page"
                    :total="adoptState.count"
                    @showSizeChange="(p,e)=>{getAdoptList(adoptState.page,e)}"
                    @change="(e)=>{getAdoptList(e,adoptState.limit)}"
                />
            </div>
        </div>

        <!-- 认养特点 -->
        <a-drawer title="项目特点设置" :width="900" :visible="afState.show" @close="afState.show = false">
            <a-form>
                <a-form-item label="项目特点1" v-for="(item,index) in afState.form" :key="index">
                    <div class="v2al-feature-item">
                        <kd-img-select :src="item.img" @success="(url)=>{ item.img = url}"></kd-img-select>
                        <a-input v-model:value="item.name" class="v2al-input" placeholder="特点名称" style="width: 300px;"></a-input>
                        <a-input v-model:value="item.summary" class="v2al-input" placeholder="特点简介" style="width: 500px;"></a-input>
                        <i class="ri-delete-bin-line ri-2x" style="margin-left: 12px;" @click="addOrDelFeature(index)"></i>
                    </div>
                </a-form-item>
            </a-form>
            <div class="drawer-footer">
                <a-space>
                    <a-button type="primary" @click="addOrDelFeature(-1)">
                        <i class="ri-add-line ri-lg ri-top"></i>添加特点
                    </a-button>
                    <a-button type="primary" @click="saveFeature">保存</a-button>
                    <a-button style="margin-right: 8px" @click="afState.show = false">取消</a-button>
                </a-space>
            </div>
        </a-drawer>

        <!-- 认养流程 -->
        <a-drawer :width="900" :visible="flowState.show" @close="flowState.show = false">
            <template #title>
                <span>认养流程设置
                    <a-tooltip>
                        <template #title>
                            <div><a-image style="width: 200px;" :src="$util.getStaticSrc('adopt/explain-4.jpg')"/></div>
                        </template>
                        <i class="ri-information-fill"></i>
                    </a-tooltip>
                </span>
            </template>
            <a-form>
                <a-form-item label="开启流程">
                    <kd-switch :value="flowState.form.status"
                               :active-value="1"
                               :inactive-value="2"
                               @change="e=>flowState.form.status = e" >
                    </kd-switch>
                </a-form-item>
                <template v-if="flowState.form.status==1">
                    <a-row :gutter="20" class="v2al-feature-flow" v-for="(item,index) in flowState.form.flowinfo" :key="index">
                        <a-col :span="12">
                            <a-form-item label="流程标题">
                                <a-input v-model:value="item.name" placeholder="请输入流程标题"></a-input>
                            </a-form-item>
                            <a-form-item label="流程说明">
                                <a-input v-model:value="item.summary" type="textarea" placeholder="请输入流程说明"></a-input>
                            </a-form-item>
                            <a-form-item v-model:value="item.time" label="流程时间">
                                <a-range-picker v-model:value="item.time"/>
                            </a-form-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-item label="流程图标">
                                <kd-img-select :src="item.img" @success="(url)=>{ item.img = url}"></kd-img-select>
                            </a-form-item>
                            <a-form-item label="流程示例图">
                                <kd-img-select :mutil="true" :src="item.imgs" @success="(url)=>{ item.imgs = url}"></kd-img-select>
                            </a-form-item>
                        </a-col>
                    </a-row>
                </template>
            </a-form>
            <div class="drawer-footer">
                <a-space>
                    <a-button type="primary" @click="addOrDelFlow(-1)">
                        <i class="ri-add-line ri-lg ri-top"></i>添加流程
                    </a-button>
                    <a-button type="primary" @click="saveFlow">保存</a-button>
                    <a-button style="margin-right: 8px" @click="flowState.show = false">取消</a-button>
                </a-space>
            </div>
        </a-drawer>

        <!-- 认养问题 -->
        <a-drawer :width="900" :visible="aiState.show" @close="aiState.show = false">
            <template #title>
                <span>认养问题设置
                    <a-tooltip>
                        <template #title>
                            <div><a-image style="width: 200px;" :src="$util.getStaticSrc('adopt/explain-3.jpg')"/></div>
                        </template>
                        <i class="ri-information-fill"></i>
                    </a-tooltip>
                </span>
            </template>
            <a-form ref="issueForm" >
                <a-form-item label="开启问题">
                    <kd-switch :value="aiState.form.qa_status" @change="e=>aiState.form.qa_status = e" :active-value="1"
                             :inactive-value="2"></kd-switch>
                </a-form-item>
                <template v-if="aiState.form.qa_status == 1">
                    <a-form-item :label="'问题'+(index+1)" v-for="(item,index) in aiState.form.qa_array" :key="index">
                        <div style="display: flex;align-items: center;">
                            <a-input v-model:value="item.ask_title" placeholder="请输入问题信息" style="width: 250px;"></a-input>
                            <a-input v-model:value="item.asnwer" placeholder="请输入问题答案" style="width: 400px;margin: 0 12px;"></a-input>
                            <i class="ri-delete-back-2-fill ri-2x" @click="addOrDelIssue(index)"></i>
                        </div>
                    </a-form-item>
                </template>
            </a-form>
            <div class="drawer-footer">
                <a-space>
                    <a-button type="primary" @click="addOrDelIssue(-1)">
                        <i class="ri-add-line ri-lg ri-top"></i>添加问题
                    </a-button>
                    <a-button type="primary" @click="saveIssue">保存</a-button>
                    <a-button style="margin-right: 8px" @click="aiState.show = false">取消</a-button>
                </a-space>
            </div>
        </a-drawer>

        <!-- 认养排名 -->
        <a-modal v-model:visible="arState.show" @ok="saveRank" width="600px" @cancel="arState.show = false">
            <template #title>
                <span>认养排名设置
                    <a-tooltip>
                        <template #title>
                            <div><a-image style="width: 200px;" :src="$util.getStaticSrc('adopt/explain-2.jpg')"/></div>
                        </template>
                        <i class="ri-information-fill"></i>
                    </a-tooltip>
                </span>
            </template>
            <a-form ref="typeForm" :label-col="{span:4}" :wrapper-col="{span:16}">
                <a-form-item label="开启认养排名">
                    <kd-switch :value="arState.form.ranking_status" @change="e=>arState.form.ranking_status = e" :active-value="1"
                             :inactive-value="2"></kd-switch>
                </a-form-item>
                <template v-if="arState.form.ranking_status == 1">
                    <a-form-item label="认养排名数量">
                        <a-input v-model:value="arState.form.ranking_num" addon-after="个"></a-input>
                    </a-form-item>
                    <a-form-item label="认养排名说明">
                        <a-input v-model:value="arState.form.ranking_info"></a-input>
                    </a-form-item>
                </template>
            </a-form>
        </a-modal>

        <!-- 认养环境设置 -->
        <a-drawer :width="900" :visible="aeState.show" @close="aeState.show = false">
            <template #title>
                <span>认养环境设置
                    <a-tooltip>
                        <template #title>
                            <div><a-image style="width: 200px;" :src="$util.getStaticSrc('adopt/explain-1.jpg')"/></div>
                        </template>
                        <i class="ri-information-fill"></i>
                    </a-tooltip>
                </span>
            </template>
            <a-form :label-col="{span:4}" :wrapper-col="{span:16}">
                <a-form-item label="农场名称">
                    <kd-switch :value="aeState.form.status" @change="e=>aeState.form.status = e" :active-value="1"
                             :inactive-value="2"></kd-switch>
                </a-form-item>
                <template v-if="aeState.form.status==1">
                    <a-form-item label="农场名称">
                        <a-input v-model:value="aeState.form.name" placeholder="农场名称"></a-input>
                    </a-form-item>
                    <a-form-item label="农场主姓名">
                        <a-input v-model:value="aeState.form.user_name" placeholder="农场主姓名"></a-input>
                    </a-form-item>
                    <a-form-item label="农场主头像">
                        <kd-img-select :src="aeState.form.user_avatar"
                                   @success="(url)=>{ aeState.form.user_avatar = url}"></kd-img-select>
                    </a-form-item>
                    <a-form-item label="地址名称">
                        <a-input v-model:value="aeState.form.area.address" placeholder="地址名称"></a-input>
                    </a-form-item>
                    <a-form-item label="选择区域">
                        <span v-if="aeState.form.area.area">已绘制区域{{ aeState.form.area.area }}平方米</span>
                        <div v-for="( item,index ) in aeState.form.area.point" :key="index">
                            经度:{{ item[0] }},纬度：{{item[1]}}
                        </div>
                        <a-button type="primary" @click="aeState.map = true">选择区域</a-button>
                    </a-form-item>
                </template>
            </a-form>
            <div class="drawer-footer">
                <a-space>
                    <a-button type="primary" @click="saveEnvironment">保存</a-button>
                    <a-button style="margin-right: 8px" @click="aeState.show = false">取消</a-button>
                </a-space>
            </div>
        </a-drawer>

        <a-modal v-model:visible="aeState.map" title="选择区域" :footer="null" width="80%">
            <div v-if="aeState.map">
                <kd-map-earth
                    :is-satellite="true"
                    :map-width="1200"
                    :map-height="600"
                    :mapValue="aeState.form.area.point"
                    @getMapData="getMapArea">
                </kd-map-earth>
            </div>
        </a-modal>
        <a-modal title="认养二维码"
                 v-model:visible="acState.showQrcode"
                 :footer="null"
                 width="300px"
                 @cancel="acState.showQrcode = false">
            <div style="text-align:center">
                <p style="color: #999;font-size: 12px;" v-if="!acState.qrcode">请重新编辑并保存商品信息以获取小程序码！</p>
                <div class="mb12">
                    <a-image style="width: 200px; height: 200px" :src="acState.qrcode"/>
                </div>
                <a-button @click="downloadQrcode" type="primary">下载二维码</a-button>
            </div>
        </a-modal>
    </div>
</template>

<script>
import kdMapEarth from "@/components/public/kd-map-earth.vue"
import comShopSearchSelect from '@/components/miniapp/com-shop-search-select.vue'
import {onActivated, reactive, toRefs} from 'vue'
import common from '@/api/common.js'
import {
    useAdopt,
    useAdoptFeature,
    useAdoptFlow,
    useAdoptIssue,
    useAdoptRank ,
    useAdoptEnvironment,
    useAdoptCycle,
    useAdoptStatistics
} from '@/models/adopt'

export default {
    name: "page-adopt-list",
    components: {
        kdMapEarth,
        comShopSearchSelect
    },
    props: {
        isShop: {
            type: [String, Number],
            default: 0
        }
    },
    setup() {
        const _d = reactive({
            search: {
                key: "",
                category_id: "",
                shop_id: "",
            },
            messageList: [],		//消息列表
        })

        let { adoptState,getAdoptList,deleteAdopt } =useAdopt(_d.search)
        getAdoptList(1, adoptState.limit)

        onActivated(() => {
            getAdoptList(adoptState.page, adoptState.limit)
        })

        //统计信息
        let asState = useAdoptStatistics()

        common.getNewMessge(1, 5, {type: 8}, res => _d.messageList = res.list)

        //添加项目特点
        let { afState,showFeature,saveFeature ,addOrDelFeature } = useAdoptFeature()

        //添加认养流程
        let { flowState ,showFlow,saveFlow,addOrDelFlow } = useAdoptFlow()

        //添加认养问题
        let { aiState,showIssue ,addOrDelIssue,saveIssue } = useAdoptIssue()

        //添加认养排名
        let { arState,showRank,saveRank } = useAdoptRank()

        //添加环境
        let { aeState ,showEnvironment,saveEnvironment,getMapArea } = useAdoptEnvironment()

        //认养周期
        let acState = useAdoptCycle()

        return {
            ...toRefs(_d),

            ...asState,
            adoptState,getAdoptList,deleteAdopt,
            afState,showFeature,saveFeature,addOrDelFeature,
            flowState ,showFlow,saveFlow,addOrDelFlow,
            aiState,showIssue ,addOrDelIssue,saveIssue,
            arState,showRank,saveRank,
            aeState ,showEnvironment,saveEnvironment,getMapArea,
            ...acState
        }
    },
}
</script>
<style lang="scss">
    .drawer-footer {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 100%;
         border-top: 1px solid #e9e9e9;
        padding: 10px 16px;
        background: #fff;
         text-align: right;
         z-index: 1;
    }

.v2-adopt-list {
    .v2al-header {
        display: flex;
        height: 160px;

        &-left {
            width: 60%;
            border: 1px solid #f4f4f4;

        &-title {
            padding: 0 24px;
            display: flex;
            height: 40px;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;

            .time {
                color: #999;
                font-size: 15px;
            }

            i {
                color: #999;
                cursor: pointer;
            }
        }

        &-info {
            display: flex;
            align-items: center;
            padding: 0 24px;

            .item {
                width: 140px;
                text-align: center;

                p {
                    font-weight: bold;
                    font-size: 20px;
                    margin: 8px 0;
                 }

                span {
                    color: #999;
                    font-size: 15px;
                 }
            }
        }
    }

        &-right {
            width: 37%;
            border: 1px solid #f4f4f4;
            margin-left: 3%;

            &-title {
                padding: 0 24px;
                margin: 8px 0 4px 0;
            }

            &-item {
                display: flex;
                align-items: center;
                height: 20px;
                padding: 0 24px;
                cursor: pointer;

                .content {
                    width: 60%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-size: 12px;
                    color: #666;
                }

                span {
                    margin-right: 8px;
                    font-size: 12px;
                    color: #999;
                }
            }
        }
    }

    .v2al-list {


        &-item {
            border: 1px solid #f4f4f4;
            padding: 12px;
            margin-bottom: 12px;
            cursor: pointer;
        }

        &-info {
            width: 100%;
            display: flex;
        }

        .cover {
             width: 80px;
             height: 80px;
             margin-right: 12px;
        }

        .adopt-number {
            color: #999;
            font-size: 13px;
        }

        &-num {
            text-align: center;
            padding-top: 8px;

            b {
                font-size: 20px;
            }

            div {
                color: #999;
                font-size: 13px;
                margin-top: 6px;
            }
        }

        &-btn {
            width: 100%;
            display: flex;
            height: 80px;
            align-items: center;
            justify-content: center;
        }
    }
}



.v2al-feature {
    padding: 24px;
    overflow: hidden;
    overflow-y: auto;
    position: fixed;
    height: 60%;
    width: 65%;

    &-flow {
        border: 1px solid #f4f4f4;
        padding: 12px 0;
        margin-bottom: 12px;
    }
}

.v2al-feature-item {
    display: flex;

    .v2al-input {
        height: 32px;
        margin: 4px 6px 0 6px;
    }
    i{
        cursor: pointer;
    }
}

.ri-delete-back-2-fill {
    cursor: pointer;
}
</style>
