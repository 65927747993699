<template>
    <!-- 仓库主页 -->
    <div class="warehouse">
        <div class="wh-head mb12">
            <div class="wh-head-card" @click="toDetail('/adhibition/digitalFarm/warning')">
                <div class="wh-head-icon icon1">
                    <div class="whhi-cycle"><i class="ri-alert-line"></i></div>
                </div>
                <div class="wh-head-right">
                    <p>200</p>
                    <span class="title">预警信息</span>
                </div>
            </div>
            <div class="wh-head-card" @click="toDetail('/adhibition/digitalFarm/stock_taking')">
                <div class="wh-head-icon icon2">
                    <div class="whhi-cycle"><i class="ri-download-2-line"></i></div>
                </div>
                <div class="wh-head-right">
                    <p>200</p>
                    <span class="title">今日入库笔数</span>
                </div>
            </div>
            <div class="wh-head-card">
                <div class="wh-head-icon icon3">
                    <div class="whhi-cycle"><i class="ri-logout-circle-r-line"></i></div>
                </div>
                <div class="wh-head-right">
                    <p>200</p>
                    <span class="title">今日出库笔数</span>
                </div>
            </div>
            <div class="wh-head-card">
                <div class="wh-head-icon icon4">
                    <div class="whhi-cycle"><i class="ri-copper-coin-line"></i></div>
                </div>
                <div class="wh-head-right">
                    <p>200</p>
                    <span class="title">今日销售金额</span>
                </div>
            </div>
            <div class="wh-head-card">
                <div class="wh-head-icon icon5">
                    <div class="whhi-cycle"><i class="ri-money-cny-circle-line"></i></div>
                </div>
                <div class="wh-head-right">
                    <p>200</p>
                    <span class="title">今日采购金额</span>
                </div>
            </div>
        </div>

        <div class="el-content mb12">
            <a-space>
                <a-select
                    v-model:value="search.key1"
                    placeholder="请选择物品类型"
                    style="width: 200px"
                >
                    <a-select-option value="jack">Jack</a-select-option>
                </a-select>
                <a-input-search
                    v-model:value="search.key2"
                    placeholder="搜索物品编号、名称"
                    enter-button
                    style="width: 400px"
                    @search="onSearch"
                />
            </a-space>
        </div>

        <a-row :gutter="[16,16]">
            <a-col :span="18">
                <div class="el-content"  style="height:456px;overflow:hidden;overflow-y:auto">
                    <a-table :pagination="false" rowKey="id" :data-source="info.list" :columns="[
                        {title:'ID',dataIndex:'id'},
                        {title:'仓库',dataIndex:'name'},
                        {title:'类型',dataIndex:'type'},
                        {title:'编号',dataIndex:'number'},
                        {title:'物品名称',dataIndex:'goods_name'},
                        {title:'规格型号',dataIndex:'spce'},
                        {title:'单位',dataIndex:'unit'},
                        {title:'库存',dataIndex:'count'},
                        {title:'备注',dataIndex:'remark'},
                        {title:'操作',dataIndex:'action'},
                    ]" >
                    </a-table>
                    <div class="pager">
                        <a-pagination
                            show-size-changer
                            :default-current="info.page"
                            :total="info.count"
                            @showSizeChange="(p,e)=>{getDisRule(info.page,e)}"
                            @change="(e)=>{getDisRule(e,info.limit)}"
                        />
                    </div>
                </div>
            </a-col>
            <a-col :span="6">
                <div class="wh-oprate">
                    <div class="wh-oprate-item">
                        <i class="ri-download-2-line"></i>
                        <span>添加入库</span>
                    </div>
                    <div class="wh-oprate-item">
                        <i class="ri-logout-circle-r-line"></i>
                        <span>添加出库</span>
                    </div>
                    <div class="wh-oprate-item" @click="toDetail('/adhibition/digitalFarm/customer')">
                        <i class="ri-account-box-fill"></i>
                        <span>客户管理</span>
                    </div>
                    <div class="wh-oprate-item" @click="toDetail('/adhibition/digitalFarm/supplier')">
                        <i class="ri-user-star-fill"></i>
                        <span>供应商管理</span>
                    </div>
                    <div class="wh-oprate-item">
                        <i class="ri-home-8-fill"></i>
                        <span>仓库管理</span>
                    </div>
                    <div class="wh-oprate-item" @click="toDetail('/adhibition/digitalFarm/product')">
                        <i class="ri-shopping-bag-3-fill"></i>
                        <span>产品管理</span>
                    </div>
                    <div class="wh-oprate-item" @click="toDetail('/adhibition/digitalFarm/farm_data')">
                        <i class="ri-profile-fill"></i>
                        <span>农资管理</span>
                    </div>

                </div>
            </a-col>
        </a-row>
    </div>
</template>
<script>
import { reactive, toRefs } from 'vue'
import router from '@/router'
export default {
    setup() {
        const state = reactive({
            search:{
               key1:""
            },
            info:{
                list:[
                    {id:1,name:'城南1号仓库',type:'产品',number:'1111222',goods_name:'脐橙',spce:'188kg',unit:'斤',count:100,remark:'测试'},
                    {id:2,name:'城南1号仓库',type:'产品',number:'1111222',goods_name:'脐橙',spce:'188kg',unit:'斤',count:100,remark:'测试'},
                    {id:3,name:'城南1号仓库',type:'产品',number:'1111222',goods_name:'脐橙',spce:'188kg',unit:'斤',count:100,remark:'测试'},
                    {id:4,name:'城南1号仓库',type:'产品',number:'1111222',goods_name:'脐橙',spce:'188kg',unit:'斤',count:100,remark:'测试'},
                    {id:5,name:'城南1号仓库',type:'产品',number:'1111222',goods_name:'脐橙',spce:'188kg',unit:'斤',count:100,remark:'测试'},
                    {id:6,name:'城南1号仓库',type:'产品',number:'1111222',goods_name:'脐橙',spce:'188kg',unit:'斤',count:100,remark:'测试'},
                    {id:7,name:'城南1号仓库',type:'产品',number:'1111222',goods_name:'脐橙',spce:'188kg',unit:'斤',count:100,remark:'测试'},
                    {id:8,name:'城南1号仓库',type:'产品',number:'1111222',goods_name:'脐橙',spce:'188kg',unit:'斤',count:100,remark:'测试'},
                    {id:9,name:'城南1号仓库',type:'产品',number:'1111222',goods_name:'脐橙',spce:'188kg',unit:'斤',count:100,remark:'测试'},
                    {id:10,name:'城南1号仓库',type:'产品',number:'1111222',goods_name:'脐橙',spce:'188kg',unit:'斤',count:100,remark:'测试'},
                ],
                page:1,
                limit:10,
                count:0
            }
        })

        function toDetail(path){
            router.push(path)
        }

        return{
            ...toRefs(state),
            toDetail
        }
    },
}
</script>
<style scoped lang="scss">
.wh-head{
    width: 100%;
    display: flex;
    justify-content: space-between;

    &-card{
        background: #fff;
        padding: 12px;
        width: 19%;
        height: 100px;
        display: flex;
        cursor: pointer;
    }

    &-icon{
        width: 60px;
        height: 60px;
        border-radius: 50%;
        box-sizing: border-box;
        padding: 10px;
        
        .whhi-cycle{
            width: 40px;
            height: 40px;
            color: #fff;
            border-radius: 50%;
            line-height: 40px;
            text-align: center;
            
            i{
                font-size: 18px;
            }
        }
    }

    .icon1{
        background: rgba($color: #FF0066, $alpha: .2); 
        .whhi-cycle{
            background: #FF0066;
        }
    }
    .icon2{
        background: rgba($color: #33CC99, $alpha: .2); 
        .whhi-cycle{
            background: #33CC99;
        }
    }
    .icon3{
        background: rgba($color: #33CCFF, $alpha: .2); 
        .whhi-cycle{
            background: #33CCFF;
        }
    }
    .icon4{
        background: rgba($color: #6699FF, $alpha: .2); 
        .whhi-cycle{
            background: #6699FF;
        }
    }
    .icon5{
        background: rgba($color: #FF9900, $alpha: .2); 
        .whhi-cycle{
            background: #FF9900;
        }
    }

    &-right{
        margin-left: 12px;
        p{
            font-weight: bold;
            font-size: 20px;
            margin-bottom: 2px;
            margin-top: 8px;
        }
        .title{
            color: #b4c1ce;
            font-size: 12px;
        }
    }

}

.wh-oprate{
    width: 100%;

    &-item{
        width: 100%;
        height: 60px;
        background: #fff;
        margin-bottom: 6px;
        padding: 18px 24px;
        cursor: pointer;

        span{
            font-size: 16px;
            font-weight: bold;
            margin-left: 12px;
        }
    }
}
</style>