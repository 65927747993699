<template>
    <div class="digital-detail">
        <a-row :gutter="[16,16]">
            <a-col :span="24">
                <div class="land-info el-content">
                    <span class="title">地块信息</span>
                    <a-select
                        v-model:value="land_id"
                        style="width: 220px"
                        @change="handleChange"
                    >
                        <a-select-option :value="item.id" v-for="(item,index) in landList" :key="index">
                            {{ item.land_name }}
                        </a-select-option>
                    </a-select>

                    <span class="area"><span class="f12">面积：</span> 5600.99 平方米</span>
                    <span class="output"><span class="f12">产品：</span>脐橙，火龙果</span>
                </div>
            </a-col>

            <!-- 种养品种 -->
            <a-col :span="12">
                <div class="land-info el-content" style="height:520px;">
                    <div class="block-title">
                        <span class="title">种养品种</span>
                        <a-button type="link" @click="show.variety = true">添加种养</a-button>
                    </div>
                    <a-table :pagination="false" size="mini" :data-source="variety.list" :columns="[
                        {title:'ID',dataIndex:'id'},
                        {title:'品种',dataIndex:'name'},
                        {title:'数量',dataIndex:'count',slots:{customRender:'count'}},
                        {title:'种养时间',dataIndex:'create_time'},
                        {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
                    ]" >
                        <template #count="{record}" >
                            <span>{{record.count}} <span class="f12">颗</span></span>
                        </template>
                        <template #action="{record}" >
                            <a-button size="small" type="link">收获{{record.id}}</a-button>
                            <a-button size="small" type="link">结束</a-button>
                        </template>
                    </a-table>

                    <div class="pager">
                        <a-pagination
                            show-size-changer
                            :default-current="variety.page"
                            :total="variety.count"
                            @showSizeChange="(p,e)=>{getDisRule(info.page,e)}"
                            @change="(e)=>{getDisRule(e,info.limit)}"
                        />
                    </div>
                </div>
            </a-col>

            <!-- 农事计划 -->
            <a-col :span="12">
                <div class="land-info el-content" style="height:520px;">
                    <div class="block-title">
                        <span class="title">农事计划</span>
                        <a-button type="link">计划管理</a-button>
                    </div>
                    <a-table :pagination="false" size="mini" :data-source="farming.list" :columns="[
                        {title:'ID',dataIndex:'id'},
                        {title:'添加时间',dataIndex:'create_time'},
                        {title:'操作内容',dataIndex:'name'},
                        {title:'状态',dataIndex:'status',slots:{customRender:'status'}},
                    ]" >
                        <template #status="{record}" >
                            <a-tag color="#FF0066" v-if="record.status == 0">待分配</a-tag>
                            <a-tag color="#0099FF" v-if="record.status == 1">进行中</a-tag>
                            <a-tag color="#00CC66" v-if="record.status == 2">已完成</a-tag>
                        </template>
                    </a-table>

                    <div class="pager">
                        <a-pagination
                            show-size-changer
                            :default-current="farming.page"
                            :total="farming.count"
                            @showSizeChange="(p,e)=>{getDisRule(info.page,e)}"
                            @change="(e)=>{getDisRule(e,info.limit)}"
                        />
                    </div>
                </div>
            </a-col>

            <!-- 监控列表 -->
            <a-col :span="12">
                <div class="land-info el-content" style="height:400px;">
                    <div class="block-title">
                        <span class="title">监控列表</span>
                        <a-button type="link">监控管理</a-button>
                    </div>

                    <ul class="li-live">
                        <li>
                            <img src="http://kundian.cqkundian.com/farm/3/jpeg/20201228/c4db49149477a658b14602dba86d5b1b1609120138.jpeg?imageMogr2/auto-orient/thumbnail/!50p/blur/1x0/quality/75" alt="">
                            <div class="name">监控名称</div>
                            <div class="play"><i class="ri-play-fill"></i></div>
                        </li>
                        <li>
                            <img src="http://kundian.cqkundian.com/farm/3/jpeg/20201228/c4db49149477a658b14602dba86d5b1b1609120138.jpeg?imageMogr2/auto-orient/thumbnail/!50p/blur/1x0/quality/75" alt="">
                            <div class="name">监控名称</div>
                            <div class="play"><i class="ri-play-fill"></i></div>
                        </li>
                        <li>
                            <img src="http://kundian.cqkundian.com/farm/3/jpeg/20201228/c4db49149477a658b14602dba86d5b1b1609120138.jpeg?imageMogr2/auto-orient/thumbnail/!50p/blur/1x0/quality/75" alt="">
                            <div class="name">监控名称</div>
                            <div class="play"><i class="ri-play-fill"></i></div>
                        </li>
                        <li>
                            <img src="http://kundian.cqkundian.com/farm/3/jpeg/20201228/c4db49149477a658b14602dba86d5b1b1609120138.jpeg?imageMogr2/auto-orient/thumbnail/!50p/blur/1x0/quality/75" alt="">
                            <div class="name">监控名称</div>
                            <div class="play"><i class="ri-play-fill"></i></div>
                        </li>
                        <li>
                            <img src="http://kundian.cqkundian.com/farm/3/jpeg/20201228/c4db49149477a658b14602dba86d5b1b1609120138.jpeg?imageMogr2/auto-orient/thumbnail/!50p/blur/1x0/quality/75" alt="">
                            <div class="name">监控名称</div>
                            <div class="play"><i class="ri-play-fill"></i></div>
                        </li>
                    </ul>
                    
                </div>
            </a-col>

            <!-- 农场数据 -->
            <a-col :span="12">
                <div class="land-info el-content" style="height:400px;">
                    <div class="block-title">
                        <span class="title">农场数据</span>
                        <a-button type="link">感应器管理</a-button>
                    </div>

                    <ul class="li-device">
                        <li>
                            <div><span class="num">72</span><span class="f12">Lux</span></div>
                            <div class="lid-title">紫外线</div>
                        </li>
                        <li>
                            <div><span class="num">72</span><span class="f12">Lux</span></div>
                            <div class="lid-title">日照强度</div>
                        </li>
                        <li>
                            <div><span class="num">72</span><span class="f12">Lux</span></div>
                            <div class="lid-title">紫外线</div>
                        </li>
                        <li>
                            <div><span class="num">72</span><span class="f12">Lux</span></div>
                            <div class="lid-title">日照强度</div>
                        </li>
                        <li>
                            <div><span class="num">72</span><span class="f12">Lux</span></div>
                            <div class="lid-title">紫外线</div>
                        </li>
                    </ul>
                    
                </div>
            </a-col>

            <!-- 农资记录 -->
            <a-col :span="12">
                <div class="land-info el-content" style="height:520px;">
                    <div class="block-title">
                        <span class="title">农资记录</span>
                        <a-button type="link">仓储管理</a-button>
                    </div>
                    <a-table :pagination="false" size="mini" :data-source="farmCert.list" :columns="[
                        {title:'农资类型',dataIndex:'type'},
                        {title:'物品名称',dataIndex:'name'},
                        {title:'操作对象',dataIndex:'land_name'},
                        {title:'使用数量',dataIndex:'count'},
                        {title:'使用时间',dataIndex:'create_time'},
                    ]" >
                    </a-table>

                    <div class="pager">
                        <a-pagination
                            show-size-changer
                            :default-current="farmCert.page"
                            :total="farmCert.count"
                            @showSizeChange="(p,e)=>{getDisRule(info.page,e)}"
                            @change="(e)=>{getDisRule(e,info.limit)}"
                        />
                    </div>
                </div>
            </a-col>

            <!-- 溯源记录 -->
            <a-col :span="12">
                <div class="land-info el-content" style="height:520px;">
                    <div class="block-title">
                        <span class="title">溯源记录</span>
                        <a-button type="link">速运管理</a-button>
                    </div>
                    <a-table :pagination="false" size="mini" :data-source="trace.list" :columns="[
                        {title:'溯源名称',dataIndex:'name'},
                        {title:'溯源对象',dataIndex:'land_name'},
                        {title:'溯源码',dataIndex:'code'},
                        {title:'访问统计',dataIndex:'count'},
                        {title:'创建时间',dataIndex:'create_time'},
                        {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
                    ]" >
                        <template #action="{record}" >
                            <a-button size="small" type="link">查看{{record.id}}</a-button>
                        </template>
                    </a-table>

                    <div class="pager">
                        <a-pagination
                            show-size-changer
                            :default-current="trace.page"
                            :total="trace.count"
                            @showSizeChange="(p,e)=>{getDisRule(info.page,e)}"
                            @change="(e)=>{getDisRule(e,info.limit)}"
                        />
                    </div>
                </div>
            </a-col>


            <!-- 操作记录 -->
            <a-col :span="12">
                <div class="land-info el-content" style="height:520px;">
                    <div class="block-title">
                        <span class="title">操作记录</span>
                        <a-button type="link">操作管理</a-button>
                    </div>
                    <a-timeline >
                        <a-timeline-item>
                            <div class="li-record">
                                <div class="time">2021-02-11</div>
                                <div class="desc">张三,除草</div>
                                <div class="img">
                                    <a-space>
                                        <a-image width="64px" height="64px" src="http://kundian.cqkundian.com/farm/3/jpeg/20201228/c4db49149477a658b14602dba86d5b1b1609120138.jpeg?imageMogr2/auto-orient/thumbnail/!50p/blur/1x0/quality/75" />
                                        <a-image width="64px" height="64px" src="http://kundian.cqkundian.com/farm/3/jpeg/20201228/c4db49149477a658b14602dba86d5b1b1609120138.jpeg?imageMogr2/auto-orient/thumbnail/!50p/blur/1x0/quality/75" />
                                        <a-image width="64px" height="64px" src="http://kundian.cqkundian.com/farm/3/jpeg/20201228/c4db49149477a658b14602dba86d5b1b1609120138.jpeg?imageMogr2/auto-orient/thumbnail/!50p/blur/1x0/quality/75" />
                                        <a-image width="64px" height="64px" src="http://kundian.cqkundian.com/farm/3/jpeg/20201228/c4db49149477a658b14602dba86d5b1b1609120138.jpeg?imageMogr2/auto-orient/thumbnail/!50p/blur/1x0/quality/75" />
                                    </a-space>
                                </div>
                            </div>
                        </a-timeline-item>
                        <a-timeline-item>
                            <div class="li-record">
                                <div class="time">2021-02-11</div>
                                <div class="desc">张三,除草</div>
                                <div class="img">
                                    <a-space>
                                        <a-image width="64px" height="64px" src="http://kundian.cqkundian.com/farm/3/jpeg/20201228/c4db49149477a658b14602dba86d5b1b1609120138.jpeg?imageMogr2/auto-orient/thumbnail/!50p/blur/1x0/quality/75" />
                                        <a-image width="64px" height="64px" src="http://kundian.cqkundian.com/farm/3/jpeg/20201228/c4db49149477a658b14602dba86d5b1b1609120138.jpeg?imageMogr2/auto-orient/thumbnail/!50p/blur/1x0/quality/75" />
                                        <a-image width="64px" height="64px" src="http://kundian.cqkundian.com/farm/3/jpeg/20201228/c4db49149477a658b14602dba86d5b1b1609120138.jpeg?imageMogr2/auto-orient/thumbnail/!50p/blur/1x0/quality/75" />
                                        <a-image width="64px" height="64px" src="http://kundian.cqkundian.com/farm/3/jpeg/20201228/c4db49149477a658b14602dba86d5b1b1609120138.jpeg?imageMogr2/auto-orient/thumbnail/!50p/blur/1x0/quality/75" />
                                    </a-space>
                                </div>
                            </div>
                        </a-timeline-item>
                        <a-timeline-item>
                            <div class="li-record">
                                <div class="time">2021-02-11</div>
                                <div class="desc">张三,除草</div>
                                <div class="img">
                                    <a-space>
                                        <a-image width="64px" height="64px" src="http://kundian.cqkundian.com/farm/3/jpeg/20201228/c4db49149477a658b14602dba86d5b1b1609120138.jpeg?imageMogr2/auto-orient/thumbnail/!50p/blur/1x0/quality/75" />
                                        <a-image width="64px" height="64px" src="http://kundian.cqkundian.com/farm/3/jpeg/20201228/c4db49149477a658b14602dba86d5b1b1609120138.jpeg?imageMogr2/auto-orient/thumbnail/!50p/blur/1x0/quality/75" />
                                        <a-image width="64px" height="64px" src="http://kundian.cqkundian.com/farm/3/jpeg/20201228/c4db49149477a658b14602dba86d5b1b1609120138.jpeg?imageMogr2/auto-orient/thumbnail/!50p/blur/1x0/quality/75" />
                                        <a-image width="64px" height="64px" src="http://kundian.cqkundian.com/farm/3/jpeg/20201228/c4db49149477a658b14602dba86d5b1b1609120138.jpeg?imageMogr2/auto-orient/thumbnail/!50p/blur/1x0/quality/75" />
                                    </a-space>
                                </div>
                            </div>
                        </a-timeline-item>
                    </a-timeline>
                </div>
            </a-col>
        </a-row>

        <a-modal title="添加种养品种" v-model:visible="show.variety"  @ok="handleOk" width="700px">
            <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
				<a-form-item label="类型">
					<a-select v-model:value="varietyForm.time"></a-select>
				</a-form-item>
				<a-form-item label="品种" >
					<a-input v-model:value="varietyForm.name"></a-input>
				</a-form-item>
                <a-form-item label="数量" >
					<a-input v-model:value="varietyForm.count"></a-input>
				</a-form-item>
                <a-form-item label="种养时间" >
                    <a-date-picker v-model:value="varietyForm.create_time" />
				</a-form-item>
			</a-form>
        </a-modal>  


    </div>
</template>
<script>
import { reactive, toRefs } from 'vue'
export default {
    setup() {
        const state = reactive({
            show:{
                variety:false,
            },
            landList:[
                {id:1,land_name:"地块一"},
                {id:2,land_name:"地块二"},
                {id:3,land_name:"地块三"},
                {id:4,land_name:"地块四"},
            ],
            land_id:1,

            variety:{
                list:[
                    {id:1,name:'脐橙，火龙果',count:1000,create_time:'2021-02-23'},
                    {id:2,name:'脐橙，火龙果1',count:999,create_time:'2021-02-23'},
                    {id:3,name:'脐橙，火龙果2',count:888,create_time:'2021-02-23'},
                    {id:3,name:'脐橙，火龙果2',count:888,create_time:'2021-02-23'},
                    {id:3,name:'脐橙，火龙果2',count:888,create_time:'2021-02-23'},
                    {id:3,name:'脐橙，火龙果2',count:888,create_time:'2021-02-23'},
                    {id:3,name:'脐橙，火龙果2',count:888,create_time:'2021-02-23'},
                    {id:3,name:'脐橙，火龙果2',count:888,create_time:'2021-02-23'},
                    {id:3,name:'脐橙，火龙果2',count:888,create_time:'2021-02-23'},
                    {id:3,name:'脐橙，火龙果2',count:888,create_time:'2021-02-23'},
                ],
                page:1,
                limit:10,
                count:0,
            },
            varietyForm:{
                type:"",
                name:"",
                count:"",
                create_time:"",
            },
            farming:{
                list:[
                    {id:1,name:'除草施肥1',create_time:'2021-02-23',status:0},
                    {id:2,name:'除草施肥1',create_time:'2021-02-23',status:1},
                    {id:3,name:'除草施肥1',create_time:'2021-02-23',status:2},
                ],
                page:1,
                limit:10,
                count:0
            },
            farmCert:{
                list:[
                    {id:1,type:'化肥',name:'氮肥',land_name:'脐橙',count:1,create_time:'2021-02-23'},
                    {id:1,type:'化肥',name:'氮肥',land_name:'脐橙',count:1,create_time:'2021-02-23'},
                    {id:1,type:'化肥',name:'氮肥',land_name:'脐橙',count:1,create_time:'2021-02-23'},
                    {id:1,type:'化肥',name:'氮肥',land_name:'脐橙',count:1,create_time:'2021-02-23'},
                    {id:1,type:'化肥',name:'氮肥',land_name:'脐橙',count:1,create_time:'2021-02-23'},
                    {id:1,type:'化肥',name:'氮肥',land_name:'脐橙',count:1,create_time:'2021-02-23'},
                    {id:1,type:'化肥',name:'氮肥',land_name:'脐橙',count:1,create_time:'2021-02-23'},
                    {id:1,type:'化肥',name:'氮肥',land_name:'脐橙',count:1,create_time:'2021-02-23'},
                    {id:1,type:'化肥',name:'氮肥',land_name:'脐橙',count:1,create_time:'2021-02-23'},
                    {id:1,type:'化肥',name:'氮肥',land_name:'脐橙',count:1,create_time:'2021-02-23'},
                ],
                page:1,
                limit:10,
                count:0
            },
            trace:{
                list:[
                    {id:1,name:'2021-脐橙0',code:'#123456',land_name:'脐橙',count:1,create_time:'2021-02-23'},
                    {id:1,name:'2021-脐橙0',code:'#123456',land_name:'脐橙',count:1,create_time:'2021-02-23'},
                    {id:1,name:'2021-脐橙0',code:'#123456',land_name:'脐橙',count:1,create_time:'2021-02-23'},
                    {id:1,name:'2021-脐橙0',code:'#123456',land_name:'脐橙',count:1,create_time:'2021-02-23'},
                    {id:1,name:'2021-脐橙0',code:'#123456',land_name:'脐橙',count:1,create_time:'2021-02-23'},
                    {id:1,name:'2021-脐橙0',code:'#123456',land_name:'脐橙',count:1,create_time:'2021-02-23'},
                    {id:1,name:'2021-脐橙0',code:'#123456',land_name:'脐橙',count:1,create_time:'2021-02-23'},
                    {id:1,name:'2021-脐橙0',code:'#123456',land_name:'脐橙',count:1,create_time:'2021-02-23'},
                    {id:1,name:'2021-脐橙0',code:'#123456',land_name:'脐橙',count:1,create_time:'2021-02-23'},
                    {id:1,name:'2021-脐橙0',code:'#123456',land_name:'脐橙',count:1,create_time:'2021-02-23'},
                ],
                page:1,
                limit:10,
                count:0
            }
        })

        return{
            ...toRefs(state)
        }
    },
}
</script>
<style scoped lang="scss">
.land-info{
    overflow: hidden;
    overflow-y: auto;
    .title{
        font-weight: bold;
        font-size: 14px;
        margin-right: 12px;
    }

    .area,.output{
        margin-left: 12px;
    }

    .block-title{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;
    }

    .li-live{
        list-style: none;
        margin: 0;
        padding: 0;

        li{
            display: inline-block;
            width: 240px;
            height: 150px;
            margin-right: 12px;
            margin-bottom: 12px;
            border: 1px solid;
            position: relative;
            cursor: pointer;

            img{
                width: 100%;
                height: 100%;
            }
            .name{
                position: absolute;
                width: 100%;
                background: rgba($color: #000000, $alpha: .5);
                color: #fff;
                height: 24px;
                line-height: 24px;
                margin-top: -24px;
                padding: 0 6px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .play{
                position: absolute;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                border: 2px solid #fff;
                text-align: center;
                line-height: 40px;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                color: #fff;
                

                i{
                    font-size: 22px;
                }
            }
        }
    }

    .li-device{
        list-style: none;
        margin: 0;
        padding: 0;

        li{
            display: inline-block;
            width: 240px;
            height: 150px;
            text-align: center;
            border: 1px solid #f4f4f4;
            margin: 0 12px 12px 0;
            box-sizing: border-box;
            padding: 45px 0;

            .num{
                font-size: 22px;
                font-weight: bold;
            }
        }
    }

    .li-record{
        .time{
            font-weight: bold;
        }
        .img{
            display: flex;
            flex-wrap: wrap;

            &-li{
                width: 64px;
                height: 64px;
                margin: 0 12px 12px 0;
            }
        }
    }


    
}
</style>