<template>
	<!-- 平台资金总览 -->
	<div class="platform-fund-view">
		<div class="pfv-money mb12">
			<div class="pfv-money-item">
				<div>会员已提现金额（元）</div>
				<div><span v-if="total">{{total.accountWithdraw}}</span></div>
			</div>
			<div class="pfv-money-item">
				<div>商家已提现金额（元）</div>
				<div><span v-if="total">{{total.shopWithDraw}}</span></div>
			</div>
			<div class="pfv-money-item">
				<div>总会员未提现金额（元）</div>
				<div><span v-if="total">{{total.accoutPrice}}</span></div>
			</div>
			<div class="pfv-money-item">
				<div>总商家未提现金额（元）</div>
				<div><span v-if="total">{{total.ShopPrice}}</span></div>
			</div>
			<div class="pfv-money-item">
				<div>总会员冻结金额（元）</div>
				<div><span v-if="total">{{total.accountFrozen}}</span></div>
			</div>
			<div class="pfv-money-item">
				<div>总商家冻结金额（元）</div>
				<div><span v-if="total">{{total.ShopFrozen}}</span></div>
			</div>
			
		</div>
		<div class="el-content">
			<a-space class="mb12">
				<span style="margin-right: 4px;">收支情况</span>
				<a-radio-group v-model:value="type" button-style="solid" @change="getTradeCountToDayList(1,info.limit)">
					<a-radio-button :value="1">本月</a-radio-button>
					<a-radio-button :value="0">上月</a-radio-button>
				</a-radio-group>
				<a-button 
					style="padding: 6px 15px;position: relative;top: 1px;" 
					type="primary" 
					@click="downloadBill">账单下载
				</a-button>
			</a-space>
			<a-table :pagination="false" row-key="id" :data-source="info.list" :columns="[
				{title:'日期',dataIndex:'date'},
				{title:'收入总金额',dataIndex:'amount_in',slots:{customRender:'amount_in'}},
				{title:'支出总金额',dataIndex:'amount_out',slots:{customRender:'amount_out'}},
				{title:'总收入笔数',dataIndex:'total_in',slots:{customRender:'total_in'}},
				{title:'总支出笔数',dataIndex:'total_out',slots:{customRender:'total_out'}},
			]" >
			<template #amount_in="{record}">
				<span style="color:#00CC66">{{ record.amount_in || 0}}</span>
			</template>
			<template #amount_out="{record}">
				<span style="color:#FF0066">{{ record.amount_out || 0}}</span>
			</template>
			<template #total_in="{record}">
				<span style="color:#00CC66">{{ record.total_in || 0}}</span>
			</template>
			<template #total_out="{record}">
				<span style="color:#FF0066">{{ record.total_out || 0}}</span>
			</template>
			</a-table>
				
			<div class="pager">
				<a-pagination
					show-size-changer
					:default-current="info.page"
					:total="info.count"
					@showSizeChange="(p,e)=>{getTradeCountToDayList(info.page,e)}"
					@change="(e)=>{getTradeCountToDayList(e,info.limit)}"
				/>
			</div>
		</div>
		
	</div>
</template>

<script>
import siteinfo from '@/util/global.js'
import userModel from '@/api/user.js'
import { reactive, toRefs } from 'vue'
export default{
	setup(){
		const _d = reactive({
			money:null,
			total:null,
			type:1,
			info:{
				list:[],
				page:1,
				limit:10,
				count:0
			},
		})

		userModel.getProgramMoney(res=>{
			_d.money = res
			getTradeCountToDayList(1,_d.info.limit)
		})
		userModel.getFundOverview(res=>_d.total = res)

		function getTradeCountToDayList(page,limit){
			userModel.getTradeCountToDayList(page,limit,{page,limit,gold_id:_d.money.id,type:_d.type},res=>{
				_d.info = {limit,...res}
			})
		}
		function downloadBill(){
			let url = siteinfo.siteinfo.siteroot+'/api/admin/downloadTradeCount?token='+localStorage.getItem('token');
			window.open(url);
		}
		return{
			...toRefs(_d),
			downloadBill,
			getTradeCountToDayList
		}
	}
}
</script>

<style lang="scss">
.pfv-money{
	width: 100%;
	height: 120px;
	background: #fff;
	display: flex;
	
	&-item{
		width: 400px;
		padding-top: 24px;
		
		div:first-child{
			color: #999;
			font-size: 14px;
		}
		span{
			font-size: 28px;
		}
	}
	
	&-item:first-child{
		margin-left: 48px;
	}
}
</style>
