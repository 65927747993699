<template>
    <div class="digital-farm-index el-content">
        <div class="df-info">
            <div class="df-info-top">
                <div class="df-title">信息统计</div>
                <ul class="dfit-con">
                    <li>
                        <div class="num">32</div>
                        <div class="desc">地块数量</div>
                    </li>
                    <li>
                        <div class="num">8880 ㎡</div>
                        <div class="desc">地块面积</div>
                    </li>
                    <li>
                        <div class="num">88</div>
                        <div class="desc">待办任务</div>
                    </li>
                </ul>
            </div>

            <div class="df-info-evr">
                <div class="df-title">环境数据</div>
                <div class="dfie-con">
                    <div class="dfie-item">
                        <div class="desc">日照强度</div>
                        <div class="val"><span>32</span> Lux</div>
                    </div>
                    <div class="dfie-item">
                        <div class="desc">日照强度</div>
                        <div class="val"><span>32</span> Lux</div>
                    </div>
                    <div class="dfie-item">
                        <div class="desc">日照强度</div>
                        <div class="val"><span>32</span> Lux</div>
                    </div>
                    <div class="dfie-item">
                        <div class="desc">日照强度</div>
                        <div class="val"><span>32</span> Lux</div>
                    </div>
                </div>

                <div class="dfie-add" @click="show.add = true"><i class="ri-add-line"></i>添加地块</div>
            </div>
        </div>
        <div class="dfmap" id="dfmap" style="width:100%; height:700px" ></div>
        <a-modal v-model:visible="show.add" title="添加地块" @ok="sureAddLand" width="700px">
            <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
				<a-form-item label="标记颜色">
					<kd-color v-model="lumpForm.color"></kd-color>
				</a-form-item>
				<a-form-item label="标记区域" >
					<a-button type="primary" @click="drawMap"><i class="ri-add-line"></i>添加区域</a-button>
                    <span v-if="lumpForm.area">已标记区域约 {{lumpForm.area.area }} 平方米</span>
				</a-form-item>
                <a-form-item label="土地名称" >
					<a-input v-model:value="lumpForm.land_name"></a-input>
				</a-form-item>
                <a-form-item label="核心产物" >
					<a-input v-model:value="lumpForm.output"></a-input>
				</a-form-item>
			</a-form>
        </a-modal>

        <a-modal v-model:visible="show.map" title="选择区域" :footer="null" width="1000px">
			<kd-map-earth :map-height="600" draw-type="polygon" @getMapData="getMapData"></kd-map-earth>
		</a-modal>
    </div>    
</template>
<script>
import kdMapEarth from "@/components/public/kd-map-earth.vue"
import { nextTick, reactive, toRefs } from 'vue'
import GdMap from '@/util/GdMap'
export default {
    components:{
        kdMapEarth
    },
    setup() {
        const state = reactive({
            mapObj:null,
            keyword:'',
            addressList:[],
            show:{
                searchAdd:false,
                add:false,
                map:false,
            },
            lumpForm:{
                color:"",
                area:null,
                land_name:"",
                output:"",
            },

            land:[
                {area: "22279.51",
                color:'#e2534d',
                output:"脐橙，火龙果,百香果",
                land_name:"地块A",
                point:[
                        [106.487801, 29.623031],
                        [106.487823, 29.621688],
                        [106.489217, 29.621697],
                        [106.489132, 29.623376],
                    ]
                },{
                    land_name:"地块B",
                    area: "26285.16",
                    color:'#00af57',
                    output:"大西瓜，小西瓜，一串葡萄牙",
                    point:[
                        [106.491395, 29.623404],
                        [106.491556, 29.621977],
                        [106.489947, 29.621855],
                        [106.489861, 29.623534],
                    ]
                     
                }
            ],
        })

        ;(function(){
            let map = new GdMap({el:'dfmap',drawType:'location'})
            nextTick(()=>{
                map.initMap(true)

                state.land.forEach(item=>{
                    map.drawPolyon(res=>{},item.point,false,item.color,item.color,createWindowInfo(item))
                })
            })
            state.mapObj = map
        })();

        function drawMap(){
			state.show.map = true
		}

		function getMapData(e){
			console.log('绘制完成',e);
            state.lumpForm.area = e
            state.show.map = false
		}

        function sureAddLand(){
            state.show.add = false
            state.land.push(state.lumpForm)
            state.mapObj.drawPolyon(res=>{},state.lumpForm.area.point,false,state.lumpForm.color,state.lumpForm.color)
        }

        function createWindowInfo(data){
            /**
             *  <div class="df-custom">
                    <div class="df-custom-top">
                        <div class="color"></div>
                        <div class="name">地块名称</div>   
                    </div>
                    <div class="area">1500平方米</div>
                    <div class="output">脐橙、橙子、火龙果、西瓜</div>

                    <div class="footer">
                        <div class="footer-item">查看数据</div>
                        <div class="footer-item">地块资料</div>
                    </div>
                </div>
            * 
            */

            let info = document.createElement("div")
            info.style=`width: 220px; 
                        height: 124px; 
                        background: rgba(0,0,0,.7); 
                        z-index: 99; 
                        border-radius: 6px; 
                        padding-top: 12px ; 
                        color: #fff;cursor:pointer;`

            let close = document.createElement("i")
            close.className="ri-close-line"
            close.style=`position: absolute;
                         right: 9px;
                         cursor: pointer;`
            close.onclick=closeWindowInfo

            let top = document.createElement("div")
            top.style=` display: flex;
                        align-items: center;
                        padding: 0 12px;`

            let colorBlock = document.createElement("div")
            colorBlock.style=`  width: 16px;
                                height: 16px;
                                background: ${data.color};
                                margin-right: 6px;`

            let name = document.createElement("div")
            name.innerHTML=data.land_name

            top.appendChild(close)
            top.appendChild(colorBlock)
            top.appendChild(name)

            let area = document.createElement("div")
            area.style=`font-size: 12px;
                        line-height: 16px;
                        padding: 0 12px;`
            area.innerHTML=data.area+"平方米"

            let output = document.createElement("div")
            output.style=`font-size: 12px;
                        line-height: 16px;
                        padding: 0 12px;`
            output.innerHTML=data.output+"脐橙、橙子、火龙果、西瓜"

            //底部
            let footer = document.createElement("div")
            footer.style=`  width: 100%;
                            height: 32px;
                            display: flex;
                            align-items: center;
                            border-top: 1px solid #9e9e9e;
                            margin-top: 12px;`
            let footerItem1 = document.createElement("div")
            footerItem1.innerHTML="查看数据"
            footerItem1.style=` width: 50%;
                                text-align: center;
                                height: 32px;
                                line-height: 32px;
                                cursor: pointer;border-right: 1px solid #9e9e9e;`
            let footerItem2 = document.createElement("div")
            footerItem2.innerHTML="地块资料"
            footerItem2.style=` width: 50%;
                                text-align: center;
                                height: 32px;
                                line-height: 32px;
                                cursor: pointer;`
            footer.appendChild(footerItem1)
            footer.appendChild(footerItem2)
            info.appendChild(top)
            info.appendChild(area)
            info.appendChild(output)
            info.appendChild(footer)

            return info
        }

        function closeWindowInfo(){
            state.mapObj.closeWindowInfo()
        }

        
        return{
            ...toRefs(state),
            drawMap,
            getMapData,
            sureAddLand,
        }
    },
}
</script>
<style lang="scss" scoped>
.dfmap{
    border: 1px solid;
    height: 700px;
}

.df-info{
    position: absolute;
    width: 300px;
    z-index: 99;
    margin: 12px 0 0 12px;
    border-radius: 6px;
    right: 24px;
    
    &-title{
        color: #fff;
        font-weight: bold;
        font-size: 16px;
    }

    &-top{
        width: 100%;
        height: 120px;
        background: rgba($color: #000000, $alpha: .7);
        color: #fff;
        box-sizing: border-box;
        padding: 12px;
        border-radius: 6px;
    }

    &-evr{
        width: 100%;
        background: rgba($color: #000000, $alpha: .7);
        color: #fff;
        box-sizing: border-box;
        padding: 12px;
        margin-top: 12px;
        border-radius: 6px;
    }


    .dfit-con{
        list-style: none;
        margin: 12px 0 0 0;
        padding: 0;

        li{
            display: inline-block;
            width: 33%;
            text-align: center;

            .num{
                font-size: 18px;
                font-weight: bold;
            }
            .desc{
                font-size: 12px;
                color: #bdbdbd;
                margin-top: 6px;
            }
        }
    }

    .dfie-con{
        display: flex;
        flex-wrap: wrap;

        .dfie-item{
            width: 50%;
            height: 50px;
        }
        .desc{
            font-size: 12px;
            color: #bdbdbd;
            margin-top: 6px;
        }
        .val{
            span{
                font-size: 16px;
                font-weight: bold;
            }
        }
    }

    .dfie-add{
        width: 100%;
        height: 32px;
        text-align: center;
        background: #2177f8;
        line-height: 32px;
        cursor: pointer;
        margin-top: 12px;
        border-radius: 6px;
    }
}




</style>