<template>
    <div class="com-select-gbdevice">
        <a-button :type="btnType" @click="showDevice">[设置国标设备]</a-button>
        <a-button :type="btnType" v-if="order.device_id" @click="play = true">[播放]</a-button>
        <a-modal v-model:visible="show" title="设置国标设备" width="600px" @ok="saveDeviceSet" @cancel="show = false">
			<a-form :label-col="{span:4}" :wrapper-col="{span:12}">
                <a-form-item label="选择设备">
                    <a-select v-model:value="device_index" placeholder="请选择设备" @change="getChannel">
                        <a-select-option :value="0">请选择设备</a-select-option>
                        <a-select-option v-for="( item,index) in list" :key="index" :value="index">
                            {{item.desc}}
                        </a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="选择设备通道" v-if="channel && channel.length > 0" >
                    <a-select v-model:value="channel_gbid" placeholder="选择设备通道" @change="getPreset">
                        <a-select-option :value="0">选择预置位</a-select-option>
                        <a-select-option v-for="( item,index) in channel" :key="index" :value="item.gbId">
                            {{item.name}}
                        </a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="选择预置位" v-if="preset && preset.length > 0">
                    <a-select v-model:value="preset_id" placeholder="选择预置位">
                        <a-select-option :value="0">选择预置位</a-select-option>
                        <a-select-option v-for="( item,index) in preset" :key="index" :value="item.id">
                            {{item.name}}
                        </a-select-option>
                    </a-select>
                </a-form-item>
            </a-form>
		</a-modal>

        <a-modal v-model:visible="play" title="播放" width="640px" :footer="null" @cancel="stopPlay">
            <com-play-gb
                ref="playRef" 
                :device-id="order.device_id" 
                :channle-id="order.channel_gbid">
            </com-play-gb>
        </a-modal>
    </div>
</template>
<script>
import { reactive, toRefs } from 'vue'
import liveModel from '@/api/live'
import commonModel from '@/api/common'
import comPlayGb from '@/components/layout/live/com-play-gb.vue'
export default {
    name:'com-select-gbdevice',
    components:{
        comPlayGb
    },
    props:{
        btnType:{
            type:String,
            default:"link"
        },
        setType:{   //设置类型 3=认养订单  4土地订单
            type:Number
        },
        order:{   //订单id
            type:Object,
            default:0
        }
    },
    setup(props) {
        const state = reactive({
            show:false,
            play:false,
            
            list:[],
            preset:[],  //预置位
            channel:[], //通道

            device_index:null,  //选择的设备下标
            device_id:null, //设备id
            channel_gbid:null,  //通道ID
            preset_id:null,      //预置位id

            playRef:null,
        })

        function showDevice(){
            state.show = true
            liveModel.getGbDevice(1,99,null,res=>state.list = res.list)
        }

        //获取预置位
        function getPreset(channel_id){
            liveModel.getPrePosition(state.device_id,channel_id).then(res=>state.preset = res.items)
        }

        const getChannel = e=>{
            state.preset = []
            state.channel = []
            let cur = state.list[e]
            state.device_id = cur.id
            
            if( cur.type == 1 ){    //摄像头类型 仅有预置位
                getPreset(null)
                return
            }

            if( cur.type == 2 ){  //平台类型 有通道
                liveModel.getChannel(cur.id).then(res=>state.channel = res.items)
                return
            }
        }

        function saveDeviceSet(){
            let { device_id ,channel_gbid ,preset_id } = state
            let data = { 
                order_id:props.order.id,
                order_type:props.setType,
                device_id,
                preset_id,
                channel_gbid
            }
            commonModel.setOrderDevice(data,()=> state.show = false )
        }

        function stopPlay(){
            state.playRef.pullFlow('stop')
            state.play = false
        }

        return{
            ...toRefs(state),
            showDevice,
            getChannel,
            getPreset,
            saveDeviceSet,
            stopPlay
        }
    },
}
</script>
<style lang="scss" scoped>
.com-select-gbdevice{
    display: inline-block;
}
</style>
