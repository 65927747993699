<template>
	<div id="wxappList">
		<div class="el-content mb12">
			<a-space>
				<a-input-search v-model:value="search.key" 
					placeholder="应用名称、用户昵称" 
					enter-button 
					style="width:360px;"
					@search="getProgramList(1)" 
				/>
				<router-link to="/admin/app/app_edit" v-has="{ action: '/admin/app/app_edit' }">
					<a-button type="primary">
						<i class="ri-add-line"></i>
						添加应用
					</a-button>
				</router-link>
			</a-space>
		</div>

		<div class="el-content">
			<a-tabs v-model:activeKey="search.type" @change="getProgramList(1, info.limit)">
				<a-tab-pane key="1" tab="我的应用"></a-tab-pane>
				<a-tab-pane key="3" tab="回收站"></a-tab-pane>
			</a-tabs>
			<app-list :list="info.list" 
				:pageCount="info.count" 
				:type="search.type" 
				@changeList="getProgramList(currentPage)">
			</app-list>
			<div class="pager">
				<a-pagination
					show-size-changer
					:default-current="info.page"
					:total="info.count"
					@showSizeChange=" (page, e) => { getProgramList(info.page, e); } "
					@change=" e => { getProgramList(e, info.limit); } "
				/>
			</div>
		</div>
	</div>
</template>

<script>
import appList from '@/components/admin/appList.vue';
import { reactive, toRefs } from 'vue';
import programModel from '@/api/saas/app';
export default {
	components: {
		appList
	},
	setup(){
		const state = reactive({
			//search.type 1我的小程序  2子用户小程序
			search:{type:1,key:''},
			key: '',
			info: {
				list: [],
				page: 1,
				limit: 10,
				count: 0
			},
		})

		let width = document.body.clientWidth - 300
		let limit = Math.floor(width / 300) * 3
		state.info.limit = limit
		getProgramList(1,state.info.limit)

		function getProgramList(page,limit){
			programModel.getProgramList(state.search.type,page,limit,state.search,res=>state.info = {limit,...res})
		}

		return{
			...toRefs(state),
			getProgramList
		}
	}
}
</script>

<style lang="scss">
.remove-app {
	&-seach {
		width: 100%;
		height: 50px;

		&-input {
			width: 400px;
		}
	}
	&-user {
		width: 100%;
		display: flex;
		flex-wrap: wrap;

		&-lo {
			width: 210px;
			height: 40px;
			display: flex;
			border: 1px solid #f1f1f1;
			padding: 10px 0 10px 10px;
			margin-bottom: 10px;
			cursor: pointer;
			margin-right: 10px;

			.lo-pic {
				width: 40px;
				height: 40px;
				border-radius: 50%;
				margin-right: 10px;
			}

			&-info {
				width: 80%;
				height: 100%;
			}
		}

		.li-active-border {
			border: 1px solid red;
		}
		.lo-active {
			position: absolute;
			margin-left: 176px;
			margin-top: 17px;
		}
	}
}

.app-row {
	display: flex;
	flex-wrap: wrap;
	margin-top: 20px;

	.none-app {
		width: 100%;
		color: #868686;
		font-size: 14px;
		text-align: center;
		line-height: 40px;
	}
}
.app-search-input {
	width: 350px !important;
}

.zyl-row-li {
	background: #ffffff;
	padding: 20px;
	height: 275px;
	width: 210px;
	border: 1px solid #e6e6e6;
	margin-right: 20px;
	cursor: pointer;
	margin-bottom: 20px;

	.kd-try-tag {
		width: 45px;
		height: 17px;
		position: absolute;
		font-size: 12px;
		background: #e66a4d;
		color: #ffffff;
		margin-left: -20px;
		margin-top: -20px;
		text-align: center;
	}
	.program_name {
		height: 40px;
		line-height: 40px;
		font-size: 18px;
	}
	.logo {
		width: 100px;
		height: 100px;
		border-radius: 5px;
	}
	.user,
	.status,
	.time {
		height: 30px;
		line-height: 30px;
		color: #868686;
		font-size: 13px;
	}
	.program-manage {
		width: 100%;
		height: 30px;
		line-height: 9px;
		margin-top: 10px;
	}
	.program_version {
		position: absolute;
		margin: -60px 0 0 170px;
	}
	.menu-down {
		position: absolute !important;
		margin-left: 190px;
		font-size: 20px;
	}
}
.zyl-row-li:hover {
	border: 1px solid #409eff;
}
.zyl-row-li:hover .program_name {
	color: #409eff;
}
</style>
