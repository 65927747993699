<template>
	<!-- 应用管理层header -->
	<div id="platformHeader">
		<a-layout style="position: fixed;height: 100%;width: 100%;">
			<a-layout-sider class="menu-collapsed kd-menu" :width="is_show_two_menu ? 240 : 130">
				<!-- 一级菜单 -->
				<div class="kd-platform-header left-menu-1">
					<div class="kph-app" v-if="wxappInfo">
						<img :src="wxappInfo.logo" class="wxapp-logo" />
						<div v-if="!isCollapse">{{ wxappInfo.program_name }}</div>
					</div>
					<div
						class="p-left-menu"
						v-for="(item, index) in list"
						:class="$store.state.miniNav && $store.state.miniNav.first == item.path ? 'active' : ''"
						:key="index"
						@click="navChangeLevelOne(index)"
					>
						<i :class="item.menu_icon"></i>
						<div class="pl-menu-item">{{ item.title }}</div>
					</div>
				</div>

				<!-- 二级菜单 -->
				<template v-if="is_show_two_menu">
					<div
						class="left-menu-2"
						v-for="(item, index) in list"
						:class="item.path == $store.state.miniNav.first ? 'left-menu-2-show' : ''"
						:key="index"
					>
						<!-- 顶部一级导航标题 -->
						<div class="lm-title">{{ item.title }}</div>

						<div class="lm-item" v-for="(child, ind) in item.children" :key="ind">
							<template v-if="child.children.length > 0">
								<div class="lm-item-title">{{ child.title }}</div>
								<div class="lm-item-ul">
									<div
										@click="navChangeLevelTwo(v)"
										class="lm-item-ul-title"
										v-for="(v, v_ind) in child.children"
										:key="v_ind"
										:class="$store.state.miniNav.second == v.path ? 'active' : ''"
									>
										{{ v.title }}
									</div>
								</div>
							</template>

							<template v-else>
								<div class="lm-item-ul" @click="navChangeLevelTwo(child)">
									<div class="lm-item-ul-title" :class="$store.state.miniNav.second == child.path ? 'active' : ''">{{ child.title }}</div>
								</div>
							</template>
						</div>
					</div>
				</template>
			</a-layout-sider>
			<a-layout>
				<a-layout-header style="background: #fff;">
					<div class="main-header">
						<div class="main-header-left">
							<!-- div占位 -->
							<div :style="{ width: is_show_two_menu ? '236px' : '126px' }"></div>
							<!-- 版本 -->
							<div class="kdpm-server" v-if="wxappInfo && wxappInfo.server">
								<i class="ri-vip-diamond-fill"></i>
								<span>{{ wxappInfo.server.server_name }}</span>
							</div>

                            <div class="kdpm-try-server" v-if="wxappInfo && wxappInfo.is_trail == 1">试用版</div>

							<div class="kdpm-expire_time">
								<span v-if="wxappInfo">到期时间：{{ wxappInfo.end_time }}</span>
							</div>
							<i class="ri-refresh-line ri-lg" @click="refreshPage"></i>
						</div>
						<div class="main-header-right">
							<!-- H5链接入口 -->
							<a-popover title="H5入口">
									<template #content>
										<a-image :src="entrance.app_qr_code" v-if="entrance.app_qr_code"/>
										<a-input v-model:value="entrance.app_url" v-if="entrance.app_url">
											<template #addonAfter>
												<com-copy :value="entrance.app_url"></com-copy>
											</template>
										</a-input>
										<div style="color: #999;font-size: 12px;">未配置H5展示域名</div>
									</template>
							    <span>H5入口</span>
							</a-popover>
							<a-popover title="官网入口">
							    <template #content>
									<div class="mh-top-nav-item" style="min-width: 100px;">
										<a-image :src="entrance.pc_url" v-if="entrance.pc_url"></a-image>
										<a-input v-model:value="entrance.pc_url" v-if="entrance.pc_url">
											<template #addonAfter>
												<com-copy :value="entrance.pc_url"></com-copy>
											</template>
										</a-input>
										<div style="color: #999;font-size: 12px;">未配置官网展示域名</div>
									</div>
							    </template>
								<template v-if="$store.state.modulesPerm.web_site==1">
									<a-divider type="vertical"/>
							    	<span class="mhr-btn">官网入口</span>
								</template>

							</a-popover>

							<!-- 消息通知 -->
							<a-badge dot>
								<a-divider type="vertical" />
								<span :count="drawerData.read" class="mhr-btn" @click="showDrawer">消息通知</span>
							</a-badge>

							<!-- 更新缓存 -->
							<a-divider type="vertical" />
							<span class="mhr-btn" @click="clearCache">更新缓存</span>
							<a-divider type="vertical" />
							<a-dropdown>
							    <span>{{ $store.state.username}}<i class="ri-arrow-down-s-fill ri-top"></i></span>
							    <template #overlay>
									<a-menu>
										<a-menu-item @click="handleCommand('backSystem')" v-if="navType == 2">返回系统</a-menu-item>
										<a-menu-item @click="handleCommand('myInfo')">我的账号</a-menu-item>
										<a-menu-item @click="handleCommand('myAddress')" v-if="navType == 3">收货地址</a-menu-item>
										<a-menu-item @click="handleCommand('logOut')">退出</a-menu-item>
									</a-menu>
							    </template>
							</a-dropdown>
						</div>
					</div>
				</a-layout-header>
				<a-layout-content style="padding: 16px;">
                    <div class="kd-try-pancel el-content mt12" v-if="wxappInfo && wxappInfo.is_trail == 1">
                        店铺试用仅剩{{ programEndDay }}天，到期后店铺功能将不可用，建议尽快购买正式版本！
                        <router-link :to="{path:'/plugin/program-renew',query:{id:wxappInfo.program_id,is_trail:wxappInfo.is_trail}}">
                            <a-button type="primary" style="background:#FF6600;border:none;">立即购买</a-button>
                        </router-link>
                    </div>

                    <div class="kd-free-version el-content mt12"
                        v-if="wxappInfo && wxappInfo.server && wxappInfo.server.price == 0 && wxappInfo.server.discount_price == 0 && wxappInfo.is_trail != 1">

                        您当前的版本为 <div class="free-tag">免费版</div>  ，若想尝试更多功能，可升级更高版本！
                        <router-link :to="{
                                path:'/plugin/program-upgrade',
                                query:{id:wxappInfo.program_id,is_trail:wxappInfo.is_trail}
                            }"
                        >
                            <a-button type="primary" style="background:#FF6600;border:none;">立即升级</a-button>
                        </router-link>
                    </div>

					<a-spin :spinning="$store.state.loading">
						<router-view></router-view>
					</a-spin>

				</a-layout-content>
			</a-layout>
		</a-layout>

		<a-drawer placement="right" :closable="false" v-model:visible="show.drawer" width="500px">
			<template #title>
				<span>我的消息</span>
				<span style="font-size: 12px;cursor: pointer;" @click="readAll">全部已读</span>
			</template>
			<div class="infinite-list" style="overflow:auto">
				<div v-if="drawerData.list.length>0">
				<div class="kpd-item" v-for="(item,index) in drawerData.list" :key="index" @click="messageToDetail(item)">
					   <div>
						   <div class="kpdi-content">{{item.title}}</div>
						   <div class="kpdi-time">{{item.create_time}}</div>
					   </div>
					</div>
				</div>
				<div v-else>
					<span style="margin-left: 5%;font-size: 12px;color: #999;">暂时没有任何消息通知</span>
				</div>
		    </div>
			<div class="pager">
				<a-pagination
					show-size-changer
					:default-current="drawerData.page"
					:total="drawerData.count"
					:pageSize="drawerData.limit"
					@showSizeChange="(p,e)=>{getMessage(drawerData.page,e)}"
					@change="(e)=>{getMessage(e,drawerData.limit)}"
				/>
			</div>
		</a-drawer>
	</div>
</template>

<script>
import comCopy from "@/components/public/com-copy.vue"
import { reactive, toRefs, computed,inject} from 'vue';
import programModel from '@/api/saas/app';
import router from '@/router'
import RouteHelper from '@/router/routeHelper.js'
import commonModel from '@/api/common';
import login from '@/api/login';
import store from '@/store'
const rhModel = new RouteHelper()

export default {
	name: 'platform-header',
	components:{
		comCopy
	},
	setup(){
		const state = reactive({
			list: [], //菜单
			isCollapse: false,
			navType: 2,
			is_show_two_menu: false, //是否显示二级菜单
			show: {
				drawer: false
			},
			drawerData: {
				list: [],
				page: 0,
				limit:10,
				count:0
			},
			entrance: {
				//客户端入口地址
				app_url: '',
				pc_url: '',
				app_qr_code: '',
				pc_qr_code: ''
			},
			wxappInfo:null,
            programEndDay:0,
		})

		//页面初始化
		;(function initLoad(){
			let program_id = localStorage.getItem("program_id")
			programModel.getProgramDetail(program_id,res=>{
				state.wxappInfo = res
				sessionStorage.setItem('__PROGRAMAPP__',JSON.stringify( res))

                var  startDate = Date.parse( new Date() );
                var  endDate = Date.parse(res.end_time);
                var days= Math.ceil( (endDate - startDate)/(1*24*60*60*1000) ) ;
                state.programEndDay = days
			})

			//获取菜单
			rhModel.getMenu('farm_admin').then(res=>{
				state.list = res

				//刷新页面后重新获取缓存中的导航选中信息
				let nav = localStorage.getItem("miniapp_nav_key")
				if( nav ){
					let nav_current = JSON.parse(nav)
					store.dispatch('setMiniNav', nav_current);
					if (nav_current.second != '') {
						state.is_show_two_menu = true;
					}
				}
			})
			getMessage()
			//h5/pc预览信息
			programModel.getProgramUrl(res=>state.entrance = res)

		}())

		//页面刷新
		const onRefresh = inject('reload')
		const refreshPage = () => {
			onRefresh && onRefresh()
		}


		const menuUnfold = computed(()=>{
			return `margin-left:${state.is_show_two_menu ? 240 : 130}px`;
		})
		const infiniteHeight = computed(()=>{
			return `margin-left:${state.drawerData.height ? 240 : 130}px`;
		})
		const clearCache = ()=>commonModel.clearCache()

		function handleCommand(command){
			if( command == 'logOut' ) {
				login.logout()
				return
			}

			if( command == 'backSystem' ){
				let userInfo = localStorage.getItem("kdFarmUserInfo")
				if( userInfo ){
					userInfo = JSON.parse(userInfo)
					if(userInfo.rank !=1 ){
						router.push('/shop/index');
						return
					}
				}
				router.push('/admin/index')
				return
			}

			if (command == 'myInfo') {
				if (state.navType == 3) {
					router.push('/many_store/public/myInfo')
					return
				}
				router.push('/admin/user_detail')
				return
			}

			if (command == 'myAddress') {
				router.push('/many_store/buy/address')
				return
			}
		}

		/**
		 * 一级导航栏切换
		 * @param {Number} index  导航栏下标
		 */
		function navChangeLevelOne(index) {
			let _d = state.list[index],path = _d.path ,nav = null;
			//判断该一级导航栏下面是否存在二级导航栏
			//localStorage.getItem("sass_nav_key")
			if (_d.children && _d.children.length > 0) {
				path = _d.children[0].path;
				nav = { first: _d.path, second: path, third: '' }
				//存在二级导航， 再判断是否存在三级导航
				if (_d.children[0].children && _d.children[0].children.length > 0) {
					path = _d.children[0].children[0].path;
					nav = { first: _d.path, second: path, third: path }
				}
				state.is_show_two_menu = true;
			} else {
				nav = { first: _d.path, second: '', third: '' }
				state.is_show_two_menu = false;
			}
			store.dispatch('setMiniNav', nav)
			localStorage.setItem("miniapp_nav_key",JSON.stringify(nav))
			router.push(path)
		}


		/**
		 * 二级菜单切换
		 * @param {Object} data		菜单数据
		 */
		function navChangeLevelTwo(data) {
			let { first } = store.state.miniNav;
			let nav = { first: first, second: data.path, third: '' }
			store.dispatch('setMiniNav', nav);
			localStorage.setItem("miniapp_nav_key",JSON.stringify(nav))

            console.log('当前导航：',nav);
            router.push(data.path);
		}

		function showDrawer() {
			state.show.drawer = true;
			state.drawerData.page = 0;
			state.drawerData.list = [];
			getMessage(1,state.drawerData.limit);
		}

		function getMessage(page,limit){
			commonModel.getNewMessge(page,limit,null,res=>state.drawerData = {limit,...res})
		}

		const readAll = ()=>commonModel.hanldeMessage('readall',()=>{
			state.drawerData.page = 0
			getMessage(1,state.drawerData.limit)
		})

		function messageToDetail(row) {
			let path = '', query = null
			if (row.is_see == 0) {
				commonModel.hanldeMessage('isread',{ids:[row.id]},()=>{})
			}
			if (row.type == 1) {
				path = '/order/detail';
				query = { id: row.to_id };
			}
			if (row.type == 7) {
				path = '/land/old_order_detail';
				query = { order_id: row.to_id };
			}
			if (row.type == 8) {
				path = '/adopt/orderDetail';
				query = { order_id: row.to_id };
			}
			if (row.type == 6) {
				path = '/adhibition/store/withdraw';
			}
			if (row.type == 5) {
				path = '/account/withdrawList';
			}
			if (row.type == 2) {
				path = '/adhibition/store/enter_audit';
			}
			if (row.type == 3) {
				path = '/adhibition/distribution/list';
			}
			if (row.type == 4) {
				path = '/order/detail';
				query = { id: row.to_id };
			}
			if (row.type == 19) {
				path = '/land/orderDetail';
				query = { order_id: row.to_id };
			}

			let nav = rhModel.getPathParent(store.state.permPlatform, path);
			if (query) {
				router.push({ path, query: query });
			} else {
				router.push(path);
			}
			state.show.drawer = false;
		}

		return{
			...toRefs(state),
			clearCache,
			handleCommand,
			navChangeLevelOne,
			navChangeLevelTwo,
			showDrawer,
			getMessage,
			readAll,
			messageToDetail,
			menuUnfold,
			infiniteHeight,
			refreshPage
		}
	}
}
</script>

<style lang="scss">
body {
	margin: 0;
	padding: 0;
}
#platformHeader {
	.kd-menu{
		z-index: 99;
	}
}
.left-menu-1::-webkit-scrollbar {
	display: none;
}
.kd-platform-header {
	position: fixed;
	left: 0;
	top: 0;
	color: #fff;
	height: 100%;
	overflow: hidden;
	overflow-y: auto;
	z-index: 99;

	.kph-app {
		width: 130px;
		height: 100px;
		text-align: center;
		margin-top: 20px;

		.wxapp-logo {
			width: 50px;
			height: 50px;
			border-radius: 50%;
		}
	}

	.p-left-menu {
		width: 100%;
		display: flex;
		align-items: center;
		padding: 10px 20px;
		cursor: pointer;
		color: #9e9e9e;
		box-sizing: border-box;
		.pl-menu-item {
			width: 80%;
			white-space: nowrap;
			overflow: hidden;
			margin-left: 10px;
			font-size: 15px;
		}
	}
	.active {
		color: #fff;
	}
	.active:after {
		content: '';
		position: absolute;
		width: 4px;
		height: 20px;
		background: #fff;
		right: 16px;
		border-radius: 4px;
	}
	.p-left-menu:hover {
		color: #fff;
		font-size: 15px;
	}
}

.left-menu-2::-webkit-scrollbar {
	display: none;
}
.left-menu-2 {
	width: 110px;
	position: fixed;
	left: 130px;
	top: 0;
	background: #fff;
	color: #000;
	height: 100%;
	font-size: 15px;
	display: none;
	z-index: 99;
	overflow: auto;
	overflow-y: auto;
	border-right: 1px solid #f4f4f4;

	a {
		text-decoration: none;
		color: #999;
	}
	a:hover {
		color: rgb(64, 158, 255);
	}

	.lm-title {
		width: 100%;
		height: 82px;
		line-height: 82px;
		border-bottom: 1px solid #f4f4f4;
		text-align: center;
		color: #000;
		font-weight: bold;
		cursor: pointer;
	}
	.lm-item {
		min-height: 40px;
		line-height: 40px;
		width: 100%;
		color: #615d5d;

		&-title {
			box-sizing: border-box;
			padding: 0 0 0 24px;
			font-weight: bold;
			text-align: left;
			font-size: 14px;
		}

		&-ul {
			height: auto;
			cursor: pointer;

			&-title {
				height: 40px;
				line-height: 40px;
				width: 100%;
				text-align: left;
				font-size: 12px;
				color: #615d5d;
				box-sizing: border-box;
				padding: 0 24px;
			}
			&-title:hover {
				color: #1890ff;
			}

			.active {
				color: #1890ff;
			}
		}
	}
}

.left-menu-2-show {
	display: block;
}

.main-header {
	text-align: right;
	background: white;
	padding: 0 20px;
	display: flex;
	justify-content: space-between;

	box-sizing: border-box;
	position: fixed;
	top: 0;
	width: 100%;
	left: 0;
	z-index: 9;

	.mh-top-nav-item {
		margin-right: 12px;
		text-align: center;
	}

	&-left {
		display: flex;
		align-items: center;
		i {
			cursor: pointer;
		}

		//刷新按钮
		.ri-refresh-line {
			margin-left: 8px;
			font-size: 13px;
			color: #666666;
		}

		.kdpm-server {
			padding: 0 6px;
			background: #fbe239;
			height: 16px;
			display: flex;
			align-items: center;
			color: #b58e19;
			font-size: 12px;

			span {
				margin-right: 12px;
			}
			span:last-child {
				margin: 0;
			}
			i {
				margin-right: 6px;
			}
		}
		.kdpm-expire_time {
			margin-left: 6px;
			font-size: 12px;
			color: #666;
		}
        .kdpm-try-server{
            padding: 0 6px;
			height: 16px;
			display: flex;
			align-items: center;
			font-size: 12px;
            border: 1px solid #FF0066;
            color: #FF0066;
            margin-left: 12px;
        }
	}

	&-right {
		.mhr-btn {
			color: #666;
			font-size: 13px;
			cursor: pointer;
		}
	}
}

.kd-platform-main {
	padding: 0;
	transition: all 0.3s;
	overflow: hidden;
}

//消息抽屉
.kd-platform-drawer {
	.kpd-item {
		width: 90%;
		margin: auto;
		padding: 10px 0;
		border-bottom: 1px solid #f4f4f4;
		display: flex;
		align-items: center;
		cursor: pointer;

		.kpdi-content {
			font-size: 12px;
			color: #333;
		}
		.kpdi-time {
			font-size: 12px;
			color: #999;
			margin-top: 5px;
		}
		div:first-child {
			margin-right: 5px;
		}
	}
}

.kpd-item{
	width: 90%;
	margin: auto;
	padding: 10px 0;
	border-bottom: 1px solid #f4f4f4;
	display: flex;
	align-items: center;
	cursor: pointer;

	.kpdi-content{
		font-size: 12px;
		color: #333;
	}
	.kpdi-time{
		font-size: 12px;
		color: #999;
		margin-top: 5px;
	}
	div:first-child{
		margin-right: 5px;
	}
}

.kd-try-pancel{
    background: #ffeeda;
    color: #000;
    padding: 12px 24px;
    color:#666;
}

.kd-free-version{
    background: #fdf2e2;
    padding: 12px 24px;
    color: #666;

    .free-tag{
        display: inline-block;
        padding: 2px 6px;
        border: 1px solid #00CC66;
        color: #00CC66;
        font-size: 12px;
    }
}
</style>
