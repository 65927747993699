<template>
    <!-- 库存盘点 -->
    <div class="stock-taking el-content">
        <a-table :pagination="false" rowKey="id" :data-source="info.list" :columns="[
            {title:'ID',dataIndex:'id'},
            {title:'仓库',dataIndex:'name'},
            {title:'图片',dataIndex:'img'},
            {title:'产品名称',dataIndex:'goods_name'},
            {title:'规格型号',dataIndex:'spce'},
            {title:'备注',dataIndex:'remark'},
            {title:'单位',dataIndex:'unit'},
            {title:'系统库存',dataIndex:'sys_count'},
            {title:'盘点库存',dataIndex:'talk_count'},
            {title:'盘点盈亏',dataIndex:'count'},
            {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
        ]" >
            <template #action="{record}">
                <a-button type="link">添加{{record.id}}</a-button>
                <a-button type="link">删除</a-button>
            </template>
        </a-table>
        <div class="pager">
            <a-space>
                <a-button type="primary">保存盘点结果</a-button>
                <a-button>导出系统库存</a-button>
                <a-button>导出盘点库存</a-button>
                <a-button>生成盘点数据</a-button>
            </a-space>
            <a-pagination
                show-size-changer
                :default-current="info.page"
                :total="info.count"
                @showSizeChange="(p,e)=>{getDisRule(info.page,e)}"
                @change="(e)=>{getDisRule(e,info.limit)}"
            />
        </div>
    </div>
</template>
<script>
import { reactive, toRefs } from 'vue'
export default {
    setup() {
        const state = reactive({
            info:{
                list:[
                    {id:1,name:'城南1号仓库',goods_name:'脐橙',spce:'188kg',count:100,sys_count:1000,unit:'斤',remark:"测试"},
                    {id:2,name:'城南1号仓库',goods_name:'脐橙',spce:'188kg',count:100,sys_count:1000,unit:'斤',remark:"测试"},
                    {id:3,name:'城南1号仓库',goods_name:'脐橙',spce:'188kg',count:100,sys_count:1000,unit:'斤',remark:"测试"},
                    {id:4,name:'城南1号仓库',goods_name:'脐橙',spce:'188kg',count:100,sys_count:1000,unit:'斤',remark:"测试"},
                    {id:5,name:'城南1号仓库',goods_name:'脐橙',spce:'188kg',count:100,sys_count:1000,unit:'斤',remark:"测试"},
                    {id:6,name:'城南1号仓库',goods_name:'脐橙',spce:'188kg',count:100,sys_count:1000,unit:'斤',remark:"测试"},
                    {id:7,name:'城南1号仓库',goods_name:'脐橙',spce:'188kg',count:100,sys_count:1000,unit:'斤',remark:"测试"},
                    {id:8,name:'城南1号仓库',goods_name:'脐橙',spce:'188kg',count:100,sys_count:1000,unit:'斤',remark:"测试"},
                    {id:9,name:'城南1号仓库',goods_name:'脐橙',spce:'188kg',count:100,sys_count:1000,unit:'斤',remark:"测试"},
                    {id:10,name:'城南1号仓库',goods_name:'脐橙',spce:'188kg',count:100,sys_count:1000,unit:'斤',remark:"测试"},
                ],
                page:1,
                count:0,
                limit:10
            }
        })

        return{
            ...toRefs(state)
        }
    },
}
</script>
<style scoped lang="scss">
.stock-taking{
    .pager{
        display: flex;
        justify-content: space-between;
    }
}
</style>