<template>
    <!-- 农事计划 -->
    <div class="el-content">
        <a-tabs v-model:activeKey="active">
            <template #tabBarExtraContent>
                <a-sapce v-if="active==1" style="display:flex;width:440px;justify-content: flex-end;">
                    <a-input-search
                        v-model:value="value"
                        placeholder="搜索农事计划、编号、名称"
                        enter-button="搜索"
                        style="width:300px;margin-right:12px"
                        @search="onSearch"
                    />
                    <a-button type="primary" @click="show.plan=true"><i class="ri-add-line"></i>添加农事计划</a-button>
                </a-sapce>

                <a-sapce v-if="active==2" style="display:flex;width:440px;justify-content: flex-end;">
                    <a-input-search
                        v-model:value="value"
                        placeholder="搜索农事计划、编号、名称"
                        enter-button="搜索"
                        style="width:300px;margin-right:12px"
                        @search="onSearch"
                    />
                    <a-button type="primary" @click="show.operation=true"><i class="ri-add-line"></i>添加操作</a-button>
                </a-sapce>
            </template>
            <a-tab-pane key="1" tab="计划列表">
                <a-table :pagination="false" :data-source="info.list" :columns="[
                    {title:'ID',dataIndex:'id'},
                    {title:'计划名称',dataIndex:'name'},
                    {title:'计划详情',dataIndex:'detail'},
                    {title:'计划类型',dataIndex:'type'},
                    {title:'地块',dataIndex:'land_name'},
                    {title:'对象',dataIndex:'obj'},
                    {title:'预计投入',dataIndex:'count'},
                    {title:'操作工人',dataIndex:'user'},
                    {title:'截止时间',dataIndex:'end_time'},
                    {title:'状态',dataIndex:'status',slots:{customRender:'status'}},
                    {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
                ]">
                    <template #status="{record}">
                        <a-tag color="#0099FF" v-if="record.status == 0">进行中</a-tag>
                        <a-tag color="#00CC66" v-if="record.status == 1">已完成</a-tag>
                        <a-tag color="#FF0066" v-if="record.status == 2">已逾期</a-tag>
                        <a-tag color="#FF9900" v-if="record.status == 3">已中止</a-tag>
                    </template>
                    <template #action="{record}">
                        <a-button type="link" size="small">任务指派{{ record.id}}</a-button>
                        <a-button type="link" size="small">打印</a-button>
                        <router-link to="/adhibition/digitalFarm/farm_plan_detail">
                            <a-button type="link" size="small">详情</a-button>
                        </router-link>
                    </template>
                </a-table>
                <div class="pager">
                    <a-pagination
                        show-size-changer
                        :default-current="info.page"
                        :total="info.count"
                        @showSizeChange="(p,e)=>{getDisRule(info.page,e)}"
                        @change="(e)=>{getDisRule(e,info.limit)}"
                    />
                </div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="操作设置">
                <a-table :pagination="false" :data-source="operation.list" :columns="[
                    {title:'ID',dataIndex:'id'},
                    {title:'操作名称',dataIndex:'name'},
                    {title:'添加时间',dataIndex:'create_time'},
                    {title:'添加人',dataIndex:'user'},
                    {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
                ]">
                    <template #action="{record}">
                        <a-button type="link" size="small">删除{{record.id}}</a-button>
                    </template>
                </a-table>
                <div class="pager">
                    <a-pagination
                        show-size-changer
                        :default-current="operation.page"
                        :total="operation.count"
                        @showSizeChange="(p,e)=>{getDisRule(info.page,e)}"
                        @change="(e)=>{getDisRule(e,info.limit)}"
                    />
                </div>
            </a-tab-pane>
        </a-tabs>

        <a-modal title="添加农事计划" v-model:visible="show.plan"  @ok="handleOk" width="600px">
            <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
				<a-form-item label="计划名称" required>
					<a-input v-model:value="planForm.name"></a-input>
				</a-form-item>
				<a-form-item label="具体任务" >
					<a-input v-model:value="planForm.detail"></a-input>
				</a-form-item>
                <a-form-item label="截止时间" required >
                    <a-date-picker v-model:value="planForm.end_time" />
				</a-form-item>
                <a-form-item label="操作选择" required >
                    <a-select v-model:value="planForm.type"></a-select>
				</a-form-item>
                <a-form-item label="操作地块" required >
                    <a-select v-model:value="planForm.land_name"></a-select>
				</a-form-item>
                <a-form-item label="操作对象" required >
                    <a-select v-model:value="planForm.obj"></a-select>
				</a-form-item>
                <a-form-item label="执行工人" required >
                    <a-select v-model:value="planForm.user"></a-select>
				</a-form-item>
                <a-form-item label="投入农资" >
                    <a-select v-model:value="planForm.count"></a-select>
				</a-form-item>
                <a-form-item label="投入数量" >
                    <a-input v-model:value="planForm.count" addon-after="kg"></a-input>
				</a-form-item>
			</a-form>
        </a-modal>
        <a-modal title="添加操作" v-model:visible="show.operation"  @ok="handleOk" width="700px">
            <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
				<a-form-item label="计划名称" required>
					<a-input v-model:value="operationForm.name"></a-input>
				</a-form-item>
			</a-form>
        </a-modal>
    </div>
</template>
<script>
import { reactive, toRefs } from 'vue'
export default {
    setup() {
        const state = reactive({
            active:"1",
            info:{
                list:[
                    {id:1,name:'贪夜蛾防止',detail:'A3型诱冲剂',type:'病虫害防治',land_name:'地块A',obj:'苹果',count:'化肥1000kg',user:'张三，李四',end_time:'2021-01-12',status:0},
                    {id:1,name:'贪夜蛾防止',detail:'A3型诱冲剂',type:'病虫害防治',land_name:'地块A',obj:'苹果',count:'化肥1000kg',user:'张三，李四',end_time:'2021-01-12',status:1},
                    {id:1,name:'贪夜蛾防止',detail:'A3型诱冲剂',type:'病虫害防治',land_name:'地块A',obj:'苹果',count:'化肥1000kg',user:'张三，李四',end_time:'2021-01-12',status:2},
                    {id:1,name:'贪夜蛾防止',detail:'A3型诱冲剂',type:'病虫害防治',land_name:'地块A',obj:'苹果',count:'化肥1000kg',user:'张三，李四',end_time:'2021-01-12',status:3},
                    {id:1,name:'贪夜蛾防止',detail:'A3型诱冲剂',type:'病虫害防治',land_name:'地块A',obj:'苹果',count:'化肥1000kg',user:'张三，李四',end_time:'2021-01-12',status:0},
                    {id:1,name:'贪夜蛾防止',detail:'A3型诱冲剂',type:'病虫害防治',land_name:'地块A',obj:'苹果',count:'化肥1000kg',user:'张三，李四',end_time:'2021-01-12',status:1},
                    {id:1,name:'贪夜蛾防止',detail:'A3型诱冲剂',type:'病虫害防治',land_name:'地块A',obj:'苹果',count:'化肥1000kg',user:'张三，李四',end_time:'2021-01-12',status:2},
                    {id:1,name:'贪夜蛾防止',detail:'A3型诱冲剂',type:'病虫害防治',land_name:'地块A',obj:'苹果',count:'化肥1000kg',user:'张三，李四',end_time:'2021-01-12',status:3},
                ],
                page:1,
                count:0,
                limit:10
            },
            operation:{
                list:[
                    {id:1,name:'采摘/不唠',user:'admin',create_time:'2021-01-12',status:0},
                    {id:1,name:'除草',user:'admin',create_time:'2021-01-12',status:1},
                    {id:1,name:'施肥',user:'admin',create_time:'2021-01-12',status:2},
                    {id:1,name:'除虫',user:'admin',create_time:'2021-01-12',status:3},
                    {id:1,name:'巡检',user:'admin',create_time:'2021-01-12',status:0},
                    {id:1,name:'巡检',user:'admin',create_time:'2021-01-12',status:1},
                    {id:1,name:'巡检',user:'admin',create_time:'2021-01-12',status:2},
                    {id:1,name:'巡检',user:'admin',create_time:'2021-01-12',status:3},
                ],
                page:1,
                count:0,
                limit:10
            },
            show:{
                plan:false,
                operation:false
            },
            planForm:{
                name:"",
                detail:"",
                end_time:"",
                type:"",
                land_name:"",
                obj:"",
                user:"",
                count:"",
            },
            operationForm:{
                name:""
            }
        })


        return{
            ...toRefs(state)
        }
    },
}
</script>