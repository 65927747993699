import { $post } from '@/api/http.js'
import tool from '@/util/tool.js'
import common from '@/api/common.js'
import router from '@/router'
import store from '@/store/index'

class Set{
    /**
     * 获取全局设置信息
     * @param {Array} keys 要获取的值的key
     * @returns 
     */
    getFarmSet(keys){
        return new Promise(resolve=>{
            $post('getSetUpList',{keys}).then(res=>{
                let result = tool.filterForm(keys,res.data)
                resolve(result)
            })
        })
    }
    /**
     * 保存全局设置信息
     * @param {object} data 
     * @param {object} showMsg 
     */
    setFarmSet(data,showMsg=true){
        $post("setSetUp",data,true,2,true,true).then(()=>{
            if( showMsg ){
                tool.message("保存成功","success")
            }
        })
    }
    /**
     * 获取微信小程序/微信公众号授权返回地址
     * @param {string} callback 授权返回跳转地址
     * @returns 
     */
    getWxOpenAuthUrl(callback=""){
        return new Promise(resolve=>{
            if( !callback ) callback = window.location.href
            $post("getWxOpenAuthUrl",{callback}).then(res=>{
                resolve(res.url)
            })
        })
    }

    /**
     * 设置微信小程序/微信公众号授权
     * @param {string} auth_type 微信小程序（min_program） 微信公众号（official）
     * @param {string} auth_code 授权码
     * @returns 
     */
    setWxOpenAuth(auth_type,auth_code){
        return new Promise(resolve=>{
            $post("setWxOpenAuth",{auth_type,auth_code}).then(()=>{
                tool.message("授权成功","success")
                resolve(true)
            })
        })
    }
    /**
     * 获取微信小程序/微信公众号的授权信息
     * @param {string} auth_type 微信小程序（min_program） 微信公众号（official）
     * @returns 
     */
    getWxOpenAuth(auth_type){
        return new Promise(resolve=>{
            $post("getWxOpenAuth",{auth_type}).then(res=>{
                resolve(res.data)
            })
        })
    }
    /**
     * 批量设置微信小程序/微信公众号的订阅消息
     * @param {string} sence 微信小程序（wxapp） 微信公众号（office）
     * @returns 
     */
    batchSetSubscribeMsg(sence){
        return new Promise(resolve=>{
            let api = sence == "wxapp" ? "setProgramTemplate" :"setOfficeTemplate"
            $post(api).then(res=>{
                tool.message("设置成功")
                resolve(true)
            })
        })
    }
    /**
     * 获取二维码
     * @param {string}} path 二维码生成路径 
     * @param {string} plat  平台 小程序码wx_app
     * @returns 
     */
    getQrcode(path,plat){
        return new Promise(resolve=>{
            let param = { path,plat }
            $post("getAppQrCode",param).then(res=>{
                resolve(res.data.url)
            })
        })
    }

    /**
     * 获取小程序信息
     * @returns 
     */
    getProgramInfo(){
        return new Promise(resolve=>{
            let program_id = localStorage.getItem('program_id')
            $post("getProgramDetail",{program_id},true,1).then(res=>{
                resolve(res.data)
            })
        })
    }

    /**
     * H5站点域名获取、提交审核
     * @param {*} sence 
     * @param {*} param 
     * @returns 
     */
    handleAPPSite(sence,param=null){
        return new Promise(resolve=>{
            if( sence == 'get' ){
                $post("getAppSite").then(res=>{
                    resolve(res.data)
                })
            }
            if( sence == 'edit' ){
                $post("setAppSite",param).then(res=>{
                    tool.message("提交成功，请等待审核！","success")
                    resolve(true)
                }).catch(()=>resolve(false))
            }
        })
    }
    /**
     * 获取微信公众号绑定用户二维码
     * @returns 
     */
    getBindWxAdminQrcode(){
        return new Promise(resolve=>{
            $post("bindOpenIdByAdmin").then(res=>{
                resolve(res.data)
            })
        })
    }
    /**
     * 获取微信公众号授权二维码
     * @returns 
     */
    getWxOpenIdQrcode(){
        return new Promise(resolve=>{
            $post("getOpenidByqrcode").then(res=>{
                resolve(res.data)
            })
        })
    }
    /**
     * 获取app打包记录
     * @param {number} page 
     * @param {number} limit 
     * @returns 
     */
    getAppPackageList(page,limit){
        return new Promise(resolve=>{
            let param = { page,limit}
            common.getDataList("packageApplicationList",param).then(res=>{
                resolve(res)
            })
        })
    }
    /**
     * 提交打包申请
     * @returns 
     */
    submitAppPackage(){
        return new Promise(resolve=>{
            tool.confirm("确认提交打包申请吗？").then(()=>{
                $post("packageApplication").then(()=>{
                    tool.$message.success("打包申请提交成功")
                    resolve(true)
                })
            }).catch(()=>{})
        })
    }
    /**
     * 发送APP消息推送
     * @param {object} data 
     * @returns 
     */
    sendPushMessage(data){
        if( !data.title ){
            tool.message("请输入消息标题","warning");
            return false;
        }
        if( !data.content ){
            tool.message("请输入消息内容","warning");
            return false;
        }
        $post("msgToAll",data).then(()=>{
            tool.message("消息发送成功")
        })
        return true
    }

    /**
     * 获取土地合同模板
     */
    getLandContract() {
        $post("getLandOrderContractTestImage").then(res=>{
            window.open(res.data.url)
        }).catch(()=>{})
    }
    /**
     * 获取运费规则信息
     * @param {number} page 
     * @param {number} limit 
     * @param {function} fn 
     */
    getFreight (page,limit,fn) {
        let param = { page,limit }
        common.getDataList("getFreightList",param).then(res=>{
            fn(res)
        })
    }
    /**
    * 获取运费规则详情
    * @param {number} id 
    * @param {function} fn 
    */
    getFreightDetail(id,fn){
        $post('getFreightDetail',{ id }).then(res=>fn(res.data))
    }

    /**
     *运费规则保存
     * @param {object} param 
     * @param {object} spec 
     */
    setFreight(param,spec){
        if( !param.id ) delete param.id
        spec.map((item,index)=>{
            if( item.city_ids!=0 ){
                spec[index].city_ids = item.city_ids.join(',')
            }
        })
        if( param.no_distribution && param.no_distribution.length > 0 ){
            param.no_distribution = param.no_distribution ? param.no_distribution.join(',') :'';
        }
        $post("setFreight",{freight:param,spec}).then(()=>{
            tool.message("保存成功")
            router.go(-1)
        })
    }
    
    /**
     * 获取城市信息
     * @param {function}} fn 
     */
    getCity(fn){
        $post('getBaseCity').then(res=>fn(res.data))
    }

    /**
     * 获取商家自送的配送员信息
     * @param {number} page 
     * @param {number} limit 
     * @param {object} search 
     * @param {function} fn 
     */
    getMerchantDeliveryMan(page,limit,search,fn) {
        let param = { page,limit }
        common.getDataList("getPerson",param).then(res=>{
            fn(res)
        })
    }
    /**
     * 商家添加配送员
     * @param {object} data 
     * @param {function} fn 
     * @returns 
     */
    addMerchantDeliveryMan(data,fn) {
        if( !data.name ){
            tool.message("请输入配送员名称","warning")
            return
        }
        if( !data.mobile ){
            tool.message("请输入联系电话","warning")
            return
        }

        if( !data.outlet_id){
            tool.message("请选择门店","warning")
            return
        }

        if( !data.account_id){
            tool.message("请选择用户","warning")
            return
        }
        if( !data.id) delete data.id
        $post("addOrEditPerson",data).then(res=>{
            tool.message("保存成功")
            fn(res.data)
        })
    }

    /**
     * 根据用户获取每个用户的微信授权二维码
     * @param {number} id 
     * @param {function} fn 
     */
    getPersonWxAuthQrcode(id,fn) {
        $post("getQrcodeByPersion",{id}).then(res=>{
            fn(res.data)
        })
    }
}
const setModel = new Set()
export default setModel