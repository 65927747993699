<template>
	<div class="kd-img-select" style="width:100%">
		<div v-if="custom" @click="imageShow">
			<slot></slot>
		</div>
		<div v-else>
			<div class="one-upload" v-if="!mutil">
				<a-input v-model:value="input_url">
					<template #addonAfter>
						<i style="cursor: pointer;" class="ri-upload-cloud-2-fill" @click="imageShow"></i>
					</template>
				</a-input>
				<a-image v-if="input_url && showImg" class="img" :width="80" :src="input_url" />
			</div>
			<div class="mutil-upload" v-if="mutil">
				<a-input disabled>
					<template #addonAfter>
						<i style="cursor: pointer;" class="ri-upload-cloud-2-fill" @click="imageShow"></i>
					</template>
				</a-input>
				<div class="img-ul" v-if="urls.length >0">
					<div class="img-ul-li" v-for="(item,index) in urls" :key="index">
						<div class="img-delete">
							<i class="ri-delete-bin-5-line" @click="deleteSlideImg(index)" style="color: white;font-size: 20px;"></i>
						</div>
						<a-image class="img" :preview="false" :src="item"></a-image>
					</div>
				</div>
			</div>
		</div>

		<a-modal v-model:visible="image.show" width="940px" @ok="sureSelect" @cancel="image.show = false">
			<template #title>
				<span style="margin-right: 12px;">选择文件</span>
				<span class="f12" v-if="info.size">存储空间：{{ info.size.use_size }}/{{ info.size.max_size }}</span>
			</template>
			<a-tabs v-model:activeKey="image.type">
				<a-tab-pane key="1" tab="自定义图标库">
					<div class="img-all">
						<div class="img-li" v-for="(item, index) in info.list" :key="index" @click="checkImg(index)">
							<div class="gou" v-if="item.checked == true"></div>
							<a-image :preview="false" style="width:98px; height: 98px" :src="item.url"></a-image>
						</div>
						<div style="width: 102px;height: 2px;"></div>
						<div style="width: 102px;height: 2px;"></div>
						<div style="width: 102px;height: 2px;"></div>
						<div style="width: 102px;height: 2px;"></div>
						<div style="width: 102px;height: 2px;"></div>
					</div>
					<div class="pager">
						<a-pagination
							show-quick-jumper
							v-model:current="info.page"
							:defaultPageSize="info.limit"
							:total="info.count"
							@change=" e => { getImageList(e, info.limit); } "
						/>
					</div>
				</a-tab-pane>
				<a-tab-pane key="2" tab="内置图标库">
					<div class="img-all">
						<div class="img-li" v-for="(item, index) in bullInIcon" :key="index" @click="checkIcon(index)">
							<div class="gou" v-if="item.checked == true"></div>
							<a-image :preview="false" width="60" :src="item.url" fit="scale-down"></a-image>
						</div>
						<div style="width: 102px;height: 2px;" v-for="v in 6" :key="v"></div>
					</div>
				</a-tab-pane>
				<template #tabBarExtraContent>
					<a-space v-if="image.type == 1">
						<a-checkbox @change="checkAll">全选</a-checkbox>
						<a-button type="danger" @click="deleteImg">删除</a-button>
						<com-upload-btn :multiple="true" @success="getImageList(info.page,info.limit)"></com-upload-btn>
					</a-space>
				</template>
			</a-tabs>
		</a-modal>
	</div>
</template>

<script>
import comUploadBtn from '@/components/public/com-upload-btn.vue'
import draggable from 'vuedraggable'
import { reactive, toRefs, watch } from 'vue';
import common from '@/api/common.js'
import tool from '@/util/tool.js'
export default {
	name: 'kd-img-select',
	components:{
		comUploadBtn,
		draggable
	},
	props: {
		mutil: {
			//是否为批量上传
			type: Boolean,
			value: false
		},
		src: {
			type: [String, Object,Array]
		},
		custom:{		//自定义上传按钮
			type:Boolean,
			default:false
		},
		showImg:{
			type:Boolean,
			default:true
		}
	},
	setup(props,context){
		const _d = reactive({
			input_url: '', //单文件上传输入的值
			urls: [], //多文件选择
			image: {
				show: false, //是否显示img库
				type: '1',
				checked: false,
			},
			info: {
				list: [],
				page: 1,
				limit: 24,
				count: 0,
				size: null
			},
			bullInIcon: [],
		})
		watch(() => _d.input_url,(value) => {
				context.emit("success",value)
            }
        )
		watch(()=>props.src, (value)=>{
			if( props.mutil ){
				if( value ) _d.urls = value	
			}else{
				_d.input_url = value;
			}
		},{ deep:true,immediate: true})

		getBuiltInIcon()

		function imageShow(){
			_d.image.show = true
			getImageList(1,_d.info.limit)
		}

		function getImageList(page,limit){
			common.getImageList(page,limit,null,res=>_d.info = {limit,...res})
		}
		function checkImg(index) {
			if (_d.info.list[index].checked) {
				_d.info.list[index].checked = false;
			} else {
				_d.info.list[index].checked = true;
			}
		}
		function checkIcon(index) {
			if (_d.bullInIcon[index].checked) {
				_d.bullInIcon[index].checked = false;
			} else {
				_d.bullInIcon[index].checked = true;
			}
		}

		function sureSelect() {
			let { list } = _d.info, url = [];
			if (_d.image.type == '1') {
				list.forEach((item, index) => {
					if (item.checked) {
						_d.info.list[index].checked = false;
						url.push(item.url);
					}
				});
			}

			if (_d.image.type == '2') {
				_d.bullInIcon.forEach((item, index) => {
					if (item.checked) {
						_d.bullInIcon[index].checked = false;
						url.push(item.url);
					}
				});
			}
			if (url.length == 0) {
				tool.message('请选择图片','warning');
				return;
			}

			if (props.mutil){
				_d.urls = _d.urls.concat(url);
				context.emit("success",_d.urls)
			}
			if (!props.mutil) _d.input_url = url[0];
			
			_d.image.show = false;
			_d.urls = []
		}

		function getBuiltInIcon() {
			let arr = [];
			for (let i = 0; i < 9; i++) {
				let url = tool.getStaticSrc('adopt/a-' + (i + 1) + '.png');
				arr.push({ url, checked: false });
			}
			for (let i = 0; i < 10; i++) {
				let url = tool.getStaticSrc('adopt/b-' + (i + 1) + '.png');
				arr.push({ url, checked: false });
			}
			for (let i = 0; i < 55; i++) {
				let url = tool.getStaticSrc('adopt/c-' + (i + 1) + '.png');
				arr.push({ url, checked: false });
			}
			_d.bullInIcon = arr;
		}

		function checkAll(e) {
			if (e.target.checked) {
				_d.info.list.map((item, index) => {
					_d.info.list[index].checked = true;
				})
			} else {
				_d.info.list.map((item, index) => {
					_d.info.list[index].checked = false;
				})
			}
		}

		function deleteImg() {
			let { list } = _d.info;
			let ids = [];
			list.map(item => {
				if (item.checked) {
					ids.push(item.id);
				}
			})

			if (ids.length <= 0) {
				tool.message('请选择要删除的文件！',"error");
				return;
			}
			common.deleteDataList(ids,28,'确认要删除选中文件吗？').then(()=>{
				tool.message("删除成功")
				getImageList(_d.info.page,_d.info.limit)
			})
		}

		function deleteSlideImg(index) {
			let { urls } = _d;
			urls.splice(index, 1);
			context.emit('success', _d.urls);
		}
		return {
			...toRefs(_d),
			getImageList,
			checkImg,
			checkIcon,
			checkAll,
			getBuiltInIcon,
			deleteImg,
			deleteSlideImg,
			imageShow,
			sureSelect,
		}
	}
	
};
</script>

<style lang="scss">
.one-upload {
	width: 100%;
	.img {
		width: 80px;
		max-height: 80px;
		border: 1px solid #f4f4f4;
		margin-top: 12px;
	}
}

.img-all {
	width: 100%;
	height: 350px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	overflow: hidden;
	overflow-y: auto;

	.img-li {
		width: 100px;
		height: 100px;
		border: 1px solid #f4f4f4;
		position: relative;
		cursor: pointer;
		margin-bottom: 8px;
		text-align: center;
		line-height: 100px;
	}

	.img-li:hover {
		border: 1px solid #409eff;
	}
	.gou {
		position: absolute;
		background-color: rgba(0, 0, 0, 0.5);
		z-index: 5;
		width: 100px;
		height: 100px;
		background-image: url('../../assets/img/gou.png');
		background-size: 40px 40px;
		background-repeat: no-repeat;
		background-position: center;
		z-index: 99;
	}
}

.mutil-upload {
	width: 100%;
	.img-ul {
		margin-top: 8px;
		display: flex;
		flex-wrap: wrap;

		>div{
			display: flex;
			flex-wrap: wrap;
		}

		&-li {
			text-align: center;
			margin-right: 8px;
			margin-bottom: 8px;
		}
	}
	.img {
		width: 72px;
	}
	.img-delete {
		position: absolute;
		z-index: 5;
		width: 72px;
		height: 72px;
		text-align: center;
		line-height: 72px;
		opacity: 0;
		cursor: pointer;
	}
	.img-delete:hover {
		background-color: rgba(0, 0, 0, 0.5);
		z-index: 5;
		width: 72px;
		height: 72px;
		text-align: center;
		line-height: 72px;
		opacity: 1;
	}
}
</style>
