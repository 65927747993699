<template>
    <!-- 操作记录 -->
    <div class="el-content">
        <a-space class="mb12">
            <a-select
                v-model:value="value1"
                style="width: 120px"
                placeholder="操作工人"
            >
                <a-select-option value="jack">Jack</a-select-option>
            </a-select>
            <a-select
                v-model:value="value1"
                style="width: 120px"
                placeholder="操作类型"
            >
                <a-select-option value="jack">Jack</a-select-option>
            </a-select>
            <a-button type="primary"><i class="ri-search-2-line"></i>搜索</a-button>
            <a-button type="primary" @click="show.add"><i class="ri-add-line"></i>添加操作</a-button>
        </a-space>
        <a-table :pagination="false" :data-source="info.list" :columns="[
            {title:'ID',dataIndex:'id'},
            {title:'操作方式',dataIndex:'name'},
            {title:'地块',dataIndex:'land_name'},
            {title:'对象',dataIndex:'obj'},
            {title:'操作详情',dataIndex:'detail'},
            {title:'投入农资',dataIndex:'count'},
            {title:'关联计划',dataIndex:'plant'},
            {title:'操作工人',dataIndex:'user'},
            {title:'操作时间',dataIndex:'create_time'},
            {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
        ]">
            <template #action="{record}">
                <a-button type="link" size="small">删除{{record.id}}</a-button>
            </template>
        </a-table>
        <div class="pager">
            <a-pagination
                show-size-changer
                :default-current="info.page"
                :total="info.count"
                @showSizeChange="(p,e)=>{getDisRule(info.page,e)}"
                @change="(e)=>{getDisRule(e,info.limit)}"
            />
        </div>

        <a-modal title="添加操作记录" v-model:visible="show.add"  @ok="handleOk" width="700px">
            <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
				<a-form-item label="操作类型" required>
					<a-select v-model:value="recordForm.type"></a-select>
				</a-form-item>
                <a-form-item label="地区选择" required>
					<a-select v-model:value="recordForm.type"></a-select>
				</a-form-item>
                <a-form-item label="操作地块" required>
					<a-select v-model:value="recordForm.type"></a-select>
				</a-form-item>
                <a-form-item label="操作时间" required >
                    <a-date-picker v-model:value="recordForm.end_time" />
				</a-form-item>
                <a-form-item label="执行工人" required >
                    <a-select v-model:value="recordForm.user"></a-select>
				</a-form-item>
                <a-form-item label="投入农资" >
                    <a-select v-model:value="recordForm.count"></a-select>
				</a-form-item>
                <a-form-item label="投入数量" >
                    <a-input v-model:value="recordForm.count" addon-after="kg"></a-input>
				</a-form-item>
                <a-form-item label="操作备注" >
                    <a-input v-model:value="recordForm.count"></a-input>
				</a-form-item>
			</a-form>
        </a-modal>
    </div>
</template>
<script>
import { reactive, toRefs } from 'vue'
export default {
    setup() {
        const state = reactive({
            info:{
                list:[
                    {id:1,name:'操作类型',land_name:'地块名称',obj:'操作对象',detail:'操作详情',count:'氮肥100kg',plan:'',create_time:"2020-10-25",user:'admin'},
                    {id:1,name:'操作类型',land_name:'地块名称',obj:'操作对象',detail:'操作详情',count:'氮肥100kg',plan:'',create_time:"2020-10-25",user:'admin'},
                    {id:1,name:'操作类型',land_name:'地块名称',obj:'操作对象',detail:'操作详情',count:'氮肥100kg',plan:'',create_time:"2020-10-25",user:'admin'},
                    {id:1,name:'操作类型',land_name:'地块名称',obj:'操作对象',detail:'操作详情',count:'氮肥100kg',plan:'',create_time:"2020-10-25",user:'admin'},
                    {id:1,name:'操作类型',land_name:'地块名称',obj:'操作对象',detail:'操作详情',count:'氮肥100kg',plan:'',create_time:"2020-10-25",user:'admin'},
                    {id:1,name:'操作类型',land_name:'地块名称',obj:'操作对象',detail:'操作详情',count:'氮肥100kg',plan:'',create_time:"2020-10-25",user:'admin'},
                    {id:1,name:'操作类型',land_name:'地块名称',obj:'操作对象',detail:'操作详情',count:'氮肥100kg',plan:'',create_time:"2020-10-25",user:'admin'},
                ],
                page:1,
                limit:10,
                count:0
            },
            show:{
                add:true,
            },
            recordForm:{
                type:"",
                end_time:"",
                user:"",
                count:"",
            }
        })

        return{
            ...toRefs(state)
        }
    },
}
</script>