<template>
    <!-- 供应商 -->
    <div class="digital-customer">
        <div class="el-content mb12">
            <a-space>
                <a-input-search
                    v-model:value="search.key"
                    placeholder="输入客户编号/名称/联系人/电话/备注查询"
                    enter-button
                    style="width:400px"
                    @search="onSearch"
                />  
                <a-button type="primary" @click="show.add = true"><i class="ri-add-line ri-btn"></i>新增供应商</a-button>
                <a-button><i class="ri-upload-line ri-btn"></i>批量导入</a-button>
            </a-space>
        </div>
        <div class="el-content">
            <a-table :pagination="false" rowKey="id" :data-source="info.list" :columns="[
                {title:'供应商编号',dataIndex:'number'},
                {title:'供应商名称',dataIndex:'name'},
                {title:'联系人',dataIndex:'customer_name'},
                {title:'手机',dataIndex:'phone'},
                {title:'QQ/微信/email',dataIndex:'qq'},
                {title:'应收款余额',dataIndex:'price'},
                {title:'联系地址',dataIndex:'address'},
                {title:'备注',dataIndex:'remark'},
                {title:'状态',dataIndex:'status'},
                {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
            ]" :row-selection="{ selectedRowKeys: pks, onChange:(e)=>{pks = e}  }">
                <template #action="{record}">
                    <a-button type="link">编辑{{record.id}}</a-button>
                    <a-button type="link">删除</a-button>
                </template>
            </a-table>
            <div class="pager">
                <a-space>
                    <a-button>禁用</a-button>    
                    <a-button>启用</a-button>    
                    <a-button>导出</a-button>    
                    <a-button>删除</a-button>    
                </a-space>
                <a-pagination
                    show-size-changer
                    :default-current="info.page"
                    :total="info.count"
                    @showSizeChange="(p,e)=>{getDisRule(info.page,e)}"
                    @change="(e)=>{getDisRule(e,info.limit)}"
                />
            </div>
        </div>

        <a-modal v-model:visible="show.add" title="添加供应商" @ok="handleOk" width="1000px">
            <a-form :label-col="{span:6}" :wrapper-col="{span:18}">
                <a-row :gutter="[16,16]">
                    <a-col :span="12">
                        <a-form-item label="供应商名称" required>
                            <a-input v-model:value="form.name"></a-input>
                        </a-form-item>
                        <a-form-item label="期初预收款" required>
                            <a-input v-model:value="form.name"></a-input>
                        </a-form-item>
                        <a-form-item label="增值税税率" required>
                            <a-input v-model:value="form.name"></a-input>
                        </a-form-item>
                        <a-form-item label="开户银行" required>
                            <a-input v-model:value="form.name"></a-input>
                        </a-form-item>
                        <a-form-item label="联系人" required>
                            <a-input v-model:value="form.name"></a-input>
                        </a-form-item>
                        <a-form-item label="QQ/微信/邮箱" required>
                            <a-input v-model:value="form.name"></a-input>
                        </a-form-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-item label="余额日期" required>
                            <a-input v-model:value="form.name"></a-input>
                        </a-form-item>
                        <a-form-item label="期初预付款款">
                            <a-input v-model:value="form.rank"></a-input>
                        </a-form-item>
                        <a-form-item label="纳税人识别号">
                            <a-input v-model:value="form.rank"></a-input>
                        </a-form-item>
                        <a-form-item label="银行账号" required>
                            <a-input v-model:value="form.name"></a-input>
                        </a-form-item>
                        <a-form-item label="电话号码" required>
                            <a-input v-model:value="form.name"></a-input>
                        </a-form-item>
                        <a-form-item label="备注">
                            <a-input v-model:value="form.name"></a-input>
                        </a-form-item>
                    </a-col>
                </a-row>
                
			</a-form>
        </a-modal>
    </div>
   
</template>
<script>
import { reactive, toRefs } from 'vue'
export default {
    setup() {
        const state = reactive({
            search:{
                key:'',
            },
            pks:[],
            info:{
                list:[
                    {id:1,number:"#12454",name:"重庆坤典科技",customer_name:'张三',phone:'13452212345',qq:"13452212345",price:'10000',address:'重庆市',remark:'jjajja',status:1},
                    {id:2,number:"#12454",name:"重庆坤典科技",customer_name:'张三',phone:'13452212345',qq:"13452212345",price:'10000',address:'重庆市',remark:'jjajja',status:1},
                    {id:3,number:"#12454",name:"重庆坤典科技",customer_name:'张三',phone:'13452212345',qq:"13452212345",price:'10000',address:'重庆市',remark:'jjajja',status:1},
                    {id:4,number:"#12454",name:"重庆坤典科技",customer_name:'张三',phone:'13452212345',qq:"13452212345",price:'10000',address:'重庆市',remark:'jjajja',status:1},
                    {id:5,number:"#12454",name:"重庆坤典科技",customer_name:'张三',phone:'13452212345',qq:"13452212345",price:'10000',address:'重庆市',remark:'jjajja',status:1},
                    {id:6,number:"#12454",name:"重庆坤典科技",customer_name:'张三',phone:'13452212345',qq:"13452212345",price:'10000',address:'重庆市',remark:'jjajja',status:1},
                    {id:7,number:"#12454",name:"重庆坤典科技",customer_name:'张三',phone:'13452212345',qq:"13452212345",price:'10000',address:'重庆市',remark:'jjajja',status:1},
                    {id:8,number:"#12454",name:"重庆坤典科技",customer_name:'张三',phone:'13452212345',qq:"13452212345",price:'10000',address:'重庆市',remark:'jjajja',status:1},
                    {id:9,number:"#12454",name:"重庆坤典科技",customer_name:'张三',phone:'13452212345',qq:"13452212345",price:'10000',address:'重庆市',remark:'jjajja',status:1},
                    {id:10,number:"#12454",name:"重庆坤典科技",customer_name:'张三',phone:'13452212345',qq:"13452212345",price:'10000',address:'重庆市',remark:'jjajja',status:1},
                ],
                page:1,
                count:0,
                limit:10
            },
            show:{
                add:true
            },
            form:{
                name:"",
                rank:99
            }
        })

        return{
            ...toRefs(state)
        }
    },
}
</script>
<style scoped lang="scss">
.digital-customer{
    .pager{
        display: flex;
        justify-content: space-between;
    }
    .dc-form{
        display: flex;
        flex-wrap: nowrap;
    }
}
</style>