<template>
	<div id="adoptAgreement">
		<div class="el-content">
			<com-power-form form-type="check" :formData="[
				{label:'是否展示标题',type:'radio',key:'adopt_list_title_open',value:'',dataType:'string',
					options:[
						{label:'是',value:'1'},
						{label:'否',value:'0'},
					]
				},
				{label:'认养列表标题',type:'text',key:'adopt_list_title',value:'',dataType:'string',show:{key:'adopt_list_title_open',value:'1'}},
				{label:'认养列表说明',type:'text',key:'adopt_list_desc',value:'',dataType:'string',show:{key:'adopt_list_title_open',value:'1'}},
				{label:'字体颜色',type:'color',key:'adopt_list_text_color',value:'',dataType:'string',show:{key:'adopt_list_title_open',value:'1'}},
				{label:'认养顶部封面(375*210)',type:'image',key:'adopt_list_banner',value:'',dataType:'string'},
			]"></com-power-form>
		</div>
	</div>
</template>

<script>
import comPowerForm from '@/components/form/com-power-form.vue'
export default{
	components:{
		comPowerForm
	},
}
</script>

<style>
</style>
