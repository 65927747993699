<template>
	<div id="cpm_couponEdit" class="el-content">
		<a-form :label-col="{ span: 2 }" :wrapper-col="{ span: 8 }">
			<a-form-item label="优惠券分类" required>
				<a-select v-model:value="form.type" placeholder="请选择" @change="clearSelectGoods">
				    <a-select-option
						v-for="item in typeList"
						:key="item.value"
						:value="item.value">{{item.name}}
				    </a-select-option>
				  </a-select>
			</a-form-item>
			<a-form-item label="优惠券名称" required>
				<a-input v-model:value="form.coupon_name"></a-input>
			</a-form-item>
			<a-form-item label="优惠券类型">
				<a-radio-group v-model:value="form.coupon_type">
					<a-radio :value="1">满减券</a-radio>
				</a-radio-group>
			</a-form-item>
			<a-form-item label="是否指定商品可用">
				<a-radio-group v-model:value="form.is_specify">
					<a-radio :value="1">全场通用</a-radio>
					<a-radio :value="2">指定商品可用</a-radio>
				</a-radio-group>
			</a-form-item>
			<a-form-item label="选择商品" v-show="form.is_specify == 2">
				<div v-if="form.type == 1">
					<a-input v-model:value="specifyGoods" disabled>
						<template #addonAfter>
							<com-select-table-list :mutil="true" @getData="selectGoodsData">
								选择商品
							</com-select-table-list>
						</template>
					</a-input>	
					<!-- 编辑商品时的回显 -->
					<div class="ce-goods-tag" v-for="(item,index) in form.specify_goods" :key="index" >
						{{ item.goods_name }}
						<i class="ri-close-line" @click="deleleSelectGoods(index)"></i>
					</div>
				</div>
				<div v-if="form.type == 3">
					<a-input v-model:value="specifyGoods" disabled>
						<template #addonAfter>
							<com-select-table-list data-type="adopt" :mutil="true" @getData="selectGoodsData">选择商品</com-select-table-list>
						</template>
					</a-input>	
					<!-- 编辑商品时的回显 -->
					<div class="ce-goods-tag" v-for="(item,index) in form.specify_goods" :key="index" >
						{{ item.name }}
						<i class="ri-close-line" @click="deleleSelectGoods(index)"></i>
					</div>
				</div>
				<div v-if="form.type == 4">
					<a-input v-model:value="specifyGoods" disabled>
						<template #addonAfter>
							<com-select-table-list data-type="land" :mutil="true" @getData="selectGoodsData">选择商品</com-select-table-list>
						</template>
					</a-input>	
					<!-- 编辑商品时的回显 -->
					<div class="ce-goods-tag" v-for="(item,index) in form.specify_goods" :key="index" >
						{{ item.land_name }}
						<i class="ri-close-line" @click="deleleSelectGoods(index)"></i>
					</div>
				</div>
				
			</a-form-item>
			<a-form-item label="最低消费金额（元）" required>
				<a-input v-model:value="form.low_cash_price"></a-input>
			</a-form-item>
			<a-form-item label="优惠金额（元）" required>
				<a-input v-model:value="form.coupon_price"></a-input>
			</a-form-item>
			<a-form-item label="发放总数">
				<a-input v-model:value="form.count"></a-input>
			</a-form-item>
			<a-form-item label="已发放总数">
				<a-input v-model:value="form.use_count"></a-input>
			</a-form-item>
			<a-form-item label="优惠券有效期" >
				<a-radio-group v-model:value="form.expiry_date">
					<a-radio :value="1">领取后N天内有效</a-radio>
					<a-radio :value="2">时间段</a-radio>
				</a-radio-group>
			</a-form-item>
			<a-form-item label="有效天数"  v-if="form.expiry_date==1">
				<a-input v-model:value="form.expiry_day"></a-input>
			</a-form-item>
			<a-form-item label="时间段"  v-if="form.expiry_date==2">
				<a-col :span="11">
					<a-range-picker show-time v-model:value="dateTime">
						<template #renderExtraFooter></template>
					</a-range-picker>
				</a-col>
			</a-form-item>
			<a-form-item label="是否立即发放" >
				<a-radio-group v-model:value="form.coupon_status">
					<a-radio :value="1">是</a-radio>
					<a-radio :value="0">否</a-radio>
				</a-radio-group>
			</a-form-item>
			<a-form-item label="排序" >
				<a-input v-model:value="form.rank"></a-input>
			</a-form-item>
			<a-form-item :wrapper-col="{ offset: 2 }">
				<a-space>
					<a-button @click="submitSave" type="primary" >保存</a-button>
					<a-button @click="$router.go(-1)">返回</a-button>
				</a-space>
			</a-form-item>
		</a-form>
	</div>
</template>

<script>
import  comSelectTableList from "@/components/miniapp/com-select-table-list.vue"
import { computed, reactive, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import couponModel from '@/api/addons/coupon'
export default{
	name:'coupon-edit',
	components:{
		comSelectTableList
	},
	props:{
		isShop:Number
	},
	setup(){
		const state = reactive({
			form:{
				id:0,
				type:1,
				count:1,
				rank:99,
				use_count:0,
				end_time:'',
				expiry_day:'',
				coupon_type:1,
				expiry_date:1,
				begin_time:'',
				coupon_name:'',
				coupon_price:'',
				coupon_status:1,
				low_cash_price:'',
				is_specify:1,
				specify_goods:[],
			},
			typeList:[
				{name:'普通商城',value:1},
				{name:'认养',value:3},
				{name:'土地',value:4}
			],
			dateTime:['',''],

			goods_ids:[],
		})

		const opt = useRoute().query
		if( opt.id ){
			couponModel.getCouponDetail(opt.id,Object.keys(state.form),res=>{
				state.form = res
				state.dateTime = [res.begin_time,res.end_time]
			})
		}

		const specifyGoods = computed(()=>{
			let goods_ids = []
			state.form.specify_goods.forEach(item=>goods_ids.push(item.id))
			state.goods_ids = goods_ids
			return goods_ids.join(',')
		})

		function submitSave(){
			let form = JSON.parse(JSON.stringify(state.form))
			form.begin_time = state.dateTime[0]
			form.end_time = state.dateTime[1]

			let goods = form.specify_goods , goods_ids = []
			goods.forEach(item=>goods_ids.push(item.id))
			form.specify_goods = goods_ids

			couponModel.addOrEditCoupon(form)
		}

		function deleleSelectGoods(index){
			state.form.specify_goods.splice(index,1)
			console.log(state.form.specify_goods);
			
		}

		function selectGoodsData(e){
			e.value.forEach(item=>{
				if( item ){
					if( !state.goods_ids.includes(item.id)){
						state.form.specify_goods.push(item)
					}
				}
			})
		}
		const clearSelectGoods = ()=>{
			state.form.specify_goods = []
			state.goods_ids = []
		}

		return{
			...toRefs(state),
			submitSave,
			deleleSelectGoods,
			specifyGoods,
			selectGoodsData,
			clearSelectGoods
		}
	}
}
</script>

<style lang="scss" scoped>
.ce-goods-tag{
	font-size: 12px;
	background: #f4f4f4;
	border: 1px solid #e6e6e6;
	margin-top: 8px;
	line-height: 24px;
	padding: 0 6px;
	cursor: pointer;

	i{
		font-size: 20px;
		position: relative;
		top: 6px;
		color: #666;
	}
}
</style>

