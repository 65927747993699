<template>
    <!-- 员工管理 -->
    <div class="employee">
        <div class="el-content mb12">
            <div class="kd-pacel">检索参数</div>
            <a-space>
                <a-input-search
                    v-model:value="value"
                    placeholder="名称"
                    enter-button
                    style="width:300px;"
                    @search="onSearch"
                />
                <com-worker-add 
					:work-type="2" 
					:is-shop="isShop" 
					@success="getWorkerList(worker.page,worker.limit)">
				</com-worker-add>
            </a-space>
        </div>

        <div class="el-content">
            <div class="kd-pacel">列表</div>
            <a-table :pagination="false" :data-source="info.list" :columns="[
                {title:'ID',dataIndex:'id'},
                {title:'编号',dataIndex:'number'},
                {title:'姓名',dataIndex:'name'},
                {title:'电话',dataIndex:'phone'},
                {title:'已接工单',dataIndex:'all_work'},
                {title:'已完成工单',dataIndex:'complete_work'},
                {title:'状态',dataIndex:'status',slots:{customRender:'status'}},
                {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
            ]" :row-selection="{ selectedRowKeys: id, onChange: onSelectChange }">
                <template #status="{record}">
                    <a-tag color="#FF0066" v-if="record.status==0">禁用</a-tag>
                    <a-tag color="#33CC66" v-if="record.status==1">正常</a-tag>
                </template>
                <template #action="{record}">
                    <a-button type="link" size="small">编辑{{record.id}}</a-button>
                    <a-button type="link" size="small">溯源码</a-button>
                    <a-button type="link" size="small">删除</a-button>
               </template>
            </a-table>
            <div class="pager">
                <a-pagination
                    show-size-changer
                    :default-current="info.page"
                    :total="info.count"
                    @showSizeChange="(p,e)=>{getDisRule(info.page,e)}"
                    @change="(e)=>{getDisRule(e,info.limit)}"
                />
            </div>
        </div>

    </div>
</template>
<script>
import { reactive, toRefs } from 'vue'
import comWorkerAdd from '@/components/layout/common/com-worker-add.vue'
export default {
    components:{
        comWorkerAdd
    },
    setup() {
        const state = reactive({
            info:{
                list:[
                    {id:1,number:'S11223452',name:'张三',phone:"13452212345",all_work:88,complete_work:66,status:0},
                    {id:1,number:'S11223452',name:'张三',phone:"13452212345",all_work:88,complete_work:66,status:1},
                    {id:1,number:'S11223452',name:'张三',phone:"13452212345",all_work:88,complete_work:66,status:0},
                    {id:1,number:'S11223452',name:'张三',phone:"13452212345",all_work:88,complete_work:66,status:1},
                    {id:1,number:'S11223452',name:'张三',phone:"13452212345",all_work:88,complete_work:66,status:0},
                    {id:1,number:'S11223452',name:'张三',phone:"13452212345",all_work:88,complete_work:66,status:1},
                    {id:1,number:'S11223452',name:'张三',phone:"13452212345",all_work:88,complete_work:66,status:0},
                    {id:1,number:'S11223452',name:'张三',phone:"13452212345",all_work:88,complete_work:66,status:1},
                ],
                page:1,
                limit:10,
                count:0
            }
        })

        return{
            ...toRefs(state)
        }
    },
}
</script>
<style scoped lang="scss">

</style>