<template>
    <!-- 农事计划详情 -->
    <div class="farm-plan-detail">
        <div class="fpd-head el-content mb12">
            <div class="fpd-head-order-number">单号：123456789996</div>
            <a-space class="fpd-head-do">
                <a-button>打印任务</a-button>
                <a-button>中止任务</a-button>
                <a-button type="primary">指派任务</a-button>
            </a-space>
            <div class="fpd-head-status">
                <span class="f12">状态</span>
                <div>待指派</div>
            </div>
            <div class="fpd-head-con">
                <div class="fpd-head-con-item"><span class="f12">创建人：</span>曲丽丽</div>
                <div class="fpd-head-con-item"><span class="f12">计划名称：</span>脐橙西区23号地打枝除草</div>
                <div class="fpd-head-con-item"><span class="f12">创建时间：</span>2021-05-20</div>
                <div class="fpd-head-con-item"><span class="f12">计划类型：</span>详情详情详情详情详情详情详情详情详情</div>
                <div class="fpd-head-con-item"><span class="f12">操作对象：</span>xxxx地块xxx作物</div>
                <div class="fpd-head-con-item"><span class="f12">计划时间：</span>2021-04-20 ~ 2021-04-30</div>
                <div class="fpd-head-con-item"><span class="f12">备注：</span>备注内容备注内容备注内容备注内容备注内容</div>
            </div>
        </div>

        <a-card title="流程进度">
            <a-steps progress-dot :current="1">
                <a-step title="创建计划" description="曲丽丽 2020-05-20" />
                <a-step title="指派任务" description="进行中" />
                <a-step title="执行任务" />
                <a-step title="验收完成"/>
            </a-steps>
        </a-card>

        <a-card title="操作记录">
            <ul class="fpd-record">
                <li>
                    <div><span class="f12">执行人：</span>付晓晓</div>
                    <div><span class="f12">执行时间：</span>2020-05-20 21:12</div>
                    <div><span class="f12">执行情况：</span>暂无描述</div>
                    <div><span class="f12">投入农资：</span>氮肥100kg</div>
                    <div class="img">
                        <div class="img-li">
                            <a-image width="64px" height="64px" src="http://kundian.cqkundian.com/farm/3/png/20201216/6a881f5b41b396f94709a4b1014188291608088663.png"></a-image>
                        </div>
                        <div class="img-li">
                            <a-image width="64px" height="64px" src="http://kundian.cqkundian.com/farm/3/png/20201216/6a881f5b41b396f94709a4b1014188291608088663.png"></a-image>
                        </div>
                        <div class="img-li">
                            <a-image width="64px" height="64px" src="http://kundian.cqkundian.com/farm/3/png/20201216/6a881f5b41b396f94709a4b1014188291608088663.png"></a-image>
                        </div>
                        <div class="img-li">
                            <a-image width="64px" height="64px" src="http://kundian.cqkundian.com/farm/3/png/20201216/6a881f5b41b396f94709a4b1014188291608088663.png"></a-image>
                        </div>
                    </div>
                </li>
                 <li>
                    <div><span class="f12">执行人：</span>付晓晓</div>
                    <div><span class="f12">执行时间：</span>2020-05-20 21:12</div>
                    <div><span class="f12">执行情况：</span>暂无描述</div>
                    <div><span class="f12">投入农资：</span>氮肥100kg</div>
                    <div class="img">
                        <div class="img-li">
                            <a-image width="64px" height="64px" src="http://kundian.cqkundian.com/farm/3/png/20201216/6a881f5b41b396f94709a4b1014188291608088663.png"></a-image>
                        </div>
                        <div class="img-li">
                            <a-image width="64px" height="64px" src="http://kundian.cqkundian.com/farm/3/png/20201216/6a881f5b41b396f94709a4b1014188291608088663.png"></a-image>
                        </div>
                        <div class="img-li">
                            <a-image width="64px" height="64px" src="http://kundian.cqkundian.com/farm/3/png/20201216/6a881f5b41b396f94709a4b1014188291608088663.png"></a-image>
                        </div>
                        <div class="img-li">
                            <a-image width="64px" height="64px" src="http://kundian.cqkundian.com/farm/3/png/20201216/6a881f5b41b396f94709a4b1014188291608088663.png"></a-image>
                        </div>
                    </div>
                </li>
            </ul>
        </a-card>


    </div>
</template>
<script>
export default {
    setup() {
        
    },
}
</script>
<style scoped lang="scss">
.fpd-head{
    position: relative;

    &-order-number{
        font-weight: bold;
        font-size: 18px;
    }

    &-do{
        position: absolute;
        right: 12px;
        margin-top: -24px;
    }
    &-status{
        position: absolute;
        right: 12px;
        text-align: right;
        margin-top: 24px;
        div{
            font-size: 18px;
            margin-top: 8px;
        }
    }

    &-con{
        width: 900px;
        display: flex;
        flex-wrap: wrap;
        margin-top: 12px;

        &-item{
            width: 50%;
            line-height: 32px;
        }
    }
}
.fpd-record{
    list-style: none;
    margin: 0;
    padding: 0;

    li{
        margin-bottom: 24px;
        line-height: 32px;
    }

    .img{
        display: flex;
        flex-wrap: wrap;
        margin-left: 52px;
    }
    .img-li{
        margin-right: 12px;
    }
}
</style>